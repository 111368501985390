import React, { ReactElement, useState, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import {
	PageTitle,
	QAContainer,
	QuestionTitle,
	Question,
	QuestionAnswer,
	QuestionContainer,
	AnswerContainer,
	TextStyle3,
	TextStyle1,
	Row,
	LineBreak,
	UL50PX,
	CustomUL,
	AnswerContainerMobile,
} from './common'
import { isMobile } from 'react-device-detect'
import { FAQ_PAGE } from '../../../../configs'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { sanitizeUrl } from '@braintree/sanitize-url'

function SiteContentAndFeatures(): ReactElement {

	const [selectedOption, setSelectedOption] = useState('')

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = event.target.value
		setSelectedOption(selectedValue)

		// Check the selected value and navigate to the target ID
		if (selectedValue === 'question1') {
			window.location.href = 'faq/#rp-information'
		} else if (selectedValue === 'question2') {
			window.location.href = 'faq/#print-information'
		} else if (selectedValue === 'question3') {
			window.location.href = 'faq/#print-shelf-takers'
		} else if (selectedValue === 'question4') {
			window.location.href = 'faq/#print-selected-reviews'
		} else if (selectedValue === 'question5') {
			window.location.href = 'faq/#store-wine-cellar-info'
		} else if (selectedValue === 'question6') {
			window.location.href = 'faq/#access-cellar'
		} else if (selectedValue === 'question7') {
			window.location.href = 'faq/#enter-wine'
		} else if (selectedValue === 'question8') {
			window.location.href = 'faq/#subscriber-visibility'
		} else if (selectedValue === 'question9') {
			window.location.href = 'faq/#chart-rating'
		} else if (selectedValue === 'question10') {
			window.location.href = 'faq/maturity-classifcation'
		} else if (selectedValue === 'question11') {
			window.location.href = 'faq/#next-issue-coverage'
		} else if (selectedValue === 'question12') {
			window.location.href = 'faq/#incorrect-information'
		}
		// Add more conditions as needed
	}

	return (
		<div role="main">
			{isMobile ?
				<>
					<PageTitle>Site Content {'&'} Features</PageTitle>
					<QAContainer>

						<span className='jump-question'>Jump to question</span>
						<select value={selectedOption} onChange={(e) => handleChange(e)} className='jump-question-select component-design'>
							<option value=''>- Select a question -</option>
							<option value='question1'>What information is available on RobertParker.com?</option>
							<option value='question2'>Can I print the information?T</option>
							<option value='question3'>I am a Commercial Subscriber. How do I print shelf talkers?</option>
							<option value='question4'>How do I print selected reviews and tasting notes in a printer-friendly format?</option>
							<option value='question5'>Can I store my wine cellar information on the web site?</option>
							<option value='question6'>I am unable to access My Wines / RP Cellar.</option>
							<option value='question7'>Can I enter any wine I want in RP Cellar?</option>
							<option value='question8'>Will the information I enter be visible to other subscribers?</option>
							<option value='question9'>How are the wines in the vintage chart rated?</option>
							<option value='question10'>What do the maturity classifications mean?</option>
							<option value='question11'>What wines will be covered in the next issue of The Wine Advocate?</option>
							<option value='question12'>There is incorrect and / or missing information in the website.</option>
						</select>
						<Row>
							<AnswerContainerMobile>
								<div id='#rp-information'></div>
								<QuestionTitle>
									What information is available on RobertParker.com?
									<QuestionAnswer>
										<CustomUL>
											<li>
												Virtually all the tasting notes from The Wine Advocate since 1992 plus hundreds of &quot;overflow&quot; tastings that the printed edition couldn&apos;t accommodate. We update our pstings within a few days after each new issue of The Wine Advocate is mailed.
											</li>
											<li>
												Most of the articles from The Wine Advocate beginning with the 1995 issues plus articles from Robert Parker&apos;s books and other sources.
											</li>
											<li>
												<TextStyle3><Link to="/free-publications/wine-journal">Wine Journal:</Link></TextStyle3> The Wine Advocate reviewers publish stories detailing their outlandish gustatory experiences along with informal tasting notes not published anywhere else. The publication, Hedonist’s Gazette, has been included into Wine Journal as the Gourmet section.
											</li>
											<li>
												<TextStyle3><Link to="/free-publications/wine-discoveries">Wine Discovery:</Link></TextStyle3> Our reviewers spotlight their most notable discoveries from among the wines they’ve reviewed in the previous 12 months. A new selection will be curated at the end of every year.
											</li>
											<li>
												<TextStyle3><Link to="/rp-cellar">RP Cellar:</Link></TextStyle3> Post your own personal notes, manage your cellar down to individual bottle locations or just keep track of the wines in it, the wines you want to buy or wines of other interest.
												<div id='print-information'></div>
											</li>
											<li>
												Wine Searcher prices: Through our partnership with Winesearcher, we offer single click access to current retail offerings (updated daily) already indexed to our tens of thousands of wines, whether or not they have been reviewed in The Wine Advocate to assist you in finally landing some of those hard-to-find wines you&apos;ve always wanted.
											</li>
										</CustomUL>

									</QuestionAnswer>
								</QuestionTitle>
								<div id='print-shelf-takers'></div>
								<QuestionTitle>
									Can I print the information?
									<QuestionAnswer>
										This feature is only available for Commercial Subscribers, they have an option to print shelf talkers which are most useful to present a wine with its score and tasting note.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am a Commercial Subscriber. How do I print shelf talkers?

									<QuestionAnswer>
										Please follow the below instruction guide for printing shelf talkers. You can choose between the 2x2 or 3x1 format.
										<div id='print-selected-reviews'></div>
										<CloudImageUtils imgSrc={FAQ_PAGE.SHELF_TALKERS.url} alt="image" ratio={0} params='' />
									</QuestionAnswer>

								</QuestionTitle>

								<QuestionTitle>
									How do I print selected reviews and tasting notes in a printer-friendly format?
									<QuestionAnswer>
										Print a tasting note that you are viewing:
										<p>1. On Wine Details Page, click on Print note.</p>
										<CloudImageUtils imgSrc={FAQ_PAGE.TASTING_NOTE.url} alt="image" ratio={0} params='' />
										<TextStyle1>Print multiple tasting notes:</TextStyle1>
										<p>1. Search for the wines you are looking for.</p>
										<p>2. Select the wines and click on <TextStyle1>View selected wines</TextStyle1></p>
										<CloudImageUtils imgSrc={FAQ_PAGE.MULTIPLE_TASTING_NOTE_ONE.url} alt="image" ratio={0} params='' />
										3. You can choose to:
										<UL50PX>
											<li>
												Add a particular wine to the print list by select the checkbox or
											</li>
											<li>
												Click on Add all to Print List.
											</li>
										</UL50PX>
										<CloudImageUtils imgSrc={FAQ_PAGE.MULTIPLE_TASTING_NOTE_TWO.url} alt="image" ratio={0} params='' />
										<p>4. Click <TextStyle1>Print tasting notes</TextStyle1>.</p>
										<div id='store-wine-cellar-info'></div>
										<div id='access-cellar'></div>
										<CloudImageUtils imgSrc={FAQ_PAGE.MULTIPLE_TASTING_NOTE_THREE.url} alt="image" ratio={0} params='' />
										<div id='enter-wine'></div>
									</QuestionAnswer>
								</QuestionTitle>
								<QuestionTitle>
									Can I store my wine cellar information on the web site?
									<QuestionAnswer>
										Yes. Please click the link to RP <TextStyle3>Cellar</TextStyle3>  for more information.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am unable to access My Wines / RP Cellar.
									<div id='subscriber-visibility'></div>

									<QuestionAnswer>
										You can access the current RP Cellar here: <TextStyle3>cellar.robertparker.com</TextStyle3> . Log-in will be required.
										<LineBreak />
										Your wines in My Wines should have been migrated automatically into RP Cellar. However, you might want to export your current inventory to a spreadsheet, just in case.
									</QuestionAnswer>

								</QuestionTitle>

								<QuestionTitle>
									Can I enter any wine I want in RP Cellar?
									<div id='chart-rating'></div>

									<QuestionAnswer>
										At this stage you may only enter wines which are already listed in the RobertParker.com database.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Will the information I enter be visible to other subscribers?
									<QuestionAnswer>
										Your tasting notes will be Only to other subscribers that are following you as a trusted taster.
									</QuestionAnswer>
								</QuestionTitle>

								<div id='maturity-classifcation'></div>

								<QuestionTitle>
									How are the wines in the vintage chart rated?
									<QuestionAnswer>
										Please head to this link for more explanation on the Rating System used:
										<p><TextStyle3><a href='/about/ratings'>https://www.robertparker.com/about/ratings</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>


								<QuestionTitle>
									What do the maturity classifications mean?
									<QuestionAnswer>
										<p>The results of a search are first displayed in a list that includes a column entitled &quot;Maturity&quot;. That column will contain one of the following values with the meanings shown:</p>
										<UL50PX>
											<li><TextStyle1>Young:</TextStyle1> the wine is not yet within the recommended drinking range suggested in the tasting note.</li>
											<li><TextStyle1>Early Maturity:</TextStyle1> the wine is in the first third of the recommended drinking range.</li>
											<li><TextStyle1>Mature:</TextStyle1> the wine is in the middle third of the recommended drinking range (or in the case of short
												ranges, anywhere within the range)..</li>
											<li><TextStyle1>Late Maturity:</TextStyle1> the wine is in the final third of the recommended drinking range.</li>
											<li><TextStyle1>Past Maturity:</TextStyle1> the wine is past the recommended drinking range.</li>
											<li><TextStyle1>NA:</TextStyle1> no recommended drinking range was given in the note.</li>
										</UL50PX>
										<p>If a wine has been tasted more than once, the recommended drink dates are taken from the main tasting note (usually the most recent note, unless an earlier tasting appears to be more complete).</p>
									</QuestionAnswer>
								</QuestionTitle>

								<div id='next-issue-coverage'></div>
								<QuestionTitle>
									What wines will be covered in the next issue of The Wine Advocate?
									<QuestionAnswer>
										While it is subject to change, we do post an annual The Wine Advocate Editorial Calendar here:
										<p><TextStyle3><a href='/editorial-calendar'>www.robertparker.com/resources/editorial-calendar</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>


								<QuestionTitle id='incorrect-information'>
									There is incorrect and / or missing information in the website.
									<QuestionAnswer>
										Thank you for highlighting it to us. Kindly email the erroneous information to <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3> and we will have our Editorial team look into them.
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainerMobile>
						</Row>

					</QAContainer>

				</>
				:
				<>
					<PageTitle>Site Content {'&'} Features</PageTitle>
					<QAContainer>
						<Row>
							<QuestionContainer>
								<Question>
									<HashLink to='#rp-information'>What information is available on RobertParker.com?</HashLink>
								</Question>
								<Question>
									<HashLink to='#print-information'>Can I print the information?</HashLink>
								</Question>
								<Question>
									<HashLink to='#print-shelf-takers'>I am a Commercial Subscriber. How do I print shelf talkers?</HashLink>
								</Question>
								<Question>
									<HashLink to='#print-selected-reviews'>How do I print selected reviews and tasting notes in a printer-friendly format?</HashLink>
								</Question>
								<Question>
									<HashLink to='#store-wine-cellar-info'>Can I store my wine cellar information on the web site?</HashLink>
								</Question>
								<Question>
									<HashLink to='#access-cellar'>I am unable to access My Wines / RP Cellar.</HashLink>
								</Question>
								<Question>
									<HashLink to='#enter-wine'>Can I enter any wine I want in RP Cellar?</HashLink>
								</Question>
								<Question>
									<HashLink to='#subscriber-visibility'>Will the information I enter be visible to other subscribers?</HashLink>
								</Question>
								<Question>
									<HashLink to='#chart-rating'>How are the wines in the vintage chart rated?</HashLink>
								</Question>
								<Question>
									<HashLink to='#maturity-classifcation'>What do the maturity classifications mean?</HashLink>
								</Question>
								<Question>
									<HashLink to='#next-issue-coverage'>What wines will be covered in the next issue of The Wine Advocate?</HashLink>
								</Question>
								<Question>
									<HashLink to='#incorrect-information'>There is incorrect and / or missing information in the website.</HashLink>
								</Question>
							</QuestionContainer>
							<AnswerContainer>
								<div id='#rp-information'></div>
								<QuestionTitle>
									What information is available on RobertParker.com?
									<QuestionAnswer>
										<CustomUL>
											<li>
												Virtually all the tasting notes from The Wine Advocate since 1992 plus hundreds of &quot;overflow&quot; tastings that the printed edition couldn&apos;t accommodate. We update our pstings within a few days after each new issue of The Wine Advocate is mailed.
											</li>
											<li>
												Most of the articles from The Wine Advocate beginning with the 1995 issues plus articles from Robert Parker&apos;s books and other sources.
											</li>
											<li>
												<TextStyle3><Link to="/free-publications/wine-journal">Wine Journal:</Link></TextStyle3> The Wine Advocate reviewers publish stories detailing their outlandish gustatory experiences along with informal tasting notes not published anywhere else. The publication, Hedonist’s Gazette, has been included into Wine Journal as the Gourmet section.
											</li>
											<li>
												<TextStyle3><Link to="/free-publications/wine-discoveries">Wine Discovery:</Link></TextStyle3> Our reviewers spotlight their most notable discoveries from among the wines they’ve reviewed in the previous 12 months. A new selection will be curated at the end of every year.
											</li>
											<li>
												<TextStyle3><Link to="/rp-cellar">RP Cellar:</Link></TextStyle3> Post your own personal notes, manage your cellar down to individual bottle locations or just keep track of the wines in it, the wines you want to buy or wines of other interest.
												<div id='print-information'></div>
											</li>
											<li>
												Wine Searcher prices: Through our partnership with Winesearcher, we offer single click access to current retail offerings (updated daily) already indexed to our tens of thousands of wines, whether or not they have been reviewed in The Wine Advocate to assist you in finally landing some of those hard-to-find wines you&apos;ve always wanted.
											</li>
										</CustomUL>

									</QuestionAnswer>
								</QuestionTitle>
								<div id='print-shelf-takers'></div>
								<QuestionTitle>
									Can I print the information?
									<QuestionAnswer>
										This feature is only available for Commercial Subscribers, they have an option to print shelf talkers which are most useful to present a wine with its score and tasting note.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am a Commercial Subscriber. How do I print shelf talkers?

									<QuestionAnswer>
										Please follow the below instruction guide for printing shelf talkers. You can choose between the 2x2 or 3x1 format.
										<div id='print-selected-reviews'></div>
										<CloudImageUtils imgSrc={FAQ_PAGE.SHELF_TALKERS.url} alt="image" ratio={0} params='' />
									</QuestionAnswer>

								</QuestionTitle>

								<QuestionTitle>
									How do I print selected reviews and tasting notes in a printer-friendly format?
									<QuestionAnswer>
										Print a tasting note that you are viewing:
										<p>1. On Wine Details Page, click on Print note.</p>
										<CloudImageUtils imgSrc={FAQ_PAGE.TASTING_NOTE.url} alt="image" ratio={0} params='' />

										<TextStyle1>Print multiple tasting notes:</TextStyle1>
										<p>1. Search for the wines you are looking for.</p>
										<p>2. Select the wines and click on <TextStyle1>View selected wines</TextStyle1></p>
										<CloudImageUtils imgSrc={FAQ_PAGE.MULTIPLE_TASTING_NOTE_ONE.url} alt="image" ratio={0} params='' />
										3. You can choose to:
										<UL50PX>
											<li>
												Add a particular wine to the print list by select the checkbox or
											</li>
											<li>
												Click on Add all to Print List.
											</li>
										</UL50PX>
										<CloudImageUtils imgSrc={FAQ_PAGE.MULTIPLE_TASTING_NOTE_TWO.url} alt="image" ratio={0} params='' />
										<p>4. Click <TextStyle1>Print tasting notes</TextStyle1>.</p>
										<div id='store-wine-cellar-info'></div>
										<div id='access-cellar'></div>

										<CloudImageUtils imgSrc={FAQ_PAGE.MULTIPLE_TASTING_NOTE_THREE.url} alt="image" ratio={0} params='' />
										<div id='enter-wine'></div>
									</QuestionAnswer>
								</QuestionTitle>
								<QuestionTitle>
									Can I store my wine cellar information on the web site?
									<QuestionAnswer>
										Yes. Please click the link to RP <TextStyle3>Cellar</TextStyle3>  for more information.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am unable to access My Wines / RP Cellar.
									<div id='subscriber-visibility'></div>

									<QuestionAnswer>
										You can access the current RP Cellar here: <TextStyle3>cellar.robertparker.com</TextStyle3> . Log-in will be required.
										<LineBreak />
										Your wines in My Wines should have been migrated automatically into RP Cellar. However, you might want to export your current inventory to a spreadsheet, just in case.
									</QuestionAnswer>

								</QuestionTitle>

								<QuestionTitle>
									Can I enter any wine I want in RP Cellar?
									<div id='chart-rating'></div>

									<QuestionAnswer>
										At this stage you may only enter wines which are already listed in the RobertParker.com database.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Will the information I enter be visible to other subscribers?
									<QuestionAnswer>
										Your tasting notes will be Only to other subscribers that are following you as a trusted taster.
									</QuestionAnswer>
								</QuestionTitle>

								<div id='maturity-classifcation'></div>

								<QuestionTitle>
									How are the wines in the vintage chart rated?
									<QuestionAnswer>
										Please head to this link for more explanation on the Rating System used:
										<p><TextStyle3><a href='/about/the-rating-system'>https://www.robertparker.com/about/ratings</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									What do the maturity classifications mean?
									<QuestionAnswer>
										<p>The results of a search are first displayed in a list that includes a column entitled &quot;Maturity&quot;. That column will contain one of the following values with the meanings shown:</p>
										<UL50PX>
											<li><TextStyle1>Young:</TextStyle1> the wine is not yet within the recommended drinking range suggested in the tasting note.</li>
											<li><TextStyle1>Early Maturity:</TextStyle1> the wine is in the first third of the recommended drinking range.</li>
											<li><TextStyle1>Mature:</TextStyle1> the wine is in the middle third of the recommended drinking range (or in the case of short
												ranges, anywhere within the range)..</li>
											<li><TextStyle1>Late Maturity:</TextStyle1> the wine is in the final third of the recommended drinking range.</li>
											<li><TextStyle1>Past Maturity:</TextStyle1> the wine is past the recommended drinking range.</li>
											<li><TextStyle1>NA:</TextStyle1> no recommended drinking range was given in the note.</li>
										</UL50PX>
										<p>If a wine has been tasted more than once, the recommended drink dates are taken from the main tasting note (usually the most recent note, unless an earlier tasting appears to be more complete).</p>
									</QuestionAnswer>
								</QuestionTitle>

								<div id='next-issue-coverage'></div>
								<QuestionTitle>
									What wines will be covered in the next issue of The Wine Advocate?
									<QuestionAnswer>
										While it is subject to change, we do post an annual The Wine Advocate Editorial Calendar here:
										<p><TextStyle3><a href='/editorial-calendar' >www.robertparker.com/resources/editorial-calendar</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle id='incorrect-information'>
									There is incorrect and / or missing information in the website.
									<QuestionAnswer>
										Thank you for highlighting it to us. Kindly email the erroneous information to <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3> and we will have our Editorial team look into them.
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainer>
						</Row>

					</QAContainer>
				</>
			}
		</div>
	)
}

export default SiteContentAndFeatures