import styled from 'styled-components'

export const CreditCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.6rem;

	& .form-title {
		margin-top: 1.8rem;
		font-weight: bold;
		font-size: 18px;
	}

	& .credit-card-title.padding-bottom-20px{
		padding: 1.5rem;
		width: 100%;
		border: 1px solid #ccc;
		
		& div {
			& label, & input {
				cursor: pointer;
			}
		}
	}

	& .gift-credit-card {
		border: 1px solid #ccc;
		border-radius: 2px;

		& .credit-card-check {
			display: flex;
			align-items: center;
			gap: 0.6rem;
			padding: 1rem;
			border-bottom: 1px solid #ccc;
			margin: 0;
			& label {
				margin: 0;
			}

			& input {
				margin: 0;
			}
		}

		& .credit-card-content {
			padding: 1rem;
			display: flex;
			flex-direction: column;
			gap: 1.2rem;

			& .expiry-date-container {

				& .expiry-date-inputs {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}

			& .cvv {
				& .cvv-label {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
		}

		& .credit-card-title {
			padding: 1rem;
			border-bottom: 1px solid #ccc;
		}
	}

`
export const AddOnsContainer = styled.div`
	padding: 15px;
    margin: auto;
    width: 50%;

    & .addons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .addons-title {
            font-weight: bold;
            font-size: 20px;
        }
    }

    & .addons-content {
        display: flex;
		align-items: flex-start;
		justify-content: space-between;

        & div {
            display: flex;
			flex-direction: column;

			& div {
				display: flex;
				gap: 0.6rem;
				align-items: center;
				flex-direction: row;

				& input {
					padding: 0.2rem 0.5rem;
					width: 50%;
					outline: none;
					border-radius: 8px;
					border: 1px solid #ccc;
				}
			}
        }
    }

	& .addons-promo-code-container{

		& .addons-promo-code {
			display: flex;
			align-items: center;
			justify-content: space-between;

			& span > b {
				font-size: 1.2rem;
			}

			& div {
				display: flex;
				align-items: center;
				
				& input {
					width: 80%;
					outline: none;
					border-radius: 5px 0 0 5px;
					border: 1px solid #ccc;
					border-right: none;
					padding: 0.2rem 0.5rem;
				}

				& button {
					background-color: white;
					width: 5.5rem;
					border: 1px solid #ccc;
					font-weight: bold;
					padding: 0.2rem 0;
				}
			}
		}

		& .addons-error-message {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}

	

	& .addons-total {
		width: 100%;
		margin-bottom: 0;
		text-align: end;
		font-weight: bold;
		font-size: 1.2rem;
	}

	& .checkbox {
		display: flex;
		flex-direction: row;
		justify-content: start;
		margin-top: 1rem;
		margin-bottom: 0;
		cursor: pointer;
			
		/* & input {
			display: flex;
			justify-content: start;
			align-items: start;
		} */

		& label {
			padding: 10px 0 0 0;
		}
	}

	& .captcha {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	& .addons-sub-detail {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 21px;
		align-items: flex-start;

		& div.detail-left {
			margin-left: 20px;
		}

		& div > span.normal-font {
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}

		& div > span.bolded-font {
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: bold;
			line-height: 1.2;
			letter-spacing: normal;
			text-align: right;
			color: #333;
		}
	}

	& .footerTitle {
		font-size: 16px;
		font-style: italic;
	}

	& .qtyContainer {
		& select {
			width: 98px !important;
		}
	}
	
    @media (max-width: 992px) {
        width: 85%;
    }
	
`

export const AddOnsBackButton = styled.button`
	width: 104px;
  	height: auto;
  	padding: 6px 12px 6px 12px;
  	border-radius: 8px;
  	border: solid 1px #ccc;
  	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: center;

	& img {
		padding-top: 0.3rem;
		transform: scaleX(-1);
	}

	&.mobile {
		padding-right: 12px !important;
	}
`

export const AddOnsButton = styled.button`
	width: fit-content;
  	height: auto;
  	padding: 6px 26px;
  	border-radius: 8px;
  	background-color: #73333f;
	font-family: Libre Franklin;
  	font-size: 14px;
  	font-weight: bold;
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.2;
  	letter-spacing: normal;
  	text-align: center;
	color: #fff;
	border: none;

	&:hover {
		background-color: #8E4250;
	}
`

export const ValidatedField = styled.input`
	border: 1px solid black;
	border-radius: 8px;
	padding: 6px 6px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	&:focus {
		border-color: red;
		transition: border-color 0.3s ease-in-out;
		outline: 0;
	}
	&.error {
		border-color: #c50000;
	}
`

export const PaymentMethodContainer = styled.div`

	&.desktop {
		width: 652px;
	}
	
	&.mobile {
		width: 80%;
	}

	& .back-button {
		padding-right: 25px !important;
		color: #333333 !important;
	}

	& div.payment-method {
		margin: 20px 0 0;
		border-radius: 8px;
		border: solid 1px #ddd;
		background-color: #ffffff;

		& .credit-card-title.padding-bottom-20px {
			& div {
				& label, & input {
					cursor: pointer;
				}
			}
		}
	}

	& div.credit-card-title {
		padding: 20px 20px 10px;
		font-family: Libre Franklin;
		font-size: 16px;  
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}

	& div.detail-left {
		margin: 10px 0px;
		display: flex;
		align-items: flex-start;

		&>span {
			width: 569px;
			margin: 0 0 0 8px;
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			& .link{
				color: #a08436 !important;
			}
		}
		
	}

	& div.detail-right {
		display: flex;
		justify-content: space-between;
	}

	& .default {
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.2;
		color: #008185;
	}

	& .card {
		display: flex;
    	align-items: flex-start;
		padding-bottom: 15px;
	}

	& .card-details {
		padding-top: 5px;
		font-size: 14px;
	}

	& .padding-left-5px {
		padding-left: 5px;
	}

	& .margin-top-20px {
		margin-top: 20px !important;
	}

	& .margin-TB-0px {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
	
	& .padding-bottom-20px {
		padding-bottom: 20px !important;
	}
`