import React, { ReactElement } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import SuccessActivation from '../../../../components/success-page/SuccessPage'
import NonExistPage from '../404-page/NonExistPage'
import PersonalCheckout from './plans/PersonalCheckout'
import CommercialCheckout from './plans/CommercialCheckout'

const bodyContent = [
	{
		header: 'Personal subscription activated',
		bodyOne:'Your Personal subscription has been activated. The subscription will end on',
		bodyTwo: 'You now have access to all articles and tasting notes on the site.',
	},
	{
		header: 'Commercial subscription activated',
		bodyOne:'Your Commercial subscription has been activated. The subscription will end on',
		bodyTwo: 'You and your group users now have access to all articles and tasting notes on the sites, and permission to publish scores and tasting notes according to the plan purchased.',
	},
]

function Checkout():ReactElement {
	const { search } = useLocation()
	const {type} = useParams<{ type: string }>()
	const queryParams = new URLSearchParams(search)
	const planType = queryParams.get('type')

	const personalPlans = ['personal-3-month', 'personal-1-year', 'personal-monthly']
	const commercialPlans = ['commercial-1-year']

	if(type === 'success'){
		if(planType === 'personal')
			return <SuccessActivation header={bodyContent[0].header} bodyOne={bodyContent[0].bodyOne} bodyTwo={bodyContent[0].bodyTwo} />
    
		if(planType === 'commercial')
			return <SuccessActivation header={bodyContent[1].header} bodyOne={bodyContent[1].bodyOne} bodyTwo={bodyContent[1].bodyTwo} />
	}

	if(personalPlans.includes(type))
		return <PersonalCheckout />

	if(commercialPlans.includes(type))
		return <CommercialCheckout />

	return <NonExistPage />
}

export default Checkout
