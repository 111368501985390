import React, { ReactElement } from 'react'
import './style.css'
import { isMobile } from 'react-device-detect'

function LoadingOverlay(): ReactElement { 

	return (
		<div className='overlay'>
			<div className="load">
				<div className={isMobile ? 'load-mobile load-3' : 'load-3'}>
					<div className="line"></div>
					<div className="line"></div>
					<div className="line"></div>
					<div className="line"></div>
					<div className="line"></div>
				</div>
				<p className={isMobile ? 'text-mobile' : ''}>Loading please wait...</p>
			</div>
		</div>
	)
}

export default LoadingOverlay