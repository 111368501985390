/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

// Styles
import { PublicationDateFormContainer } from '../styles'
import { ActionButton } from '../../subscriptions/styles'
import { useLocation } from 'react-router-dom'
import { getTypes } from '../../../../../utils/SearchUtil'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { SetActiveAdvanceFilters, SearchFilters as SearchFiltersFromStore } from '../../../../../app/slices/SearchFilters'
import { useAppSelector } from '../../../../../app/hooks'


type Props = {
	publicationDateData?:{
		dateFrom: number;
		dateTo: number;
	}
	type: string
	clickApplyHandler: (data: any) => void
}

function PublicationDateFilter({clickApplyHandler, publicationDateData, type}: Props): ReactElement {

	const defaultFromDate = '1992-01-01'
	const defaultFormat = 'YYYY-MM-DD'
	const defaultToDate = moment().format(defaultFormat)

	const [isApplyDisabled, setIsApplyDisabled] = useState(false)
	const [isChangeFromDate, setIsChangFromDate] = useState(true)
	const [fromDateInitialValue, setFromDateInitialValue] = useState('1989-01-01')
	const [fromDate, setFromDate] = useState(defaultFromDate)
	const [toDate, setToDate] = useState(defaultToDate)
	const [errorMessage, setErrorMessage] = useState<any>({
		message: '',
		isError: false
	})
	const { pathname } = useLocation()
	const searchType = getTypes(pathname)
	const dispatch = useDispatch()
	const {AdvanceFilter: advanceFilter} = useAppSelector(SearchFiltersFromStore)

	const handleDatePicker = (date: Date | null, type: 'from' | 'to', pageType: string) => {
		if(date){
			const formattedDate = moment(date).format(defaultFormat)

			if(type === 'from') {
				setFromDate(formattedDate)
				setErrorMessage({
					isError: false
				})
				if(pageType !== 'normal-search') {
					const prevToDate = advanceFilter && advanceFilter.date_last_tasted ? advanceFilter.date_last_tasted[0] : new Date().getTime()
					dispatch(SetActiveAdvanceFilters({
						advanceFilter: { ['date_last_tasted']: [new Date(formattedDate).getTime(), prevToDate]}
					}))
				}

				return
			}

			if(pageType !== 'normal-search') {
				const prevFromDate  = advanceFilter && advanceFilter.date_last_tasted ? advanceFilter.date_last_tasted[0] : defaultFromDate

				dispatch(SetActiveAdvanceFilters({
					advanceFilter: { ['date_last_tasted']: [prevFromDate, new Date(formattedDate).getTime()] }
				}))
			}

			setToDate(formattedDate)
			setErrorMessage({
				isError: false
			})
		}
	}

	const applyHandler = () => {
		if(fromDate === '') {
			setErrorMessage({
				message: 'Set Publication Date From',
				isError: true
			})
			return
		}
		if(toDate === '') {
			setErrorMessage({
				message: 'Set Publication Date To',
				isError: true
			})
			return
		}
		if(fromDate > toDate) {
			setErrorMessage({
				message: 'Invalid Publication Date',
				isError: true
			})
		} else {
			setErrorMessage({
				message: '',
				isError: false
			})
			clickApplyHandler({fromDate, toDate})
			
			const offCanvas: HTMLElement | null = document.querySelector('#mnmd-filter')
			const backdrop: HTMLElement | null = document.querySelector('#backdrop-filter')
			if (offCanvas !== null && offCanvas.classList.contains('is-active')) {
				offCanvas.classList.remove('is-active', 'ps-container', 'ps-theme-default')
			}

			if(backdrop){
				backdrop.remove()
			}
		}
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		if(publicationDateData){
			if(publicationDateData.dateFrom !== undefined && publicationDateData.dateTo !== undefined){
				let fromDate = publicationDateData.dateFrom.toString()
				let toDate = publicationDateData.dateTo.toString()
	
				const datePattern = /^\d{4}-\d{2}-\d{2}$/
				if (!datePattern.test(fromDate) && !datePattern.test(toDate)) {
					fromDate = new Date(publicationDateData.dateFrom).toISOString().slice(0, 10)
					toDate = new Date(publicationDateData.dateTo).toISOString().slice(0, 10)
				}
				
				if(publicationDateData.dateFrom !== 0 && publicationDateData.dateTo !== 0){
					setFromDate(fromDate)
					setToDate(toDate)
					if(isChangeFromDate){
						setFromDateInitialValue(toDate)
					}
				} else {
					setFromDate(defaultFromDate)
					setToDate(defaultToDate)
				}
			}
		}
	},[publicationDateData])

	useEffect(() => {
		if (fromDate || toDate) {
			if(fromDate === toDate){
				setIsApplyDisabled(true)
			} else {
				setIsApplyDisabled(false)
			}
		}
	
		if(pathname === '/advanced-search/wine'){
			if(advanceFilter && advanceFilter.date_last_tasted){
				const convertedToDate = new Date(advanceFilter.date_last_tasted[0]).toISOString().split('T')[0]
				const convertedFromDate = new Date(advanceFilter.date_last_tasted[1]).toISOString().split('T')[0]
				setFromDate(convertedToDate)
				setToDate(convertedFromDate)
			}
		}
		setIsChangFromDate(false)
	},[fromDate, toDate])

	return (
		<div>
			{type === 'normal-search' ?
				<PublicationDateFormContainer>
					{searchType !== 'article' &&
					<>
						<p className='sub-title'>Based on the latest tasting note</p>
					</>}
					<div className='from-field'>
						<span>From</span>
						<DatePicker className='component-design'
							onChange={(date) => handleDatePicker(date, 'from', type)}
							showMonthDropdown
							showYearDropdown
							selected={fromDate ? moment(fromDate, defaultFormat).toDate() : moment(defaultFromDate, defaultFormat).toDate()}
							dropdownMode="select"
							dateFormat="MM/dd/yyyy"
							maxDate={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
							minDate={moment(fromDateInitialValue, defaultFormat).toDate()}
							placeholderText="Select a date"
						/>
					</div>
					<div className='to-field'> 
						<span>To</span>
						<DatePicker className='component-design'
							onChange={(date) => handleDatePicker(date, 'to', type)}
							showMonthDropdown
							showYearDropdown
							selected={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
							dropdownMode="select"
							dateFormat="MM/dd/yyyy"
							maxDate={new Date()}
							minDate={fromDate ? moment(fromDate, defaultFormat).toDate() : null}
							placeholderText="Select a date"
						/>
					</div>
					<div className='errorContainer'>
						{errorMessage.isError ? errorMessage.message : ''}
					</div>
					<div className='applyBTN'>
						<ActionButton style={{width: '100%' }} onClick={() => applyHandler()} disabled={isApplyDisabled}>Apply</ActionButton>
					</div>
				</PublicationDateFormContainer>
				:
				<div style={{paddingLeft: '20px'}}>
					<div style={{ paddingBottom: '10px', paddingTop: '5px' }}>
						<span style={{ fontWeight: 'bold' }}>Publication Date</span>
					</div>
					<PublicationDateFormContainer>
						<span>From</span>
						<div className='from-field'>
							<DatePicker className='component-design'
								onChange={(date) => handleDatePicker(date, 'from', type)}
								showMonthDropdown
								showYearDropdown
								selected={fromDate ? moment(fromDate, defaultFormat).toDate() : moment(defaultFromDate, defaultFormat).toDate()}
								dropdownMode="select"
								dateFormat="MM/dd/yyyy"
								maxDate={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
								minDate={moment(fromDateInitialValue, defaultFormat).toDate()}
								placeholderText="Select a date"
							/>
						</div>
						<span>To</span>
						<div className='to-field'>
							<DatePicker className='component-design'
								onChange={(date) => handleDatePicker(date, 'to', type)}
								showMonthDropdown
								showYearDropdown
								selected={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
								dropdownMode="select"
								dateFormat="MM/dd/yyyy"
								maxDate={new Date()}
								minDate={fromDate ? moment(fromDate, defaultFormat).toDate() : null}
								placeholderText="Select a date"
							/>
						</div>
						<div className='errorContainer'>
							{errorMessage.isError ? errorMessage.message : ''}
						</div>
					</PublicationDateFormContainer>
				</div>
			}			
		</div>
	)
}

export default PublicationDateFilter