/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'

import { GetBaseQuery } from '../../utils/'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'
// import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

type RefreshAccessType = {
	refreshToken: string;
	userId: string,
	accessToken: string
}

export const UsersAPIv2 = createApi({
	reducerPath: 'UsersAPIv2',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getUserDetailsById: builder.query<UserDetails, string>({
			query: (id) => `${API_ENDPOINTS.get_user_details}?userId=${id}`,
		}),
		getUserListReviewers: builder.query<UserDetails, void>({
			query: () => API_ENDPOINTS.get_list_user_reviewers,
		}),
		getManagerDetailsById: builder.query<UserDetails, string>({
			query: (id) => `${API_ENDPOINTS.get_user_details}?userId=${id}`,
		}),
		getAuthorDetailsById: builder.query<UserDetails, string>({
			query: (id) => `${API_ENDPOINTS.get_reviewers_details}?reviewerId=${id}`,
		}),
	}),
})

export const UsersAPI = createApi({
	reducerPath: 'UsersAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getUserLogin: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.get_user_login_details,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token,
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		refreshAccessToken: builder.mutation<any, any>({
			query: (params: RefreshAccessType) => {
				return {
					url: `${API_ENDPOINTS.refresh_access_token}`,
					method: 'POST',
					body: {
						refreshToken: params.refreshToken,
						userId: params.userId,
					},
					headers: {
						'Authorization': 'Bearer ' + params.accessToken,
						'x-api-key': RP_ENV.API_KEY,
						'Content-Type': 'application/json'
					}
				}
			}
		}),
		userForgotPassword: builder.mutation({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.user_forgot_password,
					method: 'POST',
					body: params
				}
			}
		}),
		userChangePassword: builder.mutation({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.user_change_password,
					method: 'POST',
					body: params
				}
			}
		}),
		updateUserPref: builder.mutation({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.user_update_preference,
					method: 'POST',
					body: params
				}
			}
		}),
		userUpdatePassword: builder.mutation({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.update_user_passwordV2,
					method: 'POST',
					body: params
				}
			}
		}),
		userNoCCPA: builder.mutation({
			query: (arg: { params: any, userID: any }) => {
				const { params, userID } = arg
				return {
					url: `${API_ENDPOINTS.user_no_ccpa}?userId=${userID}`,
					method: 'POST',
					body: params
				}
			}
		}),
	}),
})

export const {
	useGetUserLoginMutation,
	useUserForgotPasswordMutation,
	useUserChangePasswordMutation,
	useUpdateUserPrefMutation,
	useUserNoCCPAMutation,
	useRefreshAccessTokenMutation
} = UsersAPI


export const {
	useGetUserDetailsByIdQuery,
	useGetUserListReviewersQuery,
	useGetManagerDetailsByIdQuery,
	useGetAuthorDetailsByIdQuery
} = UsersAPIv2

