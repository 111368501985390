import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import { NoSubscriptionLabel, PaymentHistoryCardAccountLabel, PaymentHistoryContainer, PaymentHistoryDateLabel, PaymentHistoryDownloadButton, PaymentHistoryDownloadLabel, PaymentHistoryPaymentLabel, PaymentHistoryStatusLabel, PaymentHistoryUsdLabel, Row, SubscriptionRow } from './styles'
import { useGetPaymentHistoryQuery } from '../../../../app/services'
import { useAppSelector } from '../../../../app/hooks'
import LoadingOverlay from '../../../../components/Loading-overlay'
import PrintReciept from './components/printComponent/PrintReciept'

import './components/printComponent/style.css'
import './style.css'
import {Users as UsersFromStore} from '../../../../app/slices/Users'
import { isMobile } from 'react-device-detect'
import ColumizedString from './components/ColumnizedString'

interface PaymentInfo {
	_id: string;
	currency: string;
	amount: number;
	transaction_date: Date;
	created_at: Date;
	credit_card: {
		number:string;
		type:string;
	}
	transaction_status:string;
}

interface IPaymentHistoryState {
	list: PaymentInfo[]
	noPaymentHistory: boolean
}

function PaymentHistory(): ReactElement {
	const componentRef = useRef<null | HTMLDivElement>(null)
	const { userData } = useAppSelector(UsersFromStore)
	const { data, isLoading: getPaymentLoading, isError  } = useGetPaymentHistoryQuery(userData._id, { skip: !userData._id })
	const [paymentHistoryState, setpaymentHistoryState] = useState<IPaymentHistoryState>({
		list: [],
		noPaymentHistory: false,
	})
	const [selectedHistory, setselectedHistory] = useState<PaymentInfo>()

	useEffect(() => {
		setpaymentHistoryState(state => ({ ...state }))
		if(data){
			if (data.success) {
				const list = data.data.map((payment:PaymentInfo ) => ({
					_id: payment._id,
					currency: payment.currency,
					amount: payment.amount,
					created_at: payment.created_at,
					credit_card: {
						number:payment.credit_card.number,
						type:payment.credit_card.type
					},
					transaction_status:payment.transaction_status
				}))
				setpaymentHistoryState(state => ({ ...state, list: data.data, noPaymentHistory: list.length > 0 ? false : true }))
			} else if (data) {
				setpaymentHistoryState(state => ({ ...state, noPaymentHistory: true, }))
			}
		}

		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [data, isError])

	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	const selectedPaymentToPrint = (data:PaymentInfo) => {
		setselectedHistory(data)
		setTimeout(() => {
			handlePrint()
		}, 100)
	}

	return (
		<div>
			{getPaymentLoading ? <LoadingOverlay /> :
				paymentHistoryState.list && paymentHistoryState.list.length > 0 ?
					(<>
						<div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'column', alignItems: 'center'}}>
							{paymentHistoryState?.list.map((ele: PaymentInfo, index: number) => {
								return (
									<PaymentHistoryContainer key={index} isMobile={isMobile}>
										<Row>
											<PaymentHistoryDateLabel isMobile={isMobile}>{ele.transaction_date ? moment(ele.transaction_date).format('MMM DD, YYYY')  : moment(ele.created_at).format('MMM DD, YYYY') }</PaymentHistoryDateLabel>
										</Row>
										<SubscriptionRow>
											<PaymentHistoryPaymentLabel isMobile={isMobile}>
												<span>Payment ID: </span>
												<span>{ele._id?.toUpperCase()}</span>
											</PaymentHistoryPaymentLabel>
											<PaymentHistoryUsdLabel isMobile={isMobile}>
												<span>{ele.currency} {(typeof(ele.amount) == 'number') ? ele.amount.toFixed(2) : ele.amount}</span>
											</PaymentHistoryUsdLabel>
										</SubscriptionRow>

										<SubscriptionRow>
											<PaymentHistoryCardAccountLabel isMobile={isMobile}>
												{ (ele.credit_card.number && ele.credit_card.type) ? <>
													<span><b>{ele.credit_card.type ?? 'Visa'}</b></span>&nbsp;
													<span>ending in {ele.credit_card?.number?.slice(ele.credit_card.number.length - 4, ele.credit_card.number.length )}</span>
												</> : <span>N/A</span> }
											</PaymentHistoryCardAccountLabel>
											{isMobile ? <PaymentHistoryStatusLabel isMobile={isMobile}>
												<ColumizedString inputString={ele.transaction_status} />
											</PaymentHistoryStatusLabel> : <PaymentHistoryStatusLabel isMobile={isMobile}>
												<span>{ele.transaction_status}</span>
											</PaymentHistoryStatusLabel>
											}
										</SubscriptionRow>

										<Row>
											<PaymentHistoryDownloadButton>
												<PaymentHistoryDownloadLabel onClick={()=> selectedPaymentToPrint(ele)} >
													Download receipt
												</PaymentHistoryDownloadLabel>
											</PaymentHistoryDownloadButton>
										</Row>
										{/* <SectionSeparator position='horizontal' /> */}
									</PaymentHistoryContainer>)
							})}
						</div>
					</>)
					: (<NoSubscriptionLabel>No payment history available</NoSubscriptionLabel>)
			}
			<div className='reciept-hide'>
				<PrintReciept refs={componentRef} recieptData={selectedHistory} />
			</div>
		</div>
	)
}

export default PaymentHistory