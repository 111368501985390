/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { APP_CONFIG } from '../../../../configs'
// Data
import { useGetArticlesFromAlgoliaQuery, useGetWinesFromAlgoliaQuery } from '../../../../app/services'

// Helpers
import { ConvertJSONArticlesCollectionToClass } from '../../../../utils'

// Slices
import { useAppSelector } from '../../../../app/hooks'
import {  SearchFilters as SearchFiltersFromStore, SetActiveAdvanceFilters, SetFacetsFilters, SetKeywords, SetPagination, SetSearchDisplayType } from '../../../../app/slices/SearchFilters'

// Styles
import {
	FilterOptions,
	FilterResultsContainer,
	// HardcodedOption,
	OneRow,
	ResultsContainer,
	TwoSidedRow,
	TwoSidedRowOption,
	VerticalSeparator
} from './common/styles'

// Sections
import queryString from 'query-string'
import AlgoliaPagination from '../../../../components/pagination/AlgoliaPagination'
import { checkQueryString, generateBody, getSameFacetsByType } from '../../../../utils/SearchUtil'
import SectionSeparator from '../../sections/section-separator'
import Filters from './Filters'
import SearchAndSort from './SearchAndSort'
import TabbedMenu from './TabbedMenu'
import LoadingOverlay from '../../../../components/Loading-overlay'
import SearchDisplay from './common/SearchDisplay'
import { decodeUrlKeywordQuery, decodeUrlParams, decodeUrlParamsFilter, encodeUrlParams } from '../../../../helper/constants'

type ProducerListType = {
	producers: {
		name: string;
		count: number;
	}[];
	totalRecords: number;
	offset:{
		offset: number;
		limit: number;
	};
	totalPages: number;
	allProducers: {
		name: string;
		count: number;
	}[];
	max: number;
	min: number;
}

const limit = APP_CONFIG.SEARCH.OFFSET_LIMIT.limit //for pagination
const tastingNoteLimit = APP_CONFIG.SEARCH.ALTERNATE_OFFSET_LIMIT.limit
const selectedLimit = APP_CONFIG.SEARCH.SELECTED_WINE_LIMIT.limit

const initialState = {
	wines: [],
	articles: [],
	totalRecords: 0,
	totalPages: 0,
	allFilters: undefined,
	filters: {},
	max: 0,
	min: 0
}

const producerInitialList = {
	producers: [],
	allProducers:[],
	totalRecords: 0,
	offset:{
		offset: 0,
		limit
	}, 
	totalPages: 0,
	max: 0,
	min: 0
}

const maxNum = (pageNum: number, total: number, type: string, wineDisplayType: string) => {
	if(type === APP_CONFIG.SEARCH.TYPES.WINE && wineDisplayType === 'wineList'){
		const max = (pageNum + 1) * tastingNoteLimit
		return {
			maxResult: max > total ? total : max,
			minResult: max - (tastingNoteLimit - 1)
		}
	}else{
		const max = (pageNum + 1) * limit
		return {
			maxResult: max > total ? total : max,
			minResult: max - (limit - 1)
		}
	}}

const producersPaginationData = (page: number, producers:{name:string; count: number}[], limit: number) => {
	const itemsPerPage = limit
	const startIndex = page * itemsPerPage
	const endIndex = startIndex + itemsPerPage
	const actualEndIndex = producers.length - 1  < endIndex ? producers.length : endIndex

	return {
		producers: producers.slice(startIndex, endIndex) || [],
		startIndex,
		endIndex: actualEndIndex
	}
}

function SearchResult(): ReactElement {

	const { type } = useParams<{ type: string }>()
	const { search } = useLocation()
	const history = useHistory()
	const dispatch = useDispatch()

	//New Update by Jc
	const [resultState, setResultState] = React.useState<any>(initialState)
	const [limitOffset, setLimitOffset] = useState(0)
	const [producersList, setProducersList] = useState<ProducerListType>(producerInitialList)
	const [sortProducer, setSortProducer] = useState('asc')
	
	// CONFIGURATION
	const varStore = useAppSelector(SearchFiltersFromStore)
	const wineDisplay = varStore.SearchType

	const limitVarStore = {
		...varStore,
		Offset_Limit: {
			offset: limitOffset,
			limit: selectedLimit
		}
	}

	const queryStrings = queryString.parse(search) as any
	const isFacetsInQueryString = checkQueryString(queryStrings, varStore.ActiveFilters)
	const body = generateBody(dispatch, varStore, type, queryStrings, varStore)
	const limitBody = generateBody(dispatch, limitVarStore, type, queryStrings, varStore)
	const didMountRef = useRef(false)
	const [articleIsDefault, setArticleIsDefault] = useState(true)
	const [isToggleClearFilter, setIsToggleClearFilter] = useState(false)
	const [rangeData, setRangeData] = useState<any>()

	const [isQuerySuccess, setIsQuerySuccess] = useState(false)
	const shouldSetResultState = useRef(false)
	useEffect(() =>{
		// if(queryStrings?.rating_computed){
		// 	const match = queryStrings.rating_computed.match(/\d+/g)
		// 	const valueRateFromURL = match ? match.map(Number) : null
			
		// 	dispatch(SetActiveAdvanceFilters({
		// 		advanceFilter: { ['rating_computed']: [valueRateFromURL[0], valueRateFromURL[1]] }
		// 	}))
		// }

		if(queryStrings?.date_last_tasted){
			const [fromTimestampStr, toTimestampStr] = queryStrings.date_last_tasted.split(' TO ')
			
			const fromTimestamp = parseInt(fromTimestampStr)
			const toTimestamp = parseInt(toTimestampStr)

			dispatch(SetActiveAdvanceFilters({
				advanceFilter: { ['date_last_tasted']: [fromTimestamp, toTimestamp] }
			}))
		}

		if(body.body.filters.includes('referring_articles')){
			const refID = body.body.filters
			const referenceID: any = {
				article_id: [refID.split(':')[1]],
			}

			dispatch(SetActiveAdvanceFilters({
				advanceFilter: referenceID
			}))
		}

		if (body.body.query !== '') {
			dispatch(SetKeywords({
				key: body.body.query
			}))
		}
	},[])

	useEffect(() => {
		const facetFiltersData = body.body.facetFilters
		const urlParams = encodeUrlParams(facetFiltersData)

		if(didMountRef.current){
			const timer = setTimeout(() => {
				shouldSetResultState.current = false
				updateURLParams(urlParams)
			}, 500)
			
			return () => {
				clearTimeout(timer)
			}
		}
		didMountRef.current = true
	},[body.body.facetFilters, body.body.filters])

	// Fetching Data
	const {data: wineQuery, isFetching: wineQueryIsFetching, isError: wineQueryIsError} = useGetWinesFromAlgoliaQuery(body, { skip: isFacetsInQueryString ||  type !== APP_CONFIG.SEARCH.TYPES.WINE ? true : false, refetchOnReconnect: true })
	const {data: wineLimitedQuery} = useGetWinesFromAlgoliaQuery(limitBody, { skip: isFacetsInQueryString || type !== APP_CONFIG.SEARCH.TYPES.WINE ? true : false, refetchOnReconnect: true })

	const {data: articleQuery, isFetching: articleQueryIsFetching, isError: articleQueryIsError} = useGetArticlesFromAlgoliaQuery(body, { skip: isFacetsInQueryString || type !== APP_CONFIG.SEARCH.TYPES.ARTICLE ? true : false, refetchOnReconnect: true })
	const [isLoading, setLoading] = useState<boolean>()

	const isProducerList = type === APP_CONFIG.SEARCH.TYPES.WINE && wineDisplay === 'producer'
	
	function updateURLParams(params: Record<string, string>) {
		// const filters: any = varStore.AdvanceFilter.rating_computed ? varStore.AdvanceFilter : varStore.FacetFilters
		const urlSearchParams = new URLSearchParams()

		// if(filters && 'price_low' in filters && 'rating_computed' in filters){
		// 	const priceRange = `${filters?.price_low[0]} TO ${filters?.price_low[1]}`
		// 	const ratingRange = `${filters?.rating_computed[0]} TO ${filters?.rating_computed[1]}`	
		// 	if(!priceRange.includes('undefined') && !ratingRange.includes('undefined')){
		// 		params['price_low'] = priceRange
		// 		params['rating_computed'] = ratingRange
		// 	}
		// }

		// if(type === 'wine'){
		// 	if(filters && 'rating_computed' in filters){
		// 		if(!isNaN(filters?.rating_computed[0]) && !isNaN(filters?.rating_computed[1])){
		// 			if(filters?.rating_computed[0] <= filters?.rating_computed[1]){
		// 				const ratingRange = `${filters?.rating_computed[0]} TO ${filters?.rating_computed[1]}`	
		// 				if(!ratingRange.includes('undefined')){
		// 					params['rating_computed'] = ratingRange
		// 				}	
		// 			}
		// 		}
		// 	}
		// }

		if(varStore.AdvanceFilter && 'date_last_tasted' in varStore.AdvanceFilter){
			const publishedDateData = `${varStore.AdvanceFilter?.date_last_tasted[0]} TO ${varStore.AdvanceFilter?.date_last_tasted[1]}`	
			if(!publishedDateData.includes('undefined')){
				params['date_last_tasted'] = publishedDateData
			}			
		}
		
		
		if(varStore.AdvanceFilter.article_id !== undefined){
			const referringArticleId = varStore.AdvanceFilter.article_id[0]
			const value = referringArticleId.split(' ')[0]

			params['article_id'] = value
		}

		if (varStore.Keywords !== '' && typeof varStore.Keywords === 'object' && 'key' in varStore.Keywords) {
			if((varStore.Keywords as { key: any }).key.length !== 0){
				params['keyword'] = (varStore.Keywords as { key: any }).key
			}
		}
		
		for (const key in params) {
			urlSearchParams.append(key, params[key])
		}		

		if (body.body.facetFilters.length > 0 || body.body.filters.length > 0 || body.body.query.length > 0) {
			window.history.replaceState(null, '', `/search/${type}?${urlSearchParams.toString()}`)
		} else {
			window.history.replaceState(null, '', `/search/${type}`)
		}
		
	}

	// WINE QUERY
	useEffect(() => {
		setLoading(true)
		const newQueryString = decodeUrlParams(search)
		const advanceFilter = decodeUrlParamsFilter(search)
		const queryKeyword = decodeUrlKeywordQuery(search)
		if (isFacetsInQueryString) {
			if(!isToggleClearFilter){
				dispatch(SetFacetsFilters({
					activeFilters: newQueryString,
				}))
				dispatch(SetActiveAdvanceFilters({
					advanceFilter: advanceFilter,
				}))
			}
			setIsToggleClearFilter(false)
			
			if(Object.keys(queryKeyword).length !== 0){
				dispatch(SetKeywords({
					key: queryKeyword?.keyword[0],
				}))
			}
		} else {
			const newPath = getSameFacetsByType(newQueryString, search, type)
			if (newPath) {
				history.replace(`/search/${type}${newPath}`)
			}
		}
	}, [isFacetsInQueryString, type])

	useEffect(() => {
		handleChangePage(0)
		setSortProducer('asc')
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, wineDisplay])

	useEffect(() => {
		if(wineQuery) {
			if (wineQuery.success && !shouldSetResultState.current) {
				setIsQuerySuccess(true)
				const numberRange = maxNum(wineQuery.data.page, wineQuery.data.nbHits, type, wineDisplay)
				setLoading(false)
				setRangeData(wineQuery.data.range)
				setResultState((state: any) => ({	
					...state,
					max: numberRange.maxResult,
					min: numberRange.minResult,
					wines: numberRange.maxResult === tastingNoteLimit ? wineQuery.data.hits.slice(0, tastingNoteLimit) : wineQuery.data.hits,
					totalRecords: wineQuery.data.nbHits,
					totalPages: wineQuery.data.nbPages,
					filters: wineQuery.data.hits.length ? wineQuery.data.facets : state.filters
				}))
				if(wineQuery?.data?.facets?.producer && wineDisplay === 'producer'){
					producersConversion(wineQuery?.data?.facets?.producer)
				}
				shouldSetResultState.current = true
			} else {
				setLoading(false)
			}
		}	
		if(wineQueryIsError){
			console.log('Something went wrong. Please try again.')
			setLoading(false)
		}
	}, [wineQuery, wineDisplay, wineQueryIsError])


	useEffect(() => {
		if(articleQuery){
			if (articleQuery.success) {
				setIsQuerySuccess(true)
				const numberRange = maxNum(articleQuery.data.page, articleQuery.data.nbHits, type, wineDisplay)
				setLoading(false)
				setResultState((state: any) => ({
					...state,
					max: numberRange.maxResult,
					min: numberRange.minResult,
					articles: ConvertJSONArticlesCollectionToClass(articleQuery.data.hits),
					totalRecords: articleQuery.data.nbHits,
					totalPages: articleQuery.data.nbPages,
					filters: articleQuery.data.hits.length !== 0 ? articleQuery.data.facets : state.filters,
				}))
			} else {
				setLoading(false)
			}
		}
		
		if(articleQueryIsError){
			console.log('Something went wrong. Please try again.')
			setLoading(false)
		}
	}, [articleQuery, articleQueryIsError])

	useEffect(() => {
		if(articleQuery){
			if(articleIsDefault){
				if(type === 'article'){
					let minPublishedDate = articleQuery.data?.facets_stats?.published_date?.min
					if(minPublishedDate === 698630400000){
						minPublishedDate = 694224000000
					}
					dispatch(SetActiveAdvanceFilters({
						advanceFilter: { ['date_last_tasted']: [minPublishedDate, articleQuery?.data?.facets_stats?.published_date?.max] }
					}))
				}
				setArticleIsDefault(false)
			}
		}
	},[articleQuery])


	/*********canvas */
	const setInactive = () => {
		const offCanvas: HTMLElement | null = document.querySelector('#mnmd-filter')
		const backdrop: HTMLElement | null = document.querySelector('#backdrop-filter')
		if (offCanvas !== null && offCanvas.classList.contains('is-active')) {
			offCanvas.classList.remove('is-active', 'ps-container', 'ps-theme-default')
		}

		if (backdrop !== null && backdrop.classList.contains('mnmd-offcanvas-backdrop')) {
			backdrop.remove()
		}
	}

	const setActive = () => {
		const backdrop = document.createElement('div')
		const offCanvas: HTMLElement | null = document.querySelector('#mnmd-filter')
		if (offCanvas !== null) {
			offCanvas.classList.add('is-active')
			offCanvas.style.overflow = 'scroll'
			backdrop.setAttribute('id', 'backdrop-filter')
			backdrop.addEventListener('click', setInactive)

			document.body.appendChild(backdrop)
			backdrop.classList.add('mnmd-offcanvas-backdrop')
		}
	}
	/********* end canvas */

	const handleChangePage = (offset: number) => {
		let newLimit: number = limit
		if(type === APP_CONFIG.SEARCH.TYPES.WINE && wineDisplay === 'wineList'){
			newLimit = tastingNoteLimit
		}
		dispatch(SetPagination({ limit: newLimit, offset }))
		setLimitOffset(offset)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	

	const handleProducerChange = (offset: number) => {
		setProducersList( (prevState) => {
			const { producers, startIndex, endIndex } = producersPaginationData(offset, prevState.allProducers ,prevState.offset.limit)
			return {
				...prevState,
				producers,
				offset:{
					limit: prevState.offset.limit,
					offset: offset
				},
				max: endIndex,
				min: startIndex + 1
			}
		})
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const getTotalDisplay = () => {
		if(isProducerList) return `${producersList.min} - ${producersList.max} of ${producersList.totalRecords}`
		else return `${resultState.min} - ${resultState.max} of ${resultState.totalRecords}`
	}
	
	const sortProducerList = (value: string) => {
		const newValue = value === 'asc' ? producersList.allProducers.sort((a, b) => a.name.localeCompare(b.name)) : producersList.allProducers.sort((a, b) => b.name.localeCompare(a.name))
		const { producers: producersData, endIndex, startIndex } = producersPaginationData(0, newValue, selectedLimit)

		setProducersList((prevState => {
			return {
				...prevState,
				allProducers: newValue,
				producers: producersData,
				offset:{
					limit: selectedLimit,
					offset: 0
				},
				max: endIndex,
				min: startIndex + 1
			}
		}))
		setSortProducer(value)
	}

	const resultTotals = (<TwoSidedRowOption>
		<div>
			{<FilterOptions onClick={setActive}>
				<a id="my-trigger" style={{ color: '#a08436' }}>FILTER OPTIONS</a>
			</FilterOptions>}
		</div>
	</TwoSidedRowOption>)

	const sortedResults = (<TwoSidedRow>
		<div className='result'>
			<span>
				Showing {getTotalDisplay()} {isProducerList ? 'producers' : 'Results'}
			</span>
		</div>
		<div className='flexed'>
			<SearchAndSort searchType={type} isProducerList={isProducerList} sortProducer={sortProducerList} sortProducerValue={sortProducer} />
		</div>
	</TwoSidedRow>)

	const sortedMobileResults = (
		<OneRow>
			<SearchAndSort searchType={type} isProducerList={isProducerList} sortProducer={sortProducerList} sortProducerValue={sortProducer} />
		</OneRow>
	)

	const resultScope = (
		<OneRow>
			<div className='result'>
				<span>
					Showing {getTotalDisplay()} {isProducerList ? 'producers' : 'Results'}
				</span>
			</div>
		</OneRow>
	)

	const changeWineDisplayHandler = (type:string) => {
		dispatch(SetSearchDisplayType(type))
	}

	const producersConversion = (producers: any) => {
		const producersArray: {name:string; count: number}[] = [] 
		const allKeys =  Object.keys(producers).sort()
		for(const key of allKeys){
			producersArray.push({
				name: key,
				count: producers[key]
			})
		}

		const { producers: producersData, endIndex, startIndex } = producersPaginationData(0,producersArray, selectedLimit)

		setProducersList({
			totalRecords: producersArray.length,
			allProducers: producersArray,
			producers: producersData,
			offset:{
				limit: selectedLimit,
				offset: 0
			},
			totalPages: Math.ceil(producersArray.length/selectedLimit),
			max: endIndex,
			min: startIndex + 1
		})
	}

	const toggleClearFilterHandler = () =>{
		shouldSetResultState.current = false
		setIsToggleClearFilter(true)
	}

	const ungroupedResults = (<ResultsContainer>
		<br />
		{resultTotals}
		{sortedResults}
		<SectionSeparator className='separator' position={'horizontal'} ></SectionSeparator>
		{/* {resultScope} */}
		{/* {sortedMobileResults} */}
		<SearchDisplay type={type} resultState={resultState} allResults={wineLimitedQuery?.data?.hits} wineDisplay={wineDisplay} onWineListView={changeWineDisplayHandler} producersList={producersList.producers} />
		
		{isProducerList ? <div className='m-t-xl'>
			<AlgoliaPagination totalPages={producersList.totalPages} totalRecords={producersList.totalRecords} paging={producersList.offset} render={handleProducerChange} wineDisplay={wineDisplay} />
		</div>: <div className='m-t-xl'>
			{isQuerySuccess &&
				<AlgoliaPagination totalPages={resultState.totalPages} totalRecords={resultState.totalRecords} paging={varStore.Offset_Limit} render={handleChangePage} wineDisplay={wineDisplay} />
			}
		</div>}
		<div className="Sustainability-Rober">
			<span>
				<b>Sustainability</b><br />
				Robert Parker Wine Advocate has recently included sustainability markers to indicate wines that have been produced with sustainable methods.
				<a href='/free-publications/sustainability-and-wine'>Learn more about sustainability</a>
			</span>
		
		</div>
	</ResultsContainer>)

	// ||(type === APP_CONFIG.SEARCH.TYPES.PRODUCER && producers && producers.length <= 0)
	const ungroupedMobileResults = (<ResultsContainer>
		{resultTotals}
		<SectionSeparator position={'horizontal'} ></SectionSeparator>
		{resultScope}
		{sortedMobileResults}
		<SearchDisplay type={type} resultState={resultState} allResults={wineLimitedQuery?.data?.hits} wineDisplay={wineDisplay} onWineListView={changeWineDisplayHandler} producersList={producersList.producers} />
		{isProducerList ? <div className='m-t-xl'>
			<AlgoliaPagination totalPages={producersList.totalPages} totalRecords={producersList.totalRecords} paging={producersList.offset} render={handleProducerChange} wineDisplay={wineDisplay} />
		</div>: <div className='m-t-xl'>
			{isQuerySuccess &&
				<AlgoliaPagination totalPages={resultState.totalPages} totalRecords={resultState.totalRecords} paging={varStore.Offset_Limit} render={handleChangePage} wineDisplay={wineDisplay} />
			}
		</div>}
		<div className="Sustainability-Rober">
			<b>Sustainability</b><br />
			Robert Parker Wine Advocate has recently included sustainability markers to indicate wines that have been produced with sustainable methods.
			<a href='/free-publications/sustainability-and-wine'>Learn more about sustainability</a>
		</div>
	</ResultsContainer>)

	return (
		<div>
			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<div className="container">
					<TabbedMenu searchType={type} />
					{(isLoading || wineQueryIsFetching || articleQueryIsFetching) ? (<LoadingOverlay />) : ''}
					<FilterResultsContainer isMobile={isMobile ? true : false}>
						<Filters searchType={type} filters={resultState.filters} wineDisplay={wineDisplay} onSwitchList={changeWineDisplayHandler} dataRequest={body.body} dataLength={resultState} onToggleClear={toggleClearFilterHandler} rangeData={rangeData}/>
						<VerticalSeparator leftMargin='25px' rightMargin='0'></VerticalSeparator>
						{isMobile ? ungroupedMobileResults : ungroupedResults}
					</FilterResultsContainer>
				</div>
			</div>
		</div>
	)
}

export default SearchResult