/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { RP_ENV } from '../../configs'
import { MAX_RETRIES } from './constant'
import { GetBaseQuery } from '../../utils'
import { reverseObfuscateConstant } from '../../utils/Constant'

// const URL = `https://${reverseObfuscateConstant(RP_ENV.ALGOLIA_APPLICATION_ID)}-dsn.algolia.net/1/indexes`
const URLV2 = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}`

const BaseQuery = (URL: string, maxRetries: number, isFromAlgolia = false ) => {

	let baseQuery = fetchBaseQuery({
		baseUrl: URL,
		prepareHeaders: (headers) => {
			headers.set('Content-Type', 'application/json')
			headers.set('X-Algolia-Application-Id', `${reverseObfuscateConstant(RP_ENV.ALGOLIA_APPLICATION_ID)}`)
			headers.set('X-Algolia-API-Key', `${reverseObfuscateConstant(RP_ENV.ALGOLIA_API_KEY)}`)

			return headers
		},
		mode: 'cors',
	})

	if(!isFromAlgolia) {
		baseQuery = fetchBaseQuery({
			baseUrl: URL,
			prepareHeaders: (headers) => {
				headers.set('Content-Type', 'application/json')
				headers.set('X-Algolia-API-Key', `${reverseObfuscateConstant(RP_ENV.ALGOLIA_API_KEY)}`)
	
				return headers
			},
			mode: 'cors',
		})
	}

	return retry(baseQuery, {maxRetries})
}

export const AlgoliaSearchAPI = createApi({
	reducerPath: 'AlgoliaSearchAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, URLV2),

	endpoints: (builder) => ({
		getArticlesFromAlgolia: builder.query<APISearchReturn, AlgoliaQueryBody>({
			query: (params: AlgoliaQueryBody) => ({
				url: `/algolia/search-query?sort=${params.sort}&type=${params.type}`,
				method: 'POST',
				body: {
					...params.body,
					'facets': ['*'],
					'sortFacetValuesBy': 'count',
				},
			}),
		}),
		getWinesFromAlgolia: builder.query<APISearchReturn, AlgoliaQueryBody>({
			query: (params: AlgoliaQueryBody) => ({
				url: `/algolia/search-query?sort=${params.sort}&type=${params.type}`,
				method: 'POST',
				body: {
					...params.body,
					'facets': ['*'],
					'sortFacetValuesBy': 'count',
				},
			}),
		}),
	}),
})

export const AlgoliaSearchAPIV2 = createApi({
	reducerPath: 'AlgoliaSearchAPIV2',
	baseQuery: BaseQuery(URLV2, MAX_RETRIES, false),

	endpoints: (builder) => ({
		getPriceRatingRange: builder.query({
			query: (params: any) => ({
				url: `/algolia/ratings-price-range?indexName=${reverseObfuscateConstant(RP_ENV.WINE_INDEX)}`,
				method: 'POST',
				body: {
					...params,
				},
			}),
		}),
	}),
})



export const {
	useGetArticlesFromAlgoliaQuery,
	useGetWinesFromAlgoliaQuery
} = AlgoliaSearchAPI

export const {
	useGetPriceRatingRangeQuery
} = AlgoliaSearchAPIV2
