import React, { ReactElement, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import {
	PageTitle,
	QAContainer,
	QuestionTitle,
	Question,
	QuestionAnswer,
	QuestionContainer,
	AnswerContainer,
	TextStyle3,
	Row,
	LineBreak,
	AnswerContainerMobile
} from './common'
import { isMobile } from 'react-device-detect'
import { sanitizeUrl } from '@braintree/sanitize-url'


function TechnicalIssue(): ReactElement {

	const [selectedOption, setSelectedOption] = useState('')

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = event.target.value
		setSelectedOption(selectedValue)

		// Check the selected value and navigate to the target ID
		if (selectedValue === 'question1') {
			window.location.href = 'faq/#'
		} else if (selectedValue === 'question2') {
			window.location.href = 'faq/#'
		} else if (selectedValue === 'question3') {
			window.location.href = 'faq/#website-slow'
		} else if (selectedValue === 'question4') {
			window.location.href = 'faq/#error-using-site'
		}
		// Add more conditions as needed
	}
	return (
		<div role="main">
			{isMobile ?
				<><PageTitle>Technical Issues</PageTitle>
					<QAContainer>

						<span className='jump-question'>Jump to question</span>
						<select value={selectedOption} onChange={(e) => handleChange(e)} className='jump-question-select component-design'>
							<option value=''>- Select a question -</option>
							<option value='question1'>The website does not load correctly on my browser.</option>
							<option value='question2'>I get an error message saying that cookies aren&apos;t enabled.</option>
							<option value='question3'>The website loads really slowly</option>
							<option value='question4'>I got an error using the website.</option>
						</select>

						<Row>
							<div id='website-not-loading'></div>
							<AnswerContainerMobile>
								<QuestionTitle>
									The website does not load correctly on my browser.
									<QuestionAnswer>
										RobertParker.com is best supported on Chrome (latest), Firefox (latest), Safari 7+, and Microsoft Edge.
										Please download the latest version of the browser for optimal viewing. If you are a Internet Explorer user, please download <TextStyle3><a href='#'>Microsoft Edge</a></TextStyle3>.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I get an error message saying that cookies aren&apos;t enabled.
									<div id='website-slow'></div>

									<QuestionAnswer>
										Your browser&apos;s security/privacy settings are preventing it from accepting cookies from our server. Cookies are necessary for the proper functioning of the login process and a few other features of the site.
										<LineBreak />
										Click on these links to find information about configuring <TextStyle3><a href='#'>Microsoft Edge</a></TextStyle3> , <TextStyle3><a href='#'>Firefox</a></TextStyle3> , <TextStyle3><a href='#'>Opera</a></TextStyle3> , or <TextStyle3><a href='#'>Chrome</a></TextStyle3>.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									The website loads really slowly.
									<QuestionAnswer>
										As we are shifting over to the new site, there are known bugs that we are working to address, especially the front-loaded cost for the desktop site. We are currently in mid-development, and we request for your patience while we are at it. To help us improve, please email <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3> and let us know the device and browser version that you are using.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I got an error using the website.
									<div id='error-using-site'></div>

									<QuestionAnswer>
										We strive to provide the best possible experience for our subscribers. However, technology isn&apos;t perfect so we need your help in finding and correcting any technical problems. Should you encounter one, please send an email to <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3>. Let us know:
										<LineBreak />
										<p>a) the error that you encountered (e.g. any words displayed in your browser to alert you to the error -- you may be able to copy and paste these directly into your email)</p>

										<p>b) what you were doing when the error occurred and</p>

										<p>c) if the error occurred when you were doing a search, please tell us what you typed into the search engine before the error occurred.</p>
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainerMobile>
						</Row>

					</QAContainer>

				</>
				:
				<>
					<PageTitle>Technical Issues</PageTitle>
					<QAContainer>
						<Row>
							<div id='website-not-loading'></div>
							<QuestionContainer>
								<Question>
									<HashLink to=''>The website does not load correctly on my browser.</HashLink>
								</Question>
								<Question>
									<HashLink to=''>I get an error message saying that cookies aren&apos;t enabled.</HashLink>
								</Question>
								<Question>
									<HashLink to='#website-slow'>The website loads really slowly.</HashLink>
								</Question>
								<Question>
									<HashLink to='#error-using-site'>I got an error using the website.</HashLink>
								</Question>
							</QuestionContainer>
							<AnswerContainer>

								<QuestionTitle>
									The website does not load correctly on my browser.
									<QuestionAnswer>
										RobertParker.com is best supported on Chrome (latest), Firefox (latest), Safari 7+, and Microsoft Edge.
										Please download the latest version of the browser for optimal viewing. If you are a Internet Explorer user, please download <TextStyle3><a href='#'>Microsoft Edge</a></TextStyle3>.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I get an error message saying that cookies aren&apos;t enabled.
									<div id='website-slow'></div>

									<QuestionAnswer>
										Your browser&apos;s security/privacy settings are preventing it from accepting cookies from our server. Cookies are necessary for the proper functioning of the login process and a few other features of the site.
										<LineBreak />
										Click on these links to find information about configuring <TextStyle3><a href='#'>Microsoft Edge</a></TextStyle3> , <TextStyle3><a href='#'>Firefox</a></TextStyle3> , <TextStyle3><a href='#'>Opera</a></TextStyle3> , or <TextStyle3><a href='#'>Chrome</a></TextStyle3>.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									The website loads really slowly.
									<QuestionAnswer>
										As we are shifting over to the new site, there are known bugs that we are working to address, especially the front-loaded cost for the desktop site. We are currently in mid-development, and we request for your patience while we are at it. To help us improve, please email <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3> and let us know the device and browser version that you are using.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I got an error using the website.
									<div id='error-using-site'></div>

									<QuestionAnswer>
										We strive to provide the best possible experience for our subscribers. However, technology isn&apos;t perfect so we need your help in finding and correcting any technical problems. Should you encounter one, please send an email to <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3>. Let us know:
										<LineBreak />
										<p>a) the error that you encountered (e.g. any words displayed in your browser to alert you to the error -- you may be able to copy and paste these directly into your email)</p>

										<p>b) what you were doing when the error occurred and</p>

										<p>c) if the error occurred when you were doing a search, please tell us what you typed into the search engine before the error occurred.</p>
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainer>
						</Row>

					</QAContainer>
				</>
			}
		</div>
	)
}

export default TechnicalIssue