/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { RATING_RANGES, WINE_MATURITIES, VINTAGE_CHART } from '../../../../configs'
import { useGetChartDataV2Query } from '../../../../app/services'
import * as _ from 'lodash'

// Sub Components
import RatingDisplay from './RatingDisplay'

// Utilities
import { FilterRegionsByCountry, FilterScoresByTrinity, FilterVintagesByRegion, GetVintagesByCountryAndRegion, SortingData } from '../../../../utils/VintageChart'

// Styles
import {
	Chart,
	ChartHeader,
	ChartTableData,
	ChartTableHeader,
	DownloadButton,
	Maturities,
	Maturity,
	RatingRange,
	RatingRanges,
	CountryOption,
	RegionOptions,
	SelectCountryVintage,
	TitleAndDownload,
	DownloadButtonMobile,
	CardItems
} from './styles'

import SectionSeparator from '../../sections/section-separator'
import DescriptionDisplay from './DescriptionDIsplay'
import { useHistory } from 'react-router-dom'

import { isMobile } from 'react-device-detect'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { useDispatch } from 'react-redux'
import { SetFacetsFilters } from '../../../../app/slices/SearchFilters'
import { validateURL } from '../../../../utils/ValidateUrl'

function VintageChart(): ReactElement {

	const history = useHistory()

	const [countries, setCountries] = useState([''])
	const [currentCountry, setCurrentCountry] = useState('')
	const [currentGroup, setCurrentGroup] = useState('')
	const [currentRegion, setCurrentRegion] = useState('')
	const [vintages, setVintages] = useState<string[]>([])
	const [currentVintage, setCurrentVintage] = useState('')
	const [rows, setRows] = React.useState<any>()
	const [vintageScores, setVintageScores] = useState<{ year: string; label: string; }[]>([])

	const { data: newData, isLoading, isError } = useGetChartDataV2Query()
	const dispatch = useDispatch()

	useEffect(() => {
		if (newData) {
			if(newData.success){
				const allCountries = SortingData(newData.data.data, 'country')
				setCountries(allCountries)
				setCurrentCountry(allCountries[0])
			} else {
				console.log(newData.message)
			}
		}
		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [newData, isError])

	useEffect(() => {
		if (currentCountry && newData) {
			setCurrentVintage('all')
			let allRegions = FilterRegionsByCountry(newData.data.data, currentCountry)
			if (!allRegions.length) {
				allRegions = ['N/A']
			}
			setRows(allRegions)
			setCurrentRegion(allRegions[0])
			setVintageScores(FilterVintagesByRegion(newData.data.data, allRegions[0], currentCountry))
		}
	}, [currentCountry])

	useEffect(() => {
		if (newData) {
			setCurrentVintage('all')
			setVintages(GetVintagesByCountryAndRegion(newData.data.data, currentCountry, currentRegion))
		}
	}, [currentRegion])


	useEffect(() => {
		if (newData) {
			const vintageScores = FilterScoresByTrinity(newData.data.data, currentCountry, currentRegion, currentVintage)
			setVintageScores(vintageScores)
		}
	}, [currentVintage, currentRegion])

	const updateCurrentVintage = (vintage: string) => {
		setCurrentVintage(vintage)
	}

	const generateRowsMenu = () => countries && countries.length > 0 ? countries.map((country: string, key: number) => (<CountryOption key={key} selected={country === currentCountry ? true : false} onClick={() => { setCurrentCountry(country) }} >{country}</CountryOption>)) : (<></>)

	const redirectToSearchResults = (event: any, score: any): void => {
		event.preventDefault()
		dispatch(SetFacetsFilters({
			activeFilters: {},
		}))
		const parameters = []

		parameters.push(`country=${score.country}`)

		if (score.region)
			parameters.push(`region=${score.region}`)

		if (score.year) {
			parameters.push(`vintage=${score.year}`)
		}

		history.push(`/search/wine?${parameters.join('&')}`)
	}

	const MobileLayout = () => {
		return (
			<>
				<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background" style={{marginBottom: '10px'}}>
					<div className="container">
						{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>The Wine Advocate Vintage Guide</h2> : <h2 className="page-heading__title">The Wine Advocate Vintage Guide</h2>}
						<div className='page-heading__subtitle'>1970 - 2022</div>
					</div>
				</div>
				<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
					<div className="container">
						<div>
							<a href={validateURL(`${VINTAGE_CHART.PDF}`) ? `${VINTAGE_CHART.PDF}` : ''}><DownloadButtonMobile>Download PDF</DownloadButtonMobile></a>
						</div>

						<div style={{ marginBottom: '-4em' }}>
							<br />
							<label htmlFor='country-selection'>Countries</label>
							<div>
								<select id='country-selection' name='country-selection' className='component-design' value={currentCountry} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { setCurrentCountry(event.target.value) }}>
									{countries ? countries.map((country: string, key: number) => (<option key={key} value={country} >{country}</option>)) : (<></>)}
								</select>
							</div>
							<br />
						</div>
					</div>
				</div>
				<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
					<div className="container">
						<div className="row">
							<div className="mnmd-sub-col mnmd-sub-col--left sidebar js-sticky-sidebar" role="complementary">
								<b style={{ fontSize: '16px' }}>Rating ranges</b>
								<div className='ranges-container'><br />
									{RATING_RANGES.map((range: { high: number, low: number, color: string, description: string }, key: number) => (
										<RatingRange key={key} color={range.color}>
											<div className='color'>&nbsp;</div>
											<div className='rating'>{range.low != 0 ? `${range.low}-${range.high}` : `< ${range.high}`}</div>
											<div className='comment'>{range.description}</div>
										</RatingRange>
									))}
									<br />
								</div>
								<b style={{ fontSize: '16px' }}>Maturity</b>
								<div className='maturities-container'><br />
									{WINE_MATURITIES.map((maturity: { label: string, description: string }, key: number) => (
										<Maturity key={key} >
											<div className='label'>{maturity.label}</div>
											<div className='description'>{maturity.description}</div>
										</Maturity>
									))}
									<br />
								</div>
							</div>
							<div className="mnmd-main-col has-left-sidebar" role="main">
								<SectionSeparator position={'horizontal'} ></SectionSeparator>
								{currentRegion !== 'N/A' && <div>
									<br />
									<label htmlFor='region-selection'>Select a region</label>
									<div>
										<select id='region-selection' name='region-selection' className='component-design' value={currentRegion} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { setCurrentRegion(event.target.value) }}>
											{rows ? rows.map((region: string, key: number) => (<option key={key} value={region} >{region}</option>)) : (<></>)}
										</select>
									</div>
									<br />
								</div>}
								<div>
									<br />
									<label htmlFor='vintage-selection'>Select a vintage</label>
									<div>
										<select id='vintage-selection' name='vintage-selection' className='component-design' value={currentVintage} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { updateCurrentVintage(event.target.value) }}>
											<option value='all'>All vintages</option>
											{vintages ? vintages.map((year: string, key: number) => (<option key={key} value={year} >{year}</option>)).reverse() : null}
										</select>
									</div>
									<br />
								</div>
								<b style={{ fontSize: '24px', marginLeft: '0.5rem' }}>{currentGroup} {currentCountry}</b><br /><br />
								{vintageScores ? _.orderBy(vintageScores, 'year', 'desc').map((score: VintageScore, key: number) => (
									<div key={key}>
										<CardItems>
											<div className='label'>Vintage </div>
											<div className='description'>{score.year}</div>
										</CardItems>
										<CardItems>
											<div className='label'>Region </div>
											<div className='description'>{currentRegion}</div>
										</CardItems>
										<CardItems>
											<div className='label'>Description </div>
											<div className='description'><DescriptionDisplay rating={score.label} /></div>
										</CardItems>
										<CardItems>
											<div className='label'>Rating </div>
											<div className='description'><RatingDisplay rating={score.label} /></div>
										</CardItems>
										<CardItems>
											<a href='#' onClick={(event) => { redirectToSearchResults(event, score) }}>View wines</a>
										</CardItems>
										<SectionSeparator position={'horizontal'}></SectionSeparator>
									</div>
								)) : (<></>)}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	const DesktopLayout = () => {
		return (
			<div className="single-entry">
				<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
					<div className="container">
						<header className="single-header">
							<TitleAndDownload>
								<div className='title'>
									<h3>The Wine Advocate Vintage Guide</h3>
									<span style={{ fontSize: '24px' }}>1970 - 2022</span>
								</div>
								<a href={validateURL(`${VINTAGE_CHART.PDF}`) ? `${VINTAGE_CHART.PDF}` : ''}><DownloadButton>Download PDF</DownloadButton></a>
							</TitleAndDownload>
						</header>
					</div>
				</div>
				<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
					<div className="container">
						<div className="row">
							<div className="mnmd-sub-col mnmd-sub-col--left sidebar js-sticky-sidebar" role="complementary">
								<RegionOptions>
									<h3>Countries</h3>
									<div className='options'>
										{generateRowsMenu()}
									</div>
								</RegionOptions>
								<RatingRanges>
									<h3>Rating ranges</h3>
									<div className='ranges-container'>
										{RATING_RANGES.map((range: { high: number, low: number, color: string, description: string }, key: number) => (
											<RatingRange key={key} color={range.color}>
												<div className='color'>&nbsp;</div>
												<div className='rating'>{range.low != 0 ? `${range.low}-${range.high}` : `< ${range.high}`}</div>
												<div className='comment'>{range.description}</div>
											</RatingRange>
										))}
									</div>
								</RatingRanges>
								<Maturities>
									<h3>Maturity</h3>
									<div className='maturities-container'>
										{WINE_MATURITIES.map((maturity: { label: string, description: string }, key: number) => (
											<Maturity key={key} >
												<div className='label'>{maturity.label}</div>
												<div className='description'>{maturity.description}</div>
											</Maturity>
										))}
									</div>
								</Maturities>
							</div>
							<div className="mnmd-main-col has-left-sidebar" role="main">
								<SelectCountryVintage>
									{currentRegion !== 'N/A' && <div>
										<label htmlFor='region-selection'>Select a region</label>
										<div>
											<select id='region-selection' name='region-selection' className='component-design' value={currentRegion} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { setCurrentRegion(event.target.value) }}>
												{rows ? rows.map((region: string, key: number) => (<option key={key} value={region} >{region}</option>)) : (<></>)}
											</select>
										</div>
									</div>}
									<div>
										<label htmlFor='vintage-selection'>Select a vintage</label>
										<div>
											<select style={{ 'width': '200px' }} id='vintage-selection' name='vintage-selection' className='component-design' value={currentVintage} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { updateCurrentVintage(event.target.value) }}>
												<option value='all'>All vintages</option>
												{vintages ? vintages.map((year: string, key: number) => (<option key={key} value={year} >{year}</option>)).reverse() : null}
											</select>
										</div>
									</div>
								</SelectCountryVintage>
								<ChartHeader>
									<h3>{currentGroup} {currentCountry}</h3>
									<p>Vintages that are not found in the list are not yet sufficiently tasted to rate.</p>
								</ChartHeader>
								<Chart>
									<div className='header'>
										<ChartTableHeader width={'68px'} >Vintage</ChartTableHeader>
										<ChartTableHeader width={'200px'} >Region</ChartTableHeader>
										<ChartTableHeader width={'228px'} >Description</ChartTableHeader>
										<ChartTableHeader width={'68px'} >Rating</ChartTableHeader>
										<ChartTableHeader width={'80px'} >&nbsp;</ChartTableHeader>
									</div>
									<SectionSeparator position={'horizontal'} ></SectionSeparator>
									{vintageScores ? _.orderBy(vintageScores, 'year', 'desc').map((score: VintageScore, key: number) => (
										<div key={key}>
											<div className='row flex-nowrap'>
												<ChartTableData width={'68px'} >{`${score.year}`}</ChartTableData>
												<ChartTableData width={'200px'} >
													<div>{currentRegion}</div>
												</ChartTableData>
												<ChartTableData width={'228px'} >
													<DescriptionDisplay rating={score.label} />
												</ChartTableData>
												<ChartTableData width={'68px'}>
													<RatingDisplay rating={score.label} />
												</ChartTableData>
												<ChartTableData width={'80px'}>
													<a href='#' onClick={(event) => { redirectToSearchResults(event, score) }}>View wines</a>
												</ChartTableData>
											</div>
											<SectionSeparator position={'horizontal'}></SectionSeparator>
										</div>
									)) : (<></>)}

								</Chart>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			{isLoading ? <LoadingOverlay /> : isMobile ? <MobileLayout /> : <DesktopLayout />}
		</>
	)
}

export default VintageChart