/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// Components
import algoliasearch from 'algoliasearch'
import { APP_CONFIG, RP_ENV } from '../../../../configs'
import SectionSeparator from '../../sections/section-separator'
import TabbedMenu from '../search-results/TabbedMenu'
// Styles
import {
	FilterAndResultWrapperMobile,
	FilterAndResultsWrapper, FilterOption, FilterOptions, KeywordAndHardcodedFilters,
	PageTitle,
	ResetButton,
	Title
} from './styles'

// Slices
import { useAppSelector } from '../../../../app/hooks'
import { ClearActiveAdvanceFilters, SearchFilters as SearchFiltersFromStore, SetActiveAdvanceFilters, SetFacetsFilters } from '../../../../app/slices/SearchFilters'

// Sections
import { useDispatch, useSelector } from 'react-redux'
import { getAdvanceSearchOptions, getFilterKey, getFilterTitle } from '../../../../utils/FilterUtils'
import { KeywordFilter, convertedItem } from './sections'
import CustomFilters from './sections/CustomFilters'
import { IsUserAuthenticated } from '../../../../utils'
import RatingFilter from './sections/RatingFilter'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { reverseObfuscateConstant } from '../../../../utils/Constant'
import PublicationDateFilter from './sections/PublicationDateFilter'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'

type FilterPairing = {
	index: number,
	label: string,
	component: ReactElement
}

type PriceRatingRangeType = {
	price_low: number[],
	rating_computed: number[]
}

type PublicationDateType = {
	dateFrom: number,
	dateTo: number,
}

type RangeError = {
	isShow: boolean;
	message: string
}

const appId = reverseObfuscateConstant(RP_ENV.ALGOLIA_APPLICATION_ID) as string
const apiKey = reverseObfuscateConstant(RP_ENV.ALGOLIA_API_KEY) as string
const searchClient = algoliasearch(appId, apiKey)

function AdvancedSearch(): ReactElement {
	const history = useHistory()

	const { userData } = useAppSelector(UsersFromStore)
	const { type } = useParams<{ type: string }>()
	const [activeFilter, setActiveFilter] = useState(0)
	const [facetFilter, setFacetFilter] = useState<any>([])
	const [keywords, setKeywords] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const {AdvanceFilter: advanceFilter} = useAppSelector(SearchFiltersFromStore)
	const priceRateRangeData = useSelector(SearchFiltersFromStore)

	const dispatch = useDispatch()

	const wineIndex =  reverseObfuscateConstant(RP_ENV.WINE_INDEX) || ''
	const articleIndex =  reverseObfuscateConstant(RP_ENV.ARTICLE_INDEX) || ''
	const SELECTED_INDEX =  type === APP_CONFIG.SEARCH.TYPES.WINE ? wineIndex : articleIndex

	const [isRatingFilterOpen, setIsRatingFilterOpen] = useState(false)
	const [isPublicationDateOpen, setIsPublicationDateOpen] = useState(true)
	const [ratingRangeData, setRatingRangeData] = useState<PriceRatingRangeType>({
		price_low: [0, 110000],
		rating_computed: [50, 100]
	})

	const [chartData, setChartData] = useState<any>()

	const [isSliderMoved, setIsSliderMoved] = useState({
		price_low: false,
		rating_computed: false,
	})

	const [range] = useState({
		price_low: {
			min: 0,
			max: 200000
		},
		rating_computed: {
			min: 50,
			max: 100
		}
	})

	const [publicationDateData, setPublicationDateData] = useState<PublicationDateType>({
		dateFrom: 0,
		dateTo: 0,
	})

	const [rangeError, setRangeError] = useState<RangeError>({
		isShow: false,
		message: '',
	})

	const rangeInputClickedHandler = (type: 'min' | 'max', reference: string, value=0) => {
		if(reference === 'rating_computed'){
			isRatingMoved()
			if(type === 'max') {
				return setRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [prevState.rating_computed[0], value]
				}))
			}
	
			if(type === 'min') {
				return setRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [value, prevState.rating_computed[1]]
				}))
			}
		}
	}

	

	useEffect(() => {
		if(ratingRangeData){
			if(isNaN(ratingRangeData.rating_computed[0])){
				setRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [0, prevState.rating_computed[1]]
				}))
			}

			if(isNaN(ratingRangeData.rating_computed[1])){
				setRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [prevState.rating_computed[0], 0]
				}))
			}
			
			if(ratingRangeData.rating_computed[0] >= 101){
				setRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [prevState.rating_computed[1] - 1, prevState.rating_computed[1]]
				}))
			}

			if(ratingRangeData.rating_computed[1] < ratingRangeData.rating_computed[0]){
				setRangeError(prevState => ({
					...prevState,
					isShow: true,
					message: 'Invalid rating range.'
				}))
			} else {
				setRangeError(prevState => ({
					...prevState,
					isShow: false
				}))
			}

			if(ratingRangeData.rating_computed[1] > 100){
				setRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [prevState.rating_computed[0], 100]
				}))
			}

			if(ratingRangeData.rating_computed[0] < 50){
				setRangeError(prevState => ({
					...prevState,
					isShow: true,
					message: 'Invalid rating range.'
				}))
			}
		}
	},[ratingRangeData])

	const setInputRangeHandler = (event: ChangeEvent<HTMLInputElement>, type: 'max' | 'min', reference: string) => {
		const {value} = event.target

		const convertValue = parseFloat(value)
		rangeInputClickedHandler(type, reference, convertValue)
	}


	const isRatingMoved = () => {
		if(!isSliderMoved.rating_computed){
			return setIsSliderMoved(prevState => ({
				...prevState,
				rating_computed: true
			}))
		}
	}

	const setRangeHandler = (data: number | number[], reference: string) => {
		if(reference === 'rating_computed' && typeof data !== 'number') {
			isRatingMoved()
			return setRatingRangeData(prevState => ({
				...prevState,
				rating_computed: data
			}))
		}

		// if(reference === 'price_low' && typeof data !== 'number') {
		// 	isPriceMoved()
		// 	return setRatingRangeData(prevState => ({
		// 		...prevState,
		// 		price_low: data
		// 	}))
		// }
	}

	useEffect(() => {
		fetchFilteredSearch()
	}, [IsUserAuthenticated()])

	const fetchFilteredSearch = async () => {
		try {
			setIsLoading(true)
			const results = await searchClient.initIndex(SELECTED_INDEX)
				.search('', {
					facets: ['*'],
					sortFacetValuesBy: 'count' ,
				})

			if(results){
				setChartData(results?.facets?.rating_computed)
			}

			const data = results.facets
			const setFilterKeys = getFilterKey(data, 'advance-search')
			if (data) {
				const FilterPairings: FilterPairing[] = []
				
				setFilterKeys.forEach((label: string, index: number) => {
					if (data[label] !== undefined) {
						const convertedLabel = convertedItem(data[label])
						const childData = getAdvanceSearchOptions(data[label], label)
						const title = getFilterTitle(label)
						FilterPairings.push({
							index: index,
							label: title,
							component: <CustomFilters items={convertedLabel} key={index} childData={childData} title={title} reference={label} showSearch={childData.length >= 100} type={type} />
						})
					}
				})
				setFacetFilter(FilterPairings)
			}
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const activateFilter = (filter: number) => {
		if(filter === 14) {
			setIsRatingFilterOpen(true)
			setIsPublicationDateOpen(false)
		} else if(filter === 15){
			setIsPublicationDateOpen(true)
			setIsRatingFilterOpen(false)
		} else {
			setActiveFilter(filter)
			setIsPublicationDateOpen(false)
			setIsRatingFilterOpen(false)
		}
	}

	const selectPair = (event:ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target
		if(parseInt(value) === 14) {
			setIsRatingFilterOpen(true)
			setIsPublicationDateOpen(false)
		} else if(parseInt(value) === 15){
			setIsPublicationDateOpen(true)
			setIsRatingFilterOpen(false)
		} else {
			setActiveFilter(parseInt(value))
			setIsPublicationDateOpen(false)
			setIsRatingFilterOpen(false)
		}
	}

	const resetActiveFilters = () => {
		dispatch(ClearActiveAdvanceFilters())
	}

	const showSearchResults = () => {
		if(rangeError.isShow){
			return
		}
		let activeFilters = {}

		Object.keys(advanceFilter).map(data => {
			const facets = [] as string[]
			advanceFilter[data].map((ele: any, i: number) => {
				if (advanceFilter[data][i].isSelected) {
					facets.push(advanceFilter[data][i].value)
					activeFilters = { ...activeFilters, [data]: facets }
					
				}
			})
		})
		
		dispatch(SetFacetsFilters({
			activeFilters: activeFilters,
			facetFilter: priceRateRangeData.FacetFilters
		}))

		if(ratingRangeData['rating_computed'][0] !== 50 || ratingRangeData['rating_computed'][1] !== 100){
			dispatch(SetActiveAdvanceFilters({
				advanceFilter: { ['rating_computed']: [ratingRangeData['rating_computed'][0], ratingRangeData['rating_computed'][1]] }
			}))
		}

		publicationDateApplyHandler(({publicationDateData}))
				
		if (keywords) {
			history.push(`/search/${type}?keyword=${keywords}`)
		} else {
			history.push(`/search/${type}`)
		}

	}

	const enterKeywordHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if(event.key === 'Enter' || event.keyCode == 13) return showSearchResults()
		else return
	}

	const publicationDateApplyHandler = (data: any) => {
		const fromDate = data.publicationDateData.dateFrom
		const toDate = data.publicationDateData.dateTo

		const fromUnixTimestamp = new Date(fromDate).getTime()
		const toUnixTimestamp = new Date(toDate).getTime()
		
		if(fromUnixTimestamp  !== 0 || toUnixTimestamp !== 0){
			dispatch(SetActiveAdvanceFilters({
				advanceFilter: { ['date_last_tasted']: [fromUnixTimestamp, toUnixTimestamp] }
			}))
		}
	}

	return (
		<div className="site-content">
			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<div className="container">
					<TabbedMenu searchType={type} />
					<PageTitle>
						Advanced Search
					</PageTitle>
					{isLoading ? (<><LoadingOverlay/><span>Loading...</span></>) : <>
						<KeywordAndHardcodedFilters>
							<div className='search-container'>
								<KeywordFilter onEnter={enterKeywordHandler} setKeywords={setKeywords} keywords={keywords} />
								<div className='show-result' onClick={showSearchResults} ><span>SHOW RESULTS</span></div>
							</div>
							<div className='hardcoded-filters'>
								<div>
									<input type='checkbox' id='green-emblem-filter' />
									<label htmlFor='rp-ratings-fitler'> Results with Green Emblem only</label>
								</div>
							</div>
							<div className='show-result' onClick={showSearchResults} ><span>SHOW RESULTS</span></div>
						</KeywordAndHardcodedFilters>
						<SectionSeparator position={'horizontal'}></SectionSeparator>

						{/* For Mobile device */}
						<FilterAndResultWrapperMobile>
							<span>Filter</span>
							<select onChange={selectPair}>
								<option key={15} value={15}>Publication Date</option>
								{!isFreeUser(userData) && 
									<option key={14} value={14}>Rating</option>
								}
								
								{facetFilter.map((pairing: FilterPairing, pairing_key: number) => (
									<option key={pairing_key} value={pairing.index}>{pairing.label}</option>
								))}
							</select>
							<button onClick={resetActiveFilters}>Reset</button>
							<SectionSeparator position={'horizontal'}></SectionSeparator>
						</FilterAndResultWrapperMobile>
						
						<FilterAndResultsWrapper>
							<div className='filters-container'>
								<div className='header'>
									<Title margin={'0px 0px 0px 0px'}>Filters</Title>
									<div><ResetButton onClick={resetActiveFilters}>Reset</ResetButton></div>
								</div>
								<FilterOptions>
									
									<>
										<FilterOption isActive={isPublicationDateOpen} key={15} >
											<div className='marker'></div>
											<div onClick={() => { activateFilter(15) }} className='menu'>Publication Date</div>
										</FilterOption>

										{/* <FilterOption isActive={isRatingFilterOpen} key={14} >
											<div className='marker'></div>
											<div onClick={() => { activateFilter(14) }} className='menu'>Rating</div>
										</FilterOption> */}
										
									</>
									
									
									{facetFilter.map((pairing: FilterPairing, pairing_key: number) => (
										<FilterOption isActive={activeFilter === pairing_key && !isRatingFilterOpen && !isPublicationDateOpen} key={pairing_key} >
											<div className='marker'></div>
											<div onClick={() => { activateFilter(pairing.index) }} className='menu'>{pairing.label}</div>
										</FilterOption>
									))}
								</FilterOptions>
							</div>
							<SectionSeparator position={'vertical'} leftMargin={'20px'} rightMargin={'20px'}></SectionSeparator>
							{isPublicationDateOpen ?
								<PublicationDateFilter key={15} type={'advance-search'} clickApplyHandler={publicationDateApplyHandler}/> : <>
									{facetFilter.map((pairing: FilterPairing, pairing_key: number) => (activeFilter === pairing_key ? pairing.component : null))}
								</>}
							{/* {isPublicationDateOpen ?
								<PublicationDateFilter key={15} type={'advance-search'} clickApplyHandler={publicationDateApplyHandler}/>
								:
								isRatingFilterOpen ? 
									<RatingFilter key={14} onInputClicked={rangeInputClickedHandler} rangeData={ratingRangeData.rating_computed} onSettingInputRangeHandler={setInputRangeHandler} onSettingRangeHandler={setRangeHandler} indicator='RP' minMaxData={range.rating_computed} chartData={chartData} rangeError={rangeError}></RatingFilter>
									: 
									<>
										{facetFilter.map((pairing: FilterPairing, pairing_key: number) => (activeFilter === pairing_key ? pairing.component : null))}
									</>
							} */}

						</FilterAndResultsWrapper>
						<SectionSeparator position={'horizontal'} ></SectionSeparator>
					</>}
				</div>
			</div>
		</div>
	)
}

export default AdvancedSearch