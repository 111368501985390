/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, ReactElement } from 'react'
import { Redirect, Route } from 'react-router-dom'

// Router related 
import { useHistory } from 'react-router-dom'

// Slices
import { useAppSelector } from '../../app/hooks'
import { clearCookie, getCookie } from '../../utils/CookieUtils'
import { ClearUserSession, SaveAuthenticationSession } from '../../utils'
import { useDispatch } from 'react-redux'
import { Users as UsersFromStore } from '../../app/slices/Users'
import { Authentication as AuthStore, UpdateUserAuthDetails } from '../../app/slices/Authentication'
import moment from 'moment'
import { useRefreshAccessTokenMutation } from '../../app/services/Users'
import { ClearSelectedWines } from '../../app/slices/SearchResults'

function TitleRoute(props: any): ReactElement {
	const history = useHistory()

	const [ userRefreshAccessMutation, {data: refreshData}] = useRefreshAccessTokenMutation()

	const dispatch = useDispatch()
	const { title, ...rest } = props

	const { userData } = useAppSelector(UsersFromStore)
	const Authentication  = useAppSelector(AuthStore)
	const { User } = Authentication
	const { params } = rest?.computedMatch

	const location = window.location

	useEffect(() => {
		const rootUrl = window.location.origin
		const robotTag = document.querySelector('meta[name="robots"]')

		if (rootUrl === 'https://rp-wine-v2.jam247.dev' && !robotTag) {
			const metaTag = document.createElement('meta')
			metaTag.name = 'robots'
			metaTag.content = 'noindex'
			document.head.appendChild(metaTag)
		}

	}, [])

	useEffect(() => {
		const finalTitle = 'Robert Parker ' + (title !== ' ' ? ('- ' + title) : title)
		document.title = finalTitle
	})
	
	useEffect(() => {
		
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			'event': 'virtualPageview',
			'pageUrl': location.href,
			'pageTitle': 'Robert Parker - Article',
			'isSignedIn': true,
			'isBusiness': false,
			'isOptin': true,
			'customerid': userData?._id,
			'articleid': params?.articleID
		})
		
		history.listen(() => {
			const LastLogin = getCookie('LastLogin')
			if (new Date() >= new Date(LastLogin)) {
				ClearUserSession(dispatch)
			}
		})
		
	},[title, userData, window.location.href])

	const fetchRefreshToken = async () => {
		try {
			const { refreshToken, accessToken, user } = User
			await userRefreshAccessMutation({ refreshToken, accessToken, userId: user._id})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if(User && User.accessTokenExpiresAt){
			const dayBeforeExpiredDate = moment(User.accessTokenExpiresAt)
			const isAfterExpiration = dayBeforeExpiredDate.isAfter(moment(new Date()).add(20, 'days'))

			if(dayBeforeExpiredDate.isSame(moment()) || dayBeforeExpiredDate.isBefore(moment()) || isAfterExpiration) 
				fetchRefreshToken()
		}
	},[props.path, User])

	useEffect(() => {
		if(refreshData && refreshData.success){
			const { user, client, refreshTokenExpiresAt } = User
			
			const userPayload = {
				token: refreshData.data.token,
				tokenExpiry: refreshData.data.expirationAt,
				refreshToken: refreshData.data.refreshToken,
				refreshTokenExpiry: refreshTokenExpiresAt,
				clientId: client._id,
				country: user.country,
				userId: user._id
			}

			SaveAuthenticationSession(
				'user',
				refreshData.data.token,
				refreshData.data.expirationAt,
				refreshData.data.refreshToken,
				'',
				client._id,
				user._id,
				user.country
			)

			dispatch(UpdateUserAuthDetails(userPayload))
			window.location.reload()
		}

		if(refreshData && !refreshData.success && refreshData.message === 'Refresh token is invalid.'){
			clearCookie()
			ClearUserSession(dispatch)
			dispatch(ClearSelectedWines())
			window.location.href = '/login?AuthExpired=true'
			
		}
		
	}, [refreshData])
	

	if (props.path === '/sign-in') return <Redirect to='/login' />

	return <Route {...rest} />
}

export default TitleRoute
