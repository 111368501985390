import React, { ReactElement, useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { ActionsContainer, BackButtonGroup, FormGroupAddUser, FormNotifications, ProceedButtonGroup, SuccessNotification, Title } from '../styles'
import { Link, useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { useAddUserFromCompanyGroupMutation } from '../../../../../app/services'
import { dataCySelector } from '../../../../../app/services/constant'
import { IsUserAuthenticated } from '../../../../../utils'
import ErrorModal from '../../../../../components/error-modal'
import Input from '../../../../../components/form-components/input/Input'

const AddGroup = (): ReactElement => {
	const [isLoading, setIsLoading] = useState(false)
	const [isInvitedSuccess, setIsInvitedSuccess] = useState(false) // Example state for success message
	const [seconds, setSeconds] = useState(3) // Example state for countdown
	const [addUserFromCompanyGroupMutation] = useAddUserFromCompanyGroupMutation()
	const [isModalError, setIsModalError] = useState({
		error: {
			isShow: false,
			message: '',
		}
	})

	const { userData } = useAppSelector(UsersFromStore)
	const history = useHistory()
	const { control, handleSubmit } = useForm<FieldValues>({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
		}
	})

	useEffect(() => {
		if (!IsUserAuthenticated()) {
			history.push('/login')
		}
	}, [history])

	useEffect(() => {
		if (userData && userData?.subscription?.addOns?.length === 0) {
			history.push('/my-company')
		}
	}, [userData, history])

	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		console.log(true)
		setIsLoading(true)
		const formData = {
			managerId: userData._id,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email
		}
		const res = await addUserFromCompanyGroupMutation(formData).unwrap()
		console.log('res', res)
		if (res?.success) {
			setIsLoading(false)
			setIsInvitedSuccess(true)
		} else {
			setIsLoading(false)
			setIsModalError({
				...isModalError,
				error: {
					isShow: true,
					message: res?.message || 'An unknown error occurred'
				}
			})
		}
	}

	useEffect(() => {
		if (isInvitedSuccess && seconds > 0) {
			const timer = setTimeout(() => {
				setSeconds(prevSeconds => prevSeconds - 1)
			}, 1000)
			return () => clearTimeout(timer)
		} else if (seconds === 0) {
			setIsInvitedSuccess(false)
			window.location.href = '/my-company'
		}
		if (!isInvitedSuccess) {
			setSeconds(3)
		}
	}, [isInvitedSuccess, seconds])


	console.log('isLoading', isLoading)

	return (
		<>
			{isLoading && <LoadingOverlay />}
			<Title><div>Add Group User</div></Title>
			<FormGroupAddUser>
				<form onSubmit={handleSubmit(onSubmit)}>

					{isInvitedSuccess && (
						<FormNotifications>
							<div className={'form-success deleted-success'}>
								<SuccessNotification>Invitation successfully sent. The page will automatically refresh in {seconds} seconds.</SuccessNotification>
							</div>
						</FormNotifications>
					)}
					<p className='form-title'>Group user information</p>
					<div className='first-row'>
						<Input
							control={control}
							name='firstName'
							type='text'
							placeholder='First Name'
							rules={{ required: 'First name is required' }}
						/>
						<Input
							control={control}
							name='lastName'
							type='text'
							placeholder='Last Name'
							rules={{ required: 'Last name is required' }}
						/>
					</div>
					<div className='second-row'>
						<Input
							control={control}
							name='email'
							type='email'
							placeholder='Email'
							rules={{ required: 'Email is required' }}
						/>
					</div>

					<ActionsContainer>
						<div className='activation-buttons'>
							<Link to={'/my-company'} {...dataCySelector('back-btn')}>
								<BackButtonGroup><span>BACK</span></BackButtonGroup>
							</Link>
							<ProceedButtonGroup type='submit'{...dataCySelector('send-invite-btn')}>SEND INVITE</ProceedButtonGroup>
						</div>
					</ActionsContainer>
				</form>
				{isModalError.error.isShow && (
					<ErrorModal
						messageBody={isModalError.error.message}
						isOpen={isModalError.error.isShow}
						onClose={() => setIsModalError((state) => ({ ...state, error: { isShow: false, message: '' } }))}
					/>
				)}
			</FormGroupAddUser>
		</>
	)
}

export default AddGroup
