/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FieldValues, useForm } from 'react-hook-form'

// Sections
import CheckoutButton from '../../../../components/form-components/buttons/CheckoutButton'
import { RPColors } from '../../../../styles/CSSConstant'
import { DropdownContainer } from '../../../../components/form-components/styles'
import ProfileActionDropdown from './components/ProfileActionDropdown'
import { GiftCardFormBody } from '../gift-subscription/styles'
import ProfilePreferences from './components/ProfilePreferences'
import UpdateProfileForm from './components/UpdateProfileForm'
import ProfileSelector from './components/ProfileSelector'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { useAppSelector } from '../../../../app/hooks'
import { useGetTradeDirectoryLookupCountriesQuery, useGetUserDetailsByIdQuery } from '../../../../app/services'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { reverseObfuscateConstant } from '../../../../utils/Constant'
import { API_ENDPOINTS, RP_ENV } from '../../../../configs'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'
import ErrorModal from '../../../../components/error-modal'
import EditProfileForm from './components/EditProfileForm'

// Styles
import {
	ProfileMainContainer,
	Title,
	ProfileForm,
	ResetButton,
	ActionContainer,
	ProfileFormContainer,
	CenterizedDropdown,
	StatusModalContainer,
	StatusSuccess,
	StatusFailed,
} from './styles'

type UserDetailsType = {
	firstName: string;
	lastName: string;
	email: string;
	userCurrentCountry: string;
	nameOnNotes: string;
	anonymousNotes: boolean;
	privateNotes: boolean;
	promo: boolean;
	subscribe: boolean;
}

function MyProfile(): ReactElement {
	const { handleSubmit, setValue, setError, control  } = useForm()

	const [isLoading, setIsLoading] = useState(false)
	const [successState, setSuccessState] = useState(false)
	const [formErrorState, setFormErrorState] = useState(false)

	const { userData } = useAppSelector(UsersFromStore)
	const authentication = useAppSelector(AuthFromStore)

	const [updateProfileError, setUpdateProfileError] = useState({
		isError: false,
		message: ''
	})
	const [userDetails, setUserDetails] = useState<UserDetailsType>()
	const { data: countryData } = useGetTradeDirectoryLookupCountriesQuery('')
	const { data: user, refetch: userRefetch, isLoading: userDataLoading } = useGetUserDetailsByIdQuery(userData?._id, {skip: !userData?._id})
	
	useEffect(() => {
		if(userData && user && user.success){

			const userInfo = {
				firstName: user.data?.profile?.name?.first_name,
				lastName: user.data?.profile?.name?.last_name,
				email: user.data?.emails[0].address,
				userCurrentCountry: user.data?.profile?.preferences?.country?.name,
				nameOnNotes: user.data?.profile?.preferences?.name_on_notes,
				anonymousNotes: user.data?.profile?.preferences?.anonymous_notes,
				privateNotes: user.data?.profile?.preferences?.private_notes,
				subscribe: user.data?.profile?.preferences?.receive_newsletter,
				promo: user.data?.profile?.preferences?.receive_marketingletter
			}

			setUserDetails(userInfo)

			setValue('firstName', userInfo.firstName)
			setValue('lastName', userInfo.lastName)
			setValue('email', userInfo.email)
			setValue('country', userInfo.userCurrentCountry)
			setValue('isNewsLetter', userInfo.subscribe)
			setValue('isNotNewsLetter', !userInfo.subscribe )
			setValue('isMarketing', userInfo.promo)
			setValue('isNotMarketing', !userInfo.promo)
		}
	}, [userData, user])
	
	const handleResetChanges = () => {

		if(user && user.success){

			const userInfo = {
				firstName: user.data?.profile?.name?.first_name,
				lastName: user.data?.profile?.name?.last_name,
				email: user.data?.emails[0].address,
				userCurrentCountry: user.data?.profile?.preferences?.country?.name,
				promo: user.data?.profile?.preferences?.receive_newsletter,
				subscribe: user.data?.profile?.preferences?.receive_marketingletter
			}

			if(selectedForm?.title === 'Edit Profile'){
				setValue('firstName', userInfo.firstName)
				setValue('lastName', userInfo.lastName)
				setValue('email', userInfo.email)
			}
	
			if(selectedForm?.title === 'Update Password'){
				setValue('currentPassword', '')
				setValue('newPassword', '')
				setValue('confirmPassword', '')
			}
	
			if(selectedForm?.title === 'Preferences'){
				setValue('isNewsLetter', userInfo.promo)
				setValue('isNotNewsLetter', !userInfo.promo )
				setValue('isMarketing', userInfo.subscribe)
				setValue('isNotMarketing', !userInfo.subscribe)
				setValue('country', userInfo.userCurrentCountry)
			}
		}
	}

	const handleSelect = (value: string) => {
		const form = handleSelectedForm(value)
		setSelectedForm(form)
		setFormErrorState(false)
	}
	
	const validatePassword = (formData: any) => {
		if (formData.newPassword === formData.confirmPassword) {
			return true
		} else {
			setError('confirmPassword', { type: 'manual', message: 'Password did not match' })
			setFormErrorState(true)
			setSuccessState(false)
			return false
		}
	}

	const handleSelectedForm = (selectedAction:string) => {
		switch (selectedAction) {
		case 'Edit Profile':
			return {
				title: 'Edit Profile',
				form: <EditProfileForm control={control} />
			}
		case 'Update Password':
			return {
				title: 'Update Password',
				form: <UpdateProfileForm control={control} />
			}
		case 'Preferences':
			return {
				title: 'Preferences',
				form: <ProfilePreferences control={control} setValue={setValue} />
			}
		default:
			break
		}
	}
	const [selectedForm, setSelectedForm] = useState(handleSelectedForm('Edit Profile'))

	const onSubmitHandler = async (formData: FieldValues) => {

		try {
			if (selectedForm?.title === 'Edit Profile') {
				setIsLoading(true)
				const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user/update-user-particulars`
				const data = await fetch(url, {
					method: 'POST',
					headers: {
						'Authorization': 'Bearer ' + authentication.User.accessToken,
						'x-api-key': RP_ENV.API_KEY,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						userId: userData._id,
						title: formData.title || 'Mr',
						first_name: formData.firstName,
						last_name: formData.lastName,
						email: formData.email
					}).replace(/</g, '\\u003c')
				})
				const response = await data.json()

				if (response.success) {
					setSuccessState(true)
					setFormErrorState(true)
					setIsLoading(false)
					userRefetch()
				} else {
					setIsLoading(false)
					setSuccessState(false)
					setFormErrorState(true)
					return setUpdateProfileError({ isError: true, message: response.message.text || response.data.message || '' })
				}
			}

			if (selectedForm?.title == 'Update Password') {
				const { newPassword, confirmPassword, currentPassword } = formData
				const isValid = validatePassword(formData)
				setIsLoading(true)

				if (isValid && newPassword === confirmPassword) {
					const userId = authentication?.User?.user?._id

					const response = await fetch(`${reverseObfuscateConstant(RP_ENV.API_URL_V2)}${API_ENDPOINTS.update_user_passwordV2}`, {
						method: 'POST',
						headers: {
							'Authorization': 'Bearer ' + authentication.User.accessToken,
							'Content-Type': 'application/json',
							'x-api-key': RP_ENV.API_KEY
						},
						body: JSON.stringify({
							userId,
							password: newPassword,
							old_password: currentPassword
						})
					})
					const data = await response.json()
					if (data.success) {
						setSuccessState(true)
						setFormErrorState(true)
						setIsLoading(false)
						handleResetChanges()
					} else {
						setSuccessState(false)
						setFormErrorState(true)
						setIsLoading(false)
						setError('currentPassword', {
							type: 'manual',
							message: data.message
						})
					}
				}else{
					setIsLoading(false)
				}
			}

			if (selectedForm?.title == 'Preferences') {
				setIsLoading(true)
				const countrySelected = countryData?.data?.find((countryDetails: any) => countryDetails.name === formData.country )
				const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user/update-user-preferences`
				const data = await fetch(url, {
					method: 'POST',
					headers: {
						'Authorization': 'Bearer ' + authentication.User.accessToken,
						'x-api-key': RP_ENV.API_KEY,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						userId: userData?._id,
						receive_newsletter: formData?.isNewsLetter ? 1 : 0,
						receive_marketingletter: formData.isMarketing ? 1 : 0,
						name_on_notes: userDetails?.nameOnNotes ? userDetails?.nameOnNotes : `${userDetails?.firstName} ${userDetails?.lastName}`,
						anonymous_notes: userDetails?.anonymousNotes ? userDetails?.anonymousNotes : true,
						private_notes: userDetails?.privateNotes ? userDetails?.privateNotes : true,
						country: countrySelected
					}).replace(/</g, '\\u003c')
				})
				const response = await data.json()

				if (response.success) {
					setSuccessState(true)
					setFormErrorState(true)
					setIsLoading(false)
					userRefetch()
					window.scrollTo({ top: 0, left: 0 })
				} else {
					setIsLoading(false)
					setSuccessState(false)
					setFormErrorState(true)
					return setUpdateProfileError({ isError: true, message: response.message.text || response.data.message || '' })
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			<ProfileMainContainer isMobile={false}>
				<GiftCardFormBody isMobile={isMobile}>
					<form className='form' onSubmit={handleSubmit(onSubmitHandler)}>
						<Title>My Profile</Title>
						<CenterizedDropdown>
							<DropdownContainer isMobile={isMobile}>
								{isMobile ? <ProfileActionDropdown onSelect={handleSelect} /> : <ProfileSelector onSelect={handleSelect} />}
							</DropdownContainer>
							{formErrorState && <StatusModalContainer status={successState} isMobile={isMobile}>
								{successState  ? <StatusSuccess>Changes saved.</StatusSuccess> : <StatusFailed>Error: Changes not saved. Please try again.</StatusFailed>}
							</StatusModalContainer>}
						</CenterizedDropdown>
						<ProfileFormContainer isMobile={isMobile}>
							<ProfileForm>
								<Title>{selectedForm?.title}</Title>
								{selectedForm?.form}
							</ProfileForm>
							<ActionContainer>
								<ResetButton onClick={handleResetChanges}>RESET CHANGES</ResetButton>
								<CheckoutButton name='SAVE CHANGES' type='submit' width='10rem' height='2.5rem' bgColor={RPColors.buttonColor} textColor={'#fff'} />
							</ActionContainer>
						</ProfileFormContainer>
					</form>
				</GiftCardFormBody>
			</ProfileMainContainer>
			{isLoading && <LoadingOverlay />}
			{userDataLoading && <LoadingOverlay />}
			{updateProfileError.isError && <ErrorModal messageBody={updateProfileError.message} isOpen={updateProfileError.isError} onClose={() => setUpdateProfileError({ isError: false, message: '' })} bgColor='#73333F' closeTextColor='white' />}
		</>
	)
}

export default MyProfile