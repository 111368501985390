/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// i18n
import englishTranslation from './translations/en.json'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

import { slugify } from '../../../../utils/ArticlesUtil'
import { TranslationsUtil } from '../../../../utils'
import { SetActiveFilters } from '../../../../app/slices/SearchFilters'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import useArticleTracking from '../../../../helper/hooks/useArticleTracking'

function CategorizedPreviewMongo(props: { article: any }): ReactElement {
	const { article } = props
	// const { triggerTrackingHandler } = useArticleTracking()

	const translate = translationUtil.getTranslator()
	const dispatch = useDispatch()

	return (
		<article className="post post--card post--card-sm cat-1">
			<div className="post__thumb">
				<Link to={`/articles/${article.id}/${slugify(article.title)}`} >
					<div className="background-img"><CloudImageUtils imgSrc={article.banner?.url} alt={article?.banner?.url} ratio={1.5} params=''/></div>
				</Link>
				<Link onClick={()=> dispatch(SetActiveFilters({}))} to={`/search/wine?article_id=${article.id}`} >
					<span className="post__cat post__cat--bg post__cat--overlap cat-theme-bg">{article.tasting_note_count ?? 0} notes</span>
				</Link>
			</div>
			<div className="post__text text-center text-trunctate-two-lines">
				<h3 className="post__title typescale-1">
					<Link to={`/articles/${article.id}/${slugify(article.title)}`} >{article.title}</Link>
				</h3>
			</div>
			<div className="post__excerpt">
				<Link to={`/articles/${article.id}/${slugify(article.title)}`} className="post__excerpt link-normal">{article.excerpt}</Link>
			</div>
			<div className="post__footer">
				<div className="post__footer-left post__meta">
					<span className="entry-author">{translate('By')}&nbsp;
						<Link to={`/author/${article.author.id}`} className="entry-author__name">{article.author.name}</Link>
					</span>
				</div>
				{/* <div className="post__footer-right post__meta">
					<time className="time published" dateTime={article.published_date} title={article.getPublishedDate('title')}><i className="mdicon mdicon-schedule"></i>{article.getPublishedDate('display')}</time>
				</div> */}
			</div>
		</article>
	)
}

export default CategorizedPreviewMongo