import React, { ReactElement, useState, ChangeEvent } from 'react'
import { HashLink } from 'react-router-hash-link'
import {
	PageTitle,
	QAContainer,
	QuestionTitle,
	Question,
	QuestionAnswer,
	QuestionContainer,
	AnswerContainer,
	TextStyle3,
	Row,
	AnswerContainerMobile,
} from './common'
import { isMobile } from 'react-device-detect'
import { sanitizeUrl } from '@braintree/sanitize-url'

function Search(): ReactElement {

	const [selectedOption, setSelectedOption] = useState('')

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = event.target.value
		setSelectedOption(selectedValue)

		// Check the selected value and navigate to the target ID
		if (selectedValue === 'question1') {
			window.location.href = 'faq/#search-wine'
		} else if (selectedValue === 'question2') {
			window.location.href = 'faq/#search-article'
		} else if (selectedValue === 'question3') {
			window.location.href = 'faq/#advance-search'
		} else if (selectedValue === 'question4') {
			window.location.href = 'faq/#find-reviews'
		}
		// Add more conditions as needed
	}
	return (
		<div role="main">
			{isMobile ?
				<><PageTitle>Technical Issues</PageTitle>
					<QAContainer>

						<span className='jump-question'>Jump to question</span>
						<select value={selectedOption} onChange={(e) => handleChange(e)} className='jump-question-select component-design'>
							<option value=''>- Select a question -</option>
							<option value='question1'>How do I search for a wine?</option>
							<option value='question2'>How do I search for an article?</option>
							<option value='question3'>Can I use the advanced search options similar to the one in erobertparker.com?</option>
							<option value='question4'>How can I find reviews that are posted on RobertParker.com but didn&apos;t fit into a printed issue of The Wine Advocate?</option>
						</select>
						<Row>
							<AnswerContainerMobile>
								<QuestionTitle>
									How do I search for a wine?
									<div id='search-article'></div>

									<QuestionAnswer>
										You can type the name of the wine, producer&apos;s name, regions or any part thereof in the yellow search box, select &quot;search for wine&quot; on the left side drop down, and just click &quot;search&quot;. Various filters will be available to you on the left for you to refine your search.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									How do I search for an article?
									<QuestionAnswer>
										You can type the topic or any part thereof in the yellow search box, select &quot;search for article&quot; on the left side drop down, and just click &quot;search&quot;. Various filters will be available to you on the left for you to refine your search.
									</QuestionAnswer>
									<div id='advance-search'></div>
								</QuestionTitle>

								<QuestionTitle>
									Can I use the advanced search options similar to the one in erobertparker.com?
									<QuestionAnswer>
										Yes, they are available to you when you click on &quot;Advanced Search&quot; in the yellow search bar. If you have suggestions on how to improve the search functionality further, we would like to hear from you! Please email us at <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}
										>support@robertparker.com</a></TextStyle3>.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									How can I find reviews that are posted on RobertParker.com but didn&apos;t fit into a printed issue of The Wine Advocate?
									<div id='find-reviews'></div>
									<QuestionAnswer>
										All reviews and tasting notes can be accessed and found through the search. The set of notes and wines on the RobertParker.com covers the full set of tasting notes done by our reviewers.
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainerMobile>
						</Row>

					</QAContainer>
				</>
				:
				<>
					<PageTitle>Search</PageTitle>
					<QAContainer>
						<Row>
							<QuestionContainer>
								<Question>
									<HashLink to='#search-wine'>How do I search for a wine?</HashLink>
								</Question>
								<Question>
									<HashLink to='#search-article'>How do I search for an article?</HashLink>
								</Question>
								<Question>
									<HashLink to='#advance-search'>Can I use the advanced search options similar to the one in erobertparker.com?</HashLink>
								</Question>
								<Question>
									<HashLink to='#find-reviews'>How can I find reviews that are posted on RobertParker.com but didn&apos;t fit into a printed issue of The Wine Advocate?</HashLink>
								</Question>
							</QuestionContainer>
							<AnswerContainer>
								<QuestionTitle>
									How do I search for a wine?
									<div id='search-article'></div>

									<QuestionAnswer>
										You can type the name of the wine, producer&apos;s name, regions or any part thereof in the yellow search box, select &quot;search for wine&quot; on the left side drop down, and just click &quot;search&quot;. Various filters will be available to you on the left for you to refine your search.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									How do I search for an article?
									<QuestionAnswer>
										You can type the topic or any part thereof in the yellow search box, select &quot;search for article&quot; on the left side drop down, and just click &quot;search&quot;. Various filters will be available to you on the left for you to refine your search.
									</QuestionAnswer>
									<div id='advance-search'></div>
								</QuestionTitle>

								<QuestionTitle>
									Can I use the advanced search options similar to the one in erobertparker.com?
									<QuestionAnswer>
										Yes, they are available to you when you click on &quot;Advanced Search&quot; in the yellow search bar. If you have suggestions on how to improve the search functionality further, we would like to hear from you! Please email us at <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3>.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									How can I find reviews that are posted on RobertParker.com but didn&apos;t fit into a printed issue of The Wine Advocate?
									<div id='find-reviews'></div>
									<QuestionAnswer>
										All reviews and tasting notes can be accessed and found through the search. The set of notes and wines on the RobertParker.com covers the full set of tasting notes done by our reviewers.
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainer>
						</Row>

					</QAContainer>
				</>
			}
		</div>
	)
}

export default Search