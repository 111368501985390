/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'

import { GetBaseQuery } from '../../utils/'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

export const SubscriptionAPI = createApi({
	reducerPath: 'SubscriptionAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getSubscriptionDetails: builder.query<any, string>({
			query: (id) => `${API_ENDPOINTS.get_subscription_details}?subscriptionId=${id}`,
		}),
		checkEndDateSubscription: builder.query<any, string>({
			query: (userId) => `${API_ENDPOINTS.check_end_subscription}?userId=${userId}`,
		}),
		reduceAddons: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.reduce_addons,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		triggerReduceAddons: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return{
					url: `${API_ENDPOINTS.trigger_reduce_addons}?nextBillingDate=${params.nextBillingDate}&subscriptionId=${params.subscriptionId}&userId=${params.userId}&reducedQuantity=${params.reducedQuantity}`,
					method: 'GET',
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		updateAutoRenewal: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return{
					url: API_ENDPOINTS.update_auto_renewal,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		changeSubscriptionPlan: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return{
					url: API_ENDPOINTS.change_subscription_plan,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),


	}),
})

export const GiftSubsAPI = createApi({
	reducerPath: 'GiftSubsAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getPromoCodeValidation: builder.query<any, string>({
			query: (promoCode: string) => `${API_ENDPOINTS.get_promo_code_validation}?code=${promoCode}`,
		}),
		createGiftRequest: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.create_gift_subs_request,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		activateNewUserGiftSub: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.activate_new_user_gift_subs,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		activateExistingUserGiftSub: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.activate_existing_user_gift_subs,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		cancelSubscription: builder.query<any, string>({
			query: (subscriptionId: any) => {
				return {
					url: `${API_ENDPOINTS.cancel_subscription}?subscriptionId=${subscriptionId}`,
					method: 'POST',
				}
			}
		}),
	}),
})

export const SignUpAPI = createApi({
	reducerPath: 'SignUpAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getPromoCodeWithPlan: builder.query<any, { promoCode: string, planID: string }>({
			query: ({ promoCode, planID }) => `${API_ENDPOINTS.get_promo_code_with_plan}?code=${promoCode}&planId=${planID}`,
		}),
		signUpNewUser: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {			
				return {
					url: API_ENDPOINTS.sign_up_new_user,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		signUpExistingUserUpdgradeCommercial: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.sign_up_existing_user,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),

	}),
})

export const RedeemSubscriptionAPI = createApi({
	reducerPath: 'RedeemSubscriptionAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2), 'application/json'),
	endpoints: (builder) => ({
		verifyRedeemCode: builder.query<any, string>({
			query: (redeemCode) => `${API_ENDPOINTS.verify_redeem_code}?code=${redeemCode}`,
		}),
	}),
})

export const AddOnsAPI = createApi({
	reducerPath: 'AddOnsAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		buyAddOns: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {			
				return {
					url: API_ENDPOINTS.buy_add_ons,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
	}),
})

export const {
	useGetPromoCodeValidationQuery,
	useCreateGiftRequestMutation,
	useActivateNewUserGiftSubMutation,
	useActivateExistingUserGiftSubMutation,
	useCancelSubscriptionQuery
} = GiftSubsAPI


export const {
	useGetSubscriptionDetailsQuery,
	useCheckEndDateSubscriptionQuery,
	useReduceAddonsMutation,
	useTriggerReduceAddonsMutation,
	useUpdateAutoRenewalMutation,
	useChangeSubscriptionPlanMutation
} = SubscriptionAPI

export const {
	useVerifyRedeemCodeQuery
} = RedeemSubscriptionAPI

export const {
	useGetPromoCodeWithPlanQuery,
	useSignUpNewUserMutation,
	useSignUpExistingUserUpdgradeCommercialMutation
} = SignUpAPI

export const {
	useBuyAddOnsMutation
} = AddOnsAPI