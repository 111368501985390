/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEvent, MutableRefObject, ReactElement, ReactNode, useEffect, useState } from 'react'
import { GiftCardContainer, GiftCardFormBody } from '../../../gift-subscription/styles'
import { isMobile } from 'react-device-detect'
import { Seperator } from '../../../subscriptions/styles'
import Input from '../../../../../../components/form-components/input/Input'
import CheckoutButton from '../../../../../../components/form-components/buttons/CheckoutButton'
import Checkbox from '../../../../../../components/form-components/checkbox/Checkbox'
import ReCAPTCHA from 'react-google-recaptcha'
import { reverseObfuscateConstant } from '../../../../../../utils/Constant'
import { RP_ENV } from '../../../../../../configs'
import { Control, FieldValues, RegisterOptions, UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import { useGetPromoCodeValidationQuery } from '../../../../../../app/services'
import { useHistory } from 'react-router-dom'

type Props = {
    recaptchaRef: MutableRefObject<null>;
    control: Control<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    setError: UseFormSetError<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
    headerText: string;
	children: ReactNode;
	total: number;
	clearErrors: UseFormClearErrors<FieldValues>;
	aggreementText?: string;
	planType?: string;
	isLogged?: boolean
}

const aggrementCheckboxRules: RegisterOptions = {
	required: 'Please check aggrement to proceed.',
	validate: (value) => value !== false || 'Please check the aggrement'
}

const trialAggrement: RegisterOptions = {
	required: 'Please check trial aggrement to proceed.',
	validate: (value) => value !== false || 'Please check the trial aggrement'
}

function SubscriptionCard({recaptchaRef, control, setValue, setError, getValues, headerText, children, total, clearErrors, aggreementText = 'Personal', planType, isLogged}: Props): ReactElement {

	const [promoCode, setPromoCode] = useState('')
	const [promoCodeMessage, setPromoCodeMessage] = useState('')

	const history = useHistory()

	const { data } = useGetPromoCodeValidationQuery(promoCode, { skip: !promoCode })

	useEffect(() => {
		if(data){
			if(!data.success){
				setError('promoCode' ,{
					type: 'custom',
					message: data.message
				})
				setPromoCodeMessage('')
			} else {
				setPromoCodeMessage('Discount code is valid')
				clearErrors('promoCode')
			}
		}
	}, [data])

	const onVerifyCode = () => {
		const promoCode = getValues('promoCode')
		if(promoCode){
			setPromoCode(promoCode)
		}
	}

	const triggerLink = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
		e.preventDefault()
		history.push(url)
	}

	const showPromo = () => {
		if(planType === 'personal-1-year' && !isLogged)
			return false

		return true
	}

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>{headerText}</h3>
			</div>
			<div className='form'>
				{children}
				{
					showPromo() && <>
						<Seperator />
						<div className='text-field' style={{ marginTop: '1.5rem' }}>
							<Input isError={promoCodeMessage ? false : true} control={control} name='promoCode' type='text' placeholder='Promo Code(optional)' />
							<CheckoutButton name='Apply' type='button' width='8rem' height='3.9rem' bgColor='white' textColor='black' onClick={() => onVerifyCode()}/>
						</div>
						{promoCodeMessage && <p style={{ color: 'green', paddingLeft: '0.5rem' }}>{promoCodeMessage}</p>}
					</>
				}
				<Seperator />
				<div className='text-field' style={{ marginBottom: '1rem', marginTop: '1rem', fontSize: 18 }}>
					<span>Total</span>
					<span>${total}</span>
				</div>
				{
					planType === 'personal-1-year' && !isLogged && <div className='first-input'>
						<Checkbox name='isTrialAggrement' control={control} rules={trialAggrement}>
							<span>I understand that after the 1 week free trial my card will be automatically charged $11/month (billed annually) unless canceled. Cancel anytime.</span>
						</Checkbox>
					</div>
				}
				<div className='first-input'>
					<Checkbox name='isAggrement' control={control} rules={aggrementCheckboxRules}>
						<span>I have read and agreed to the terms of the 
							<a href='/privacy-notice' onClick={ e => triggerLink(e, '/privacy-notice')} > Privacy Notice</a> and the  
							<a href={aggreementText === 'Personal' 
								? '/subscription-agreement?selectedPlan=personal'
								: '/subscription-agreement?selectedPlan=commercial'}>{` ${aggreementText}`} Subscription Agreement</a>.
						</span>
					</Checkbox>
				</div>
				<div className='action-input'>
					<div className='recaptcha-style'>
						<i>Please check the box below to proceed.</i>
						<ReCAPTCHA
							sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
							onChange={(token) => setValue('recaptcha', token)}
							ref={recaptchaRef}
						/>
					</div>
					<CheckoutButton name='PAY NOW' type='submit' />
				</div>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default SubscriptionCard
