/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Link, useHistory, useParams } from 'react-router-dom'

import { CancelButton, UpgradePlanButton, UpgradePlanContainer, TotalLabel } from './styles'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore} from '../../../../../app/slices/Users'
import { IsUserAuthenticated } from '../../../../../utils'
import ErrorModal from '../../../../../components/error-modal'
import { useGetSubscriptionDetailsQuery } from '../../../../../app/services/Subscription'
import { isMobile } from 'react-device-detect'
import { dataCySelector } from '../../../../../app/services/constant'
import { PLANS } from '../../../../../configs'
import { ActionButtons, FieldErrorMessage, Separator } from '../styles'

const initUpgradePlanState = {
	addOnsQty: 0,
	plan: {
		planId: PLANS[2].planId,
		planPrice: PLANS[2].numericPrice,
	},
	userId: '',
	formErrors: {},
	error: {
		isShow: false,
		message: ''
	}
}

function UpgradeToCommercial(): ReactElement {

	const { managerID } = useParams<{ managerID: string }>()

	const history = useHistory()

	const { userData } = useAppSelector(UsersFromStore)
	const isAuthenticated = IsUserAuthenticated()
	
	const { data: subscriptionDetails, isLoading: subscriptionLoading} = useGetSubscriptionDetailsQuery(userData?.subscription?._id, {skip: !userData?.subscription?._id})
	// const [executeUseBuyAddOnsMutation, { data: buyAddOnsData, isLoading: buyAddOnsIsLoading, isError: buyAddOnsIsError }] = useBuyAddOnsMutation()

	const isManager = subscriptionDetails?.data?.manager?._id === userData?._id
	
	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
	const recaptchaRef = useRef(null)

	const [upgradePlanState, setUpgradePlanState] = useState<any>(initUpgradePlanState)
	const [totalAddOnsPrice, setTotalAddOnsPrice] = useState<number>(0.00)
	const [totalPrice, setTotalPrice] = useState<number>(0.00)
	const [subsEndDate, setSubsEndDate] = useState<any>()

	useEffect(() => {
		if(!isAuthenticated){
			history.push('/login')
		}
	},[])

	const errorDisplay = (errorMessage: string) => {
		return <>
			{
				errorMessage && <div className='error-message'>
					<img src={'img/icons/icon_error_warning.svg'} />
					<span>{errorMessage}</span>
				</div>
			}
		</>
	}
	
	const openModal = (message: string) => {
		setUpgradePlanState((state: any) => ({ ...state, error: { isShow: true, message: message }}))
	}
	
	const closeModal = () => {
		setUpgradePlanState((state: any) => ({ ...state, error: { isShow: false, message: '' }}))
	}

	const handleChangePlan = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (event.target.id === 'changePlan') {
			let planPrice = 0.00
			if(event.target.value === PLANS[0].planId){
				planPrice = PLANS[0].numericPrice
			} else if(event.target.value === PLANS[1].planId){
				planPrice = PLANS[1].numericPrice
			} else if(event.target.value === PLANS[2].planId){
				planPrice = PLANS[2].numericPrice
			}

			setUpgradePlanState((state: any) => ({ ...state, plan: {planId: event.target.value, planPrice: planPrice} }))
			setTotalPrice(planPrice)

		}
	}

	const handleAddOnsQty = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (event.target.id === 'addOnsQty') {
			const qtyValue = parseInt(event.target.value, 10)
			
			const currentPrice = qtyValue * 129
			setTotalAddOnsPrice(parseFloat(currentPrice.toFixed(2)))	
			setTotalPrice(currentPrice + upgradePlanState?.plan?.planPrice)

			setUpgradePlanState((state: any) => ({ ...state, addOnsQty: event.target.value}))

		}
	}


	useEffect(() => {
		if(subscriptionDetails){
			if(subscriptionDetails.success){
				setSubsEndDate(subscriptionDetails?.data?.end)
				setTotalPrice(upgradePlanState.plan.planPrice)
			}
		}
	},[subscriptionDetails])

	const handleSubmitUpgradePlan = () => {
		const payload = {
			userId: userData?._id,
			addOnsQty: parseInt(upgradePlanState.addOnsQty, 10),
			plan:{
				planId: upgradePlanState.plan.planId,
				planPrice: upgradePlanState.plan.planPrice
			} 
		}

		console.log(payload)
	}

	return <>
		{subscriptionLoading && <LoadingOverlay/>}
		<div className='single-entry'>
			<div className='container' >
				<div className='row'>
					<div style={{ width: '100%' }} className='mnmd-block post post--single type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5 margin-bottom-0px' >
						<UpgradePlanContainer>
							<div className='subHeader'>
								<Link to={'/my-subscription'} style={{textDecoration: 'none'}} {...dataCySelector('back-btn')}>
									<span className='backButton'>Back to Manage Subscription</span>
								</Link>
								<div className='instruction'>The new commercial subscription will activate and start on {moment(subsEndDate).format('DD MMM YYYY')}. <br />
									Your card on file will be automatically charged on that day.
								</div>
							</div>	
	
							<div className='changePlan'>
								<span className='reduce-title'>Subscription</span>
								<span>Price</span>
							</div>
							<Separator style={{ marginTop: '0.5rem', marginBottom:'2rem' }} />
							<div className='upgradePlan-content'>
								<div className='main-selectContainer'>
									<p>Select a plan to change</p>
									<div className='selectContainer'>
										<select id='changePlan' className='planSelect' value={upgradePlanState.plan.planId} onChange={handleChangePlan}>
											<option value={PLANS[2].planId}>Commercial - 1 Year</option>
										</select>
										<span className='price'><strong>USD {upgradePlanState?.plan?.planPrice}{!upgradePlanState?.plan?.planPrice.toString().includes('.') && <>.00</>}</strong></span>
									</div>
									<FieldErrorMessage>{errorDisplay(upgradePlanState.formErrors?.qty)}</FieldErrorMessage>
								</div>
							</div>
							<Separator />
							<div className='upgradePlan-content'>
								<div className='main-selectContainer'>
									<p>Group user add-on</p>
									<div className='selectContainer'>
										<div className='qtyInput'>
											<label>Qty: </label>
											<select id='addOnsQty' className='qtySelect' value={upgradePlanState.addOnsQty} onChange={handleAddOnsQty}>
												{[...Array(16).keys()].map((number) => (
													<option key={number} value={number}>{number}</option>
												))}
											</select>
										</div>
										<span className='price'><strong>USD {totalAddOnsPrice}{!totalAddOnsPrice.toString().includes('.') && <>.00</>}</strong></span>
									</div>
									<FieldErrorMessage>{errorDisplay(upgradePlanState.formErrors?.qty)}</FieldErrorMessage>
								</div>
							</div>
							<Separator />
							<TotalLabel>
								<span><strong>USD {totalPrice}{!totalPrice.toString().includes('.') && <>.00</>}</strong></span>
							</TotalLabel>
							<Separator />
							<p className='footerTitle'>You will be charged a total of <strong>USD {totalPrice}{!totalPrice.toString().includes('.') && <>.00</>}</strong> on <strong>{moment(subsEndDate).format('DD MMM YYYY')}.</strong></p>
						</UpgradePlanContainer>
						<Separator />
						<ActionButtons className={isMobile ? 'mobile' : 'desktop'}>
							<Link to={'/my-subscription'} {...dataCySelector('cancel-btn')} style={{textDecoration: 'none'}}>
								<CancelButton className={isMobile ? 'mobile back-button' : 'back-button'}><span>Cancel</span></CancelButton>
							</Link>
							<UpgradePlanButton onClick={handleSubmitUpgradePlan} {...dataCySelector('upgrade-plan-btn')}>UPGRADE PLAN</UpgradePlanButton>
						</ActionButtons>
					</div>

					{!upgradePlanState.error.isShow ? '' : 
						<ErrorModal messageBody={upgradePlanState.error.message} isOpen={upgradePlanState.error.isShow} onClose={closeModal} />
					}
				</div>
			</div>
		</div></>
}

export default UpgradeToCommercial
