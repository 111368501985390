/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState, useMemo } from 'react'
import * as _ from 'lodash'

// Services
import {
	useGetTradeDirectoryLookupCountriesV2Query,
	useGetTradeDirectoryV2Mutation,
} from '../../../../../app/services'

import {
	FilterContainer,
	Separator,
	TradeDescription,
	DefaultPicture,
	ImageHolder,
	TradeContainer,
	BusinessType,
	Country,
	Address,
	VisitSite,
	ClearButton,
	NoRecords,
} from './styles'

import Pagination from '../../../../../components/pagination'
import { isMobile } from 'react-device-detect'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { Link } from 'react-router-dom'
import { addHttpsIfNeeded } from '../constants'
import { CloudImageUtils } from '../../../../../utils/CloudImageUtils'
import { BUSINESS_TYPES } from '../../../../../configs'
import { validateURL } from '../../../../../utils/ValidateUrl'

const limit = 10 //for pagination
function TradePartnersTradeDirectory(): ReactElement {

	const [selectedCountry, setSelectedCountry] = useState('all')
	const [selectedType, setSelectedType] = useState('all')
	const [searchParams, setSearchParams] = useState(['offset=0', `limit=${limit}`])
	const [tradePartnerPageState, setTradePartnerPageState] = useState({
		tradePartners: [],
		totalRecords: 0,
		currentPage: 0,
	})

	const [countries, setCountries] = useState<any>()

	const [searchTradeDirectory, { data: searchTradeDirectoryData, isLoading: tradeDirectoryLoading, isError: tradeDirectoryError  }] = useGetTradeDirectoryV2Mutation()
	const {data: lookupCountriesData, isError} = useGetTradeDirectoryLookupCountriesV2Query('')

	useEffect(() =>{
		if(lookupCountriesData){
			if(lookupCountriesData.success){
				setCountries(lookupCountriesData.data)
			}
		}

		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	},[lookupCountriesData])

	useEffect(() =>{
		if(searchTradeDirectoryData){
			if(searchTradeDirectoryData.success){
				setTradePartnerPageState(tradePartnerPageState => ({ ...tradePartnerPageState, tradePartners: searchTradeDirectoryData?.data.data, totalRecords: searchTradeDirectoryData?.data.total }))
			}
		}

		if(tradeDirectoryError){
			console.log('Something went wrong. Please try again.')
		}
	},[searchTradeDirectoryData])

	useEffect(() => {
		searchTradeDirectory(searchParams.filter(parameter => parameter != ''))	
	}, [searchParams])

	const triggerCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedCountry(event.target.value)
		setSearchParams([...searchParams.filter(param => !param.startsWith('country') && !param.startsWith('offset')), `country=${event.target.value === 'all' ? '' : event.target.value}`, 'offset=0'])
	}

	const triggerTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedType(event.target.value)
		setSearchParams([...searchParams.filter(param => !param.startsWith('type') && !param.startsWith('offset')), `type=${event.target.value === 'all' ? '' : event.target.value}`, 'offset=0'])
	}



	const setNameSearchParam = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchParams([...searchParams.filter(param => !param.startsWith('name') && !param.startsWith('offset')), `keyword=${event.target.value}`, 'offset=0'])
	}

	const handleChangePage = (page: number) => {
		setSearchParams([...searchParams.filter(param => !param.startsWith('offset')), `offset=${page}`])
	}

	const debouncedChangeHandler = useMemo(
		() => _.debounce(setNameSearchParam, 800)
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [])

	const handleClear = () => {
		setSearchParams(['offset=0', `limit=${limit}`])
		setSelectedCountry('all')
		setSelectedType('all')

		const searchBox = document.getElementById('search-box') as HTMLInputElement | null
		if (searchBox != null) {
			searchBox.value = ''
		}
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background" style={{ marginBottom: '10px' }}>
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Trade directory</h2> : <h2 className="page-heading__title">Trade directory</h2>}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<header className="container">
					<TradeDescription>
						The RP Trade Directory is your go-to global resource for finding everything from wine shops and winery cellar doors to
						locating importers or distributors...or even finding a new restaurant for dinner tonight! Simply browse our listings by location or
						do a specific search of our listings to find the exact wine information you need.
					</TradeDescription>
					<Separator />
				</header>
				<div className="container">
					<FilterContainer className="row">
						<div className="col-md-3 col-lg-3">
							<div>
								<label htmlFor='country-selection'>Select a country</label>
								<div>
									<select id='country-selection' name='country-selection' className='component-design' value={selectedCountry} onChange={triggerCountryChange}  >
										<option value='all'>All countries</option>
										{countries ? countries.map((country: { _id: string, code: string, name: string }, key: number) => (<option key={key} value={country.name} >{country.name}</option>)) : null}
									</select>
								</div>
							</div>
						</div>
						<div className="col-md-3 col-lg-3">
							<div>
								<label htmlFor='business-selection'>Select business</label>
								<div>
									<select id='business-selection' name='business-selection' className='component-design' value={selectedType} onChange={triggerTypeChange} >
										<option value='all'>All business</option>
										{BUSINESS_TYPES && BUSINESS_TYPES.map((type: string, key: number) => {
											if (type) return <option key={key} value={type}>{type}</option>
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="col-md-3 col-lg-3" >
							<div>
								<label >&nbsp;</label>
								<div>
									<input onChange={debouncedChangeHandler} type="search" id='search-box' name='search-box' className='component-design' placeholder='Search for company name' />
								</div>
							</div>
						</div>
						<div className="col-md-3 col-lg-3">
							<div>
								<div >&nbsp;</div>
								<ClearButton onClick={handleClear}><span>CLEAR&nbsp;FILTERS</span></ClearButton>
							</div>
						</div>
					</FilterContainer>
					<>
						{tradeDirectoryLoading ? <LoadingOverlay /> : <div className="row flex-row">
							{tradePartnerPageState.tradePartners.length ? tradePartnerPageState.tradePartners.map((partner: any, index: number) => partner._id && (
								<TradeContainer className="col-md-6 col-lg-6" key={index}>
									<div>
										<ImageHolder>
											{partner.logo ? 
												<>
													<CloudImageUtils imgSrc={partner.logo?.url} alt="image" ratio={0} params='func=fit'/>
												</>: <DefaultPicture />}
										</ImageHolder>
									</div>
									<div className='listing-info'>
										<BusinessType>{partner?.type?.join(', ')}</BusinessType>
										<Link className='listing-info-name' to={`/trade-directory/directory-profile/${partner._id}`}>{partner.name}</Link>
										<Country>{partner?.address?.country?.name}</Country>
										<Address>
											<div>{partner?.address?.street && partner?.address?.street}</div>
											<div>{partner?.address?.city}{partner?.address?.state && ', ' + partner?.address?.state} {partner?.address?.zip && ' - ' + partner?.address?.zip}{partner?.address?.postalCode && ', ' + partner?.address?.postalCode}</div>
										</Address>
										{validateURL(`${addHttpsIfNeeded(partner.website_url)}`) &&
											<VisitSite><a href={validateURL(`${addHttpsIfNeeded(partner.website_url)}`) ? `${addHttpsIfNeeded(partner.website_url)}` : ''} target="_blank" rel="noreferrer" >Visit the site</a></VisitSite>
										}
									</div>
								</TradeContainer>
							)): <NoRecords>No Records found.</NoRecords>}
						</div>}
					</>

					<Pagination totalRecords={tradePartnerPageState.totalRecords} limit={limit} render={handleChangePage} />
				</div>
			</div>
		</div>
	)
}

export default TradePartnersTradeDirectory