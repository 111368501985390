/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import DOMPurify from 'dompurify'
import { Helmet } from 'react-helmet'

// Selectors
import { useAppSelector } from '../../../../app/hooks'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'

// Selectors
import { useGetArticleByIdQuery } from '../../../../app/services'
import { IsUserAuthenticated, ConvertJSONArticleToClass, TranslationsUtil } from '../../../../utils'

// Sections
import AuthorBoxMongo from '../../sections/author/AuthorBoxMongo'
import IssueArchives from '../../sections/issue-archives'
import IssueSlider from '../../sections/issue-slider'
import MoreArticlesByAuthorMongo from '../../sections/more-articles-by-author/MoreArticlesByAuthorMongo'
import ProducersFromArticle from '../../sections/producers-from-article'
import WinesFromArticle from '../../sections/wines-from-article'
import TagCloud from '../../sections/tag-cloud'
import SubscriptionInvite from '../../sections/subscription-invite'

// i18n
import englishTranslation from './translations/en.json'
import { APP_CONFIG, STATIC_MESSAGES } from '../../../../configs'

// CONSTANTS
import { WINE_JOURNAL_PUBLICATION_ID } from '../../../../configs'

import { useGetWinesFromAlgoliaQuery } from '../../../../app/services'
import { convertFacetsToArray } from '../../../../utils/FilterUtils'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import LoadingOverlay from '../../../../components/Loading-overlay'

import './style.css'
import Drawer from '../../Common/Drawer'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { addTargetBlank } from '../../../../helper/constants'
import NonExistPage from '../404-page/NonExistPage'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

function ArticlePage(): ReactElement {
	const translate = translationUtil.getTranslator()

	const [articleTags, setArticleTags] = useState<any>()
	const [isForFreeReading, setIsForFreeReading] = useState(false)
	const [openDrawer, setOpenDrawer] = useState(false)
	
	const authentication = useAppSelector(AuthFromStore)
	const { userData } = useAppSelector(UsersFromStore)
	const [articleNotFound, setArticleNotFound] = useState<boolean>()

	// const { triggerTrackingHandler } = useArticleTracking()

	const { articleID } = useParams<{ articleID: string }>()

	const history = useHistory()

	const { data: articlesData, isLoading: articleLoading, refetch: refetchArticle  } = useGetArticleByIdQuery(articleID, {skip: !articleID})

	const body =
	{
		sort: 'rating_high',
		body: {
			query: '',
			facetFilters: [],
			filters: `referring_articles:${articleID}`,
			hitsPerPage: 5,
			page: 0,
		},
		type: 'wine',
	}

	const { data: wineData } = useGetWinesFromAlgoliaQuery(body, { skip: false, refetchOnReconnect: false })

	const producerArray = convertFacetsToArray(wineData?.data.facets?.producer)

	useEffect(() => {
		if(articlesData){
			if(articlesData.success){
				setArticleTags(articlesData?.data.tags)
				setArticleNotFound(false)
			} else {
				setArticleNotFound(true)
			}
		}
	},[articlesData])

	useEffect(() => {
		if(userData){
			refetchArticle()
		}
	},[userData])

	useEffect(() => {
		if (articlesData) {
			if(articlesData.success) {
				let publicationId = null
				let isForFreeReading = false

				if (articlesData.data.publication) {
					if (articlesData.data.publication._id) {
						publicationId = articlesData.data.publication._id
					} else if (articlesData.data.publication.id){
						publicationId = articlesData.data.publication.id
					}
				}

				if(articlesData.data.is_highlighted){
					isForFreeReading = true
				}
				
				if(publicationId !== APP_CONFIG.TWA_PUBLICATION){
					isForFreeReading = true
				}
				setIsForFreeReading(isForFreeReading)
			}
		}
	}, [articlesData, authentication, translate])

	const generateArticleContent = () => {
		const cleanHtml = DOMPurify.sanitize(addTargetBlank(articlesData.data?.content || articlesData.data?.content_html ), {ADD_ATTR: ['target'],  ADD_TAGS: ['iframe']})
		if(cleanHtml !== 'undefined'){
			if(!IsUserAuthenticated()){
				if(isForFreeReading){
					return (<div className='main-article-container' dangerouslySetInnerHTML={{ __html: cleanHtml }} ></div>)
				}
				return (<p>{articlesData.data?.excerpt}</p>)
			} else {
				if(!isFreeUser(userData)){
					return (<div className='main-article-container' dangerouslySetInnerHTML={{ __html: cleanHtml }} ></div>)
				} else {
					if(isForFreeReading){
						return (<div className='main-article-container' dangerouslySetInnerHTML={{ __html: cleanHtml }} ></div>)
					}
					return (<p>{articlesData.data?.excerpt}</p>)
				}
			}
		}
	}

	const getSubscriptionInviteSection = () => {
		if(IsUserAuthenticated() && !userData){
			return <LoadingOverlay />
		}
		
		if(!IsUserAuthenticated()){
			if(!isForFreeReading){
				return (<SubscriptionInvite inviteMessage={STATIC_MESSAGES.PAYWALL_TITLE} mainMessage={STATIC_MESSAGES.ARTICLE_PAYWALL_DESC}/>)
			}
			return (<></>)
		} else {
			if(isFreeUser(userData)){
				if(!isForFreeReading){
					return (<SubscriptionInvite inviteMessage={STATIC_MESSAGES.PAYWALL_TITLE} mainMessage={STATIC_MESSAGES.ARTICLE_PAYWALL_DESC}/>)
				}
			}
		}
	}

	useEffect(() => {
		if(articleID === 'the-wine-advocate'){
			location.href = '/issues/latest'
		}
	},[articleID])

	const getWinesFromArticle = () => wineData && wineData.data.hits ? (<WinesFromArticle articleId={articleID} winesInJSON={wineData.data.hits} totalWines={wineData.data.nbHits} />) : <></>

	const getProducersFromArticle = () => producerArray ? (<ProducersFromArticle articleId={articlesData && articlesData?.data?.id ? articlesData?.data?.id : null} producersData={producerArray} totalProducers={producerArray.length} />) : <></>
	const openGraphTitle = `${articlesData?.data?.title.split(':')[0].trim()} - ${moment(articlesData?.data?.published_date).format('MMMM')} ${moment(articlesData?.data?.published_date).format('YYYY')} - Wine Advocate`
	const openGraphDescription = `${articlesData?.data?.title.split(':')[0].trim()} - ${articlesData?.data?.tasting_note_count} wines reviewed by ${articlesData?.data?.author?.name}`
	
	return (
		<>
			{!articleNotFound ?
				<>
					<div className="single-entry">
						{articleLoading && <LoadingOverlay />}
						{articlesData && (
							<>
								<Helmet>
									{/* Main meta tags */}
									<meta name="description" content={articlesData?.data?.excerpt} />

									{/* FB meta tags */}
									<meta property="og:description" content={openGraphDescription} />
									<meta property='og:title' content={openGraphTitle} />
									<meta property='og:image' content={articlesData?.data?.banner?.url} />

									{/* Twitter metatags */}
									<meta name="twitter:card" content='summary_large_image' />
									<meta name="twitter:title" content={openGraphTitle} />
									<meta name="twitter:description" content={openGraphDescription} />
									<meta name='twitter:image' content={articlesData?.data?.banner?.url} />
								</Helmet>
								<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
									<div className="container">
										<header className="single-header" style={{ display: 'grid' }}>
											{/* <Link to={articlesData.publication && articlesData.publication._id && articlesData.publication?._id === WINE_JOURNAL_PUBLICATION_ID ? '/free-publications/wine-journal' : '/'} className="entry-cat cat-theme cat-5">{articlesData.publication?.title}</Link> */}
											{/* <Link to={articlesData.issue ? `/issues/${articlesData?.issue?._id}` : '/free-publications/wine-journal'} className={`entry-cat cat-theme cat-5 ${isMobile ? 'issue-link-mobile' : 'issue-link' }`}>{articlesData.issue?.title}</Link> */}
											<div onClick={()=> history.goBack()} className='article-back-button'>BACK</div>
											<div className='article-wine-list' onClick={()=> setOpenDrawer(true)}>
												<a id="my-trigger" style={{ color: '#a08436' }}>VIEW WINE LIST</a>
											</div>
											{isMobile
												? <div className="entry-title entry-title--lg" style={{ fontSize: '25px' }}>{articlesData?.data?.title}</div>
												: <div className="entry-title entry-title--lg" style={{ fontSize: '40px', width: '100%', maxWidth: '1150px' }}>{articlesData?.data?.title}</div>
											}
											<div className="entry-meta d-flex justify-content-start flex-row">
												{articlesData?.data?.author?.profile_image ? (
													<span>
														<div className="entry-author__avatar">
															<CloudImageUtils imgSrc={articlesData?.data?.author?.profile_image} alt="image" ratio={0} params='radius=100'/>
														</div>
													</span>
												) : (<></>)}
												<div className='d-flex flex-row justify-content-between' style={{paddingTop: '10px'}}>
													<div style={{marginRight: '10px'}}>
														{articlesData?.data?.author ? <span className="entry-author entry-author--with-ava">
															{articlesData?.data?.author?.name ?
																articlesData?.data?.author?.id && articlesData?.data?.author?.id.length < 8 ?
																	<>
																		<>By&nbsp;</><span className="entry-author__name mr-4" title={`Post by ${articlesData?.data?.author?.name}`}>{articlesData?.data?.author?.name}</span>
																	</>
																	:
																	<>
																		<>By&nbsp;</>
																		<Link to={`/author/${articlesData?.data?.author?.id}`} className="entry-author__name" title={`Post by ${articlesData?.data?.author?.name}`} rel="author">{articlesData?.data?.author?.name}</Link>
																	</>
																: <></>}
														</span> : ''}
													</div>
													<div>
														{articlesData?.data?.published_date ? <time className="entry-date published" dateTime={articlesData?.data?.published_date} title={moment(articlesData?.data?.published_date).format('MMM DD, YYYY at hh:mm a').toString()}>
															<i className="mdicon mdicon-schedule"></i>{moment(articlesData?.data?.published_date).format('MMM DD, YYYY').toString()}</time> : ''}
														{articlesData?.published_date ? <time className="updated" dateTime={articlesData?.data?.published_date} title={moment(articlesData?.data?.published_date).format('MMM DD, YYYY at hh:mm a').toString()}>{moment(articlesData?.data?.published_date).format('MMM DD, YYYY').toString()}</time> : ''}
													</div>
												</div>
											</div>

										</header>
									</div>
								</div>
								<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
									<div className="container">
										<div className="row">
											<div className="mnmd-main-col" role="main">
												<article className="mnmd-block post post--single post-10 type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5" itemScope itemType="https://schema.org/Article">
													<div className="page-schema-meta">
														{articlesData && articlesData?.data?.id ? <link itemProp="mainEntityOfPage" href={`/articles/${articlesData?.data?.id}`} /> : <></>}
														<meta itemProp="headline" content={articlesData?.data?.title} />
														<meta itemProp="datePublished" content={articlesData?.data?.published_date} />
														<meta itemProp="dateModified" content={articlesData?.data?.published_date} />
														<meta itemProp="commentCount" content="0" />
														<div itemProp="image" itemScope itemType="https://schema.org/ImageObject">
															<meta itemProp="url" content={articlesData?.data?.banner?.url ?? articlesData?.data?.image?.url} />
															<meta itemProp="width" content="800" />
															<meta itemProp="height" content="400" />
														</div>
														<div itemScope itemProp="author" itemType="https://schema.org/Person">
															<meta itemProp="name" content={articlesData?.data?.author?.name} />
														</div>
														<div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
															<meta itemProp="name" content={articlesData?.data?.publication?.title} />
															<div className="hidden" itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
																<meta itemProp="url" content="img/logo-mark-color.png" />
																<meta itemProp="width" content="200" />
																<meta itemProp="height" content="70" />
															</div>
														</div>
													</div>
													<div className="single-content">
														{(articlesData?.data?.banner && articlesData?.data?.banner?.url) || (articlesData?.data?.image && articlesData?.data?.image?.url) ? (
															<div className="entry-thumb single-entry-thumb">
																<CloudImageUtils imgSrc={articlesData?.data?.banner?.url ?? articlesData?.data?.image?.url} alt="image" ratio={0} params=''/>
															</div>) : (<img src="/img/icons/default_article_banner.jpg" alt="image" />)}

														<div className="single-body entry-content typography-copy" itemProp="articleBody">
															{generateArticleContent()}
														</div>

														{articlesData?.data && getSubscriptionInviteSection()}

													</div>

												</article>

												{
													articlesData?.data?.publication && articlesData?.data?.publication._id && articlesData?.data?.publication._id !== WINE_JOURNAL_PUBLICATION_ID &&
										<>
											<AuthorBoxMongo author={articlesData?.data?.author} />
											<IssueSlider referringArticle={ConvertJSONArticleToClass(articlesData?.data)} />
											{articlesData?.data?.author && articlesData?.data?.author.name && <MoreArticlesByAuthorMongo authorDetails={articlesData?.data?.author} />}
										</>
												}

											</div>
											<div className="mnmd-sub-col mnmd-sub-col--right sidebar js-sticky-sidebar" role="complementary">
												<div className='wine-info-container'>
													{
														articlesData && articlesData?.data?.publication?._id !== WINE_JOURNAL_PUBLICATION_ID &&
										<span>
											{wineData?.data.hits.length > 0 && <>
												<div className="mnmd-widget-indexed-posts-c mnmd-widget--box mnmd-widget widget">
													<span>{getWinesFromArticle()}</span>
												</div>
											</>}
											
											{producerArray?.length > 0 && <>
												<div className="mnmd-widget-indexed-posts-c mnmd-widget--box mnmd-widget widget">
													<span>{getProducersFromArticle()}</span>
												</div>
											</>}
											
										</span>
													}
													<div className="widget widget_tag_cloud article-tags">
														{articleTags && articleTags.length > 0 ? <TagCloud tags={articleTags} /> : null}
													</div>
												</div>
												<div className="widget widget_archive article-tags">
													<IssueArchives />
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						<Drawer drawerTitle='WINE LIST' isOpen={openDrawer} onClose={() => setOpenDrawer(false)}>
							{ articlesData && articlesData?.data?.publication?._id !== WINE_JOURNAL_PUBLICATION_ID &&
					<>
						<div className="mnmd-widget-indexed-posts-c mnmd-widget--box mnmd-widget widget">
							<span>{getWinesFromArticle()}</span>
						</div>
						<div className="mnmd-widget-indexed-posts-c mnmd-widget--box mnmd-widget widget">
							<span>{getProducersFromArticle()}</span>
						</div>
					</>
							}
						</Drawer>
					</div>
				</> :
				<> 
					<NonExistPage/>
				</>}
		</>

	)
}

export default ArticlePage