/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

// Styles
import { CustomButton, GreenEmblemIndicator, GreenEmblemIndicatorMobile, ProducerName, RatingContainer, RatingContainerMobile, WineAttributes } from './styles'

// Components 
import SubscriptionInvite from '../../sections/subscription-invite'

// Sections
import WineVintages from './sections/WineVintages'

// Utils
import { ConvertJSONWineToClass, IsUserAuthenticated, TranslationsUtil } from '../../../../utils'

// Selectors
import { useAppSelector } from '../../../../app/hooks'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'
import { Users as UsersFromStore } from '../../../../app/slices/Users'

// i18n
import englishTranslation from './translations/en.json'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

import TastingNotes from '../../sections/tasting-notes'
import { isMobile } from 'react-device-detect'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import PriceListingsModal from '../../../modals/price-listings'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'
import PublishedArticles from './sections/PublishedArticles'
import { WineImporterInfo } from './sections/WineImporterInfo'
import LoadingOverlay from '../../../../components/Loading-overlay'

import { STATIC_MESSAGES } from '../../../../configs'
import SectionSeparator from '../../sections/section-separator'
import CommonModalContainer from '../../../modals/ModalContainer'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { useGetEcoDetailsByCompanyIdQuery, useGetTastingNotesFromWineV2Query, useGetWineDetailsQuery } from '../../../../app/services'
import { validateURL } from '../../../../utils/ValidateUrl'
import { addHttpsIfNeeded } from '../trade-partners/constants'

type RatingsDisplayType = {
	accessToken: string;
	wine: any;
	data: any;
	userData: any;
}

declare global {
	interface Window {
		dataLayer: any;
	}
}

const getRatingType = (wine: any) => {
	const rating = wine.rating.computed
	if (rating > 95) {
		return 'Extraordinary'
	} else if (rating > 89) {
		return 'Outstanding'
	} else if (rating > 79) {
		return 'Above Average to Excellent'
	} else if (rating > 69) {
		return 'Average'
	} else if (rating > 59) {
		return 'Below Avarage'
	} else if (rating < 58) {
		return 'Appalling'
	} else {
		return 'Appalling'
	}
}

const ratingDisplay = (rating: any) => {
	if(rating.display){
		if(rating.computed === 50){
			return '?'
		} else {
			return rating.display
		}
	} else {
		return 'N/A'
	}
}

const RatingsDisplay = ({ accessToken, wine, data, userData  }: RatingsDisplayType) => {
	return <div className='unauthenticated-notes-header'>
		{(!isFreeUser(userData) && (IsUserAuthenticated() || accessToken)) ?
			<>
				{wine.rating && wine.rating?.display && (IsUserAuthenticated() || accessToken) ?
					isMobile ?
						<RatingContainerMobile style={{ color: '#A08436' }}>
							<div style={{ backgroundColor: '#A08436' }}>&nbsp;</div>
							<span className='top'>RP</span>&nbsp;
							<span className='rate'>{ratingDisplay(data?.rating)}</span>
						</RatingContainerMobile>
						:
						<RatingContainer style={{ color: '#A08436' }}>
							<div className="hover-text-custom" style={{ backgroundColor: '#A08436' }}>
								{getRatingType(wine) === 'Above Average to Excellent' ?
									<span className="tooltip-text-custom" id="topCustom">{getRatingType(wine)}</span> : <span className="tooltip-text-custom" id="top">{getRatingType(wine)}</span>}&nbsp;
							</div>&nbsp;
							<span className='top'>RP</span>&nbsp;
							<span className='rate'>{ratingDisplay(data?.rating)}</span>
						</RatingContainer>
					:
					<RatingContainer style={{ color: '#A08436', paddingTop: '10px' }}>
						<span className='rating' style={{ paddingRight: '5px' }}><b>RP</b></span>
						<span className='rate'>{ratingDisplay(data?.rating)}</span>
					</RatingContainer>
				}
			</> : wine.rating?.display === '100' ?
				isMobile ?
					<RatingContainerMobile>
						<div style={{ backgroundColor: '#A08436' }}>&nbsp;</div>
						<span className='top'>RP</span>&nbsp;
						<span className='rate'>{ratingDisplay(data?.rating)}</span>
					</RatingContainerMobile>
					:
					<RatingContainer style={{ color: '#A08436' }}>
						<div className="hover-text-custom" style={{ backgroundColor: '#A08436' }}>{getRatingType(wine) === 'Above Average to Excellent' ? <span className="tooltip-text-custom" id="topCustom">{getRatingType(wine)}</span> : <span className="tooltip-text-custom" id="top">{getRatingType(wine)}</span>}&nbsp;</div>&nbsp;
						<span className='top'>RP</span>&nbsp;
						<span className='rate'>{ratingDisplay(data?.rating)}</span>
					</RatingContainer>
				:
				accessToken === '' && (
					<>
						<nav className="mnmd-pagination text-center" style={{ width: '100%', marginBottom: '1rem' }}><Link to='/subscriptions' className="btn btn-default">Subscribe now to discover RP rating&emsp;<img src='img/icons/lock.png' style={{ width: '10px', height: '13px' }} /></Link>
						</nav>
					</>
				)
		}
	</div>
}

function WinePage(): ReactElement {
	const translate = translationUtil.getTranslator()

	const [activeDisplay, setActiveDisplay] = useState(1)
	const [findPriceOnline, setFindPriceOnline] = useState(false)
	const [winePrice, setWinePrice] = useState<{ high: number; low: number }>({
		high: 0,
		low: 0
	})
	const [producerNotes, setProducerNotes] = useState<any>()
	const [wine, setWine] = useState<any>()
	const [wineProperties, setWineProperties] = useState<{ label: string[]; values: string[] }>({
		label: [],
		values: []
	})
	const [data, setData] = useState<any>()

	const { wineID } = useParams<{ wineID: string }>()

	const { data: companyDetails, isLoading: getEcoDetailsIsLoading } = useGetEcoDetailsByCompanyIdQuery(data?.producer?._id, {skip: !data?.producer?._id})

	const {data: wineDetailsData, isLoading: wineDetailsIsLoading, isError: wineDetailsIsError, refetch: wineDetailsRefetch } = useGetWineDetailsQuery(wineID, {skip: !wineID})
	const { data: tastingNotes , isLoading: tastingNoteisLoading, refetch: tastingNoteRefetch } = useGetTastingNotesFromWineV2Query(wine?.id, { skip: wine?.id ? false : true })
	
	const authentication = useAppSelector(AuthFromStore)
	const { userData } = useAppSelector(UsersFromStore)
	const [ hasImage, setHasImage ] = useState(false)

	useEffect(() => {
		if(userData){
			if(tastingNotes){
				if(!tastingNotes[0]?.content){
					wineDetailsRefetch()
					tastingNoteRefetch()
				}
			}
		}
	},[userData])

	useEffect(() => {
		if(wineDetailsData) {
			if(wineDetailsData.success) {
				setData(wineDetailsData.data)
				setWinePrice({
					high: wineDetailsData.data.price.high,
					low: wineDetailsData.data.price.low
				})
				setProducerNotes(wineDetailsData.data.producerNotes)
				setWine(ConvertJSONWineToClass(wineDetailsData.data))
			} else {
				console.log('Error: ', wineDetailsData.message)
			}
		}
		
		if(wineDetailsIsError){
			console.log('Something went wrong. Please try again.')
		}
	}, [wineDetailsData, wineDetailsIsError])

	useEffect(() => {
		if (wine) {
			if(tastingNotes){
				const lastTasted = new Date(tastingNotes[0]?.tasted_date).getTime()
				TagManager.dataLayer({
					dataLayer: {
						'event': 'custom_wine_pages',
						'objectID': wine.id,
						'country': wine.country?.name,
						'region': wine.region?.name,
						'subregion': wine.location?.name,
						'appellation': wine.appellation?.id,
						'producer': wine.producer?.display_name,
						'name': wine.name,
						'date_last_tasted': Math.floor(lastTasted / 1000)?.toString(),
						'last_reviewer': tastingNotes[0]?.author?.name,
						'rating_computed': wine.rating?.computed?.toString(),
						'vintage': wine?.vintage?.toString()
					}
				})

				const winePropertiesLabel: string[] = []
				const winePropertiesValues: string[] = []

				if (wine.color_class?.label) {
					winePropertiesLabel.push(translate('Color'))
					winePropertiesValues.push(wine.color_class.label.toUpperCase())
				}

				if (wine.type?.label) {
					winePropertiesLabel.push(translate('Type'))
					winePropertiesValues.push(wine.type.label.toUpperCase())
				}

				if (wine.dryness?.label) {
					winePropertiesLabel.push(translate('Sweetness'))
					winePropertiesValues.push(wine.dryness.label.toUpperCase())
				}

				setWineProperties({
					label: winePropertiesLabel,
					values: winePropertiesValues
				})
			}
		}
	}, [wine, authentication, tastingNotes])

	useEffect(() => {
		if(wine){
			if (wine.image && wine.image.url) {
				setHasImage(true)
			}
		}
	},[wine])

	const generateMobileWineImage = () => {
		if (wine.image && wine.image.url) {
			return (<CloudImageUtils imgSrc={wine.image?.url} alt="image" ratio={0} params='func=bound&h=300'/>)
		}
		return (<></>)
	}

	const generateDesktopWineImage = () => {
		if (wine.image && wine.image.url) {
			return (<CloudImageUtils imgSrc={wine.image?.url} alt="image" ratio={0} params='func=bound&h=500'/>)
		}

		return (<></>)
	}

	const getVarietyLabel = () => {
		if (wine.varieties) {
			return wine?.varieties?.length < 0 ? 'Varieties' : 'Variety'
		}
		return 'Variety'
	}


	const generateContentDisplay = () => {
		if(IsUserAuthenticated() && !userData){
			return <LoadingOverlay />
		}
		
		if (wine.rating?.display === '100' || !isFreeUser(userData) && (authentication?.User?.accessToken || IsUserAuthenticated())) {
			return (<TastingNotes wine={wine} data={tastingNotes} winePrice={winePrice} activePage={activeDisplay} setActivePage={setActiveDisplay} producerNotes={producerNotes}/>)
		} else {
			if(authentication?.User?.accessToken === '' || isFreeUser(userData)){		
				return (
					<SubscriptionInvite inviteMessage={STATIC_MESSAGES.PAYWALL_TITLE} mainMessage={STATIC_MESSAGES.WINE_PAYWALL_DESC} />
				)
			}
		}
	}

	const getWineProducerLinks = (): ReactElement | null => {
		if (data && data.producer && wine.producer) {
			return (
				<div>
					<div className={'attribute-header'} >{translate('Producer')}:</div>
					<ProducerName>
						<span style={{ color: '#a08436' }}>
							{wine.producer?.website_url && validateURL(`${wine.producer.website_url}`)
								?
								<a href={validateURL(`${wine.producer.website_url}`) ? `${addHttpsIfNeeded(wine.producer.website_url)}` : ''} target='_blank' rel="noreferrer">{data.producer.display_name}</a>
								:
								data.producer?.display_name
							}
						</span>
					</ProducerName>
					<Link to={`/search/wine?producer=${encodeURIComponent(data.producer?.display_name)}`} style={{ fontSize: '0.9em', color: '#a08436' }} >{translate('View wines from this producer')}</Link>
				</div>
			)
		}

		return null
	}

	const getVarietiesSection = (): ReactElement => {
		const wineVarieties = wine.GetVarietiesDisplay().split(',')
		return <>
			<div style={{ marginTop: '-5px' }}>
				<div style={{ marginTop: '-5px' }}><div className={'attribute-header'} >{getVarietyLabel()}:</div>
					{wine.varieties?.length > 0 ? 
						<>
							{wineVarieties?.length > 1 ? <><Link to='/search/wine?varieties=Proprietary blend'>Proprietary blend</Link></> :
								<>
									{ wineVarieties.map((variety: string, index: number) => {
										if(wineVarieties?.length -1 === index) return <Link to={`/search/wine?varieties=${variety}`} key={index}>{variety}</Link>
										else return <><Link to={`/search/wine?varieties=${variety}`} key={index}>{variety}</Link>, </>
									})}
								</> 
							}
						</> :
						<>
							<Link to='/search/wine?varieties=Proprietary blend'>Proprietary blend</Link>
						</>
					}
				</div>
			</div>
		</>
	}

	const getWinePropertiesSection = (): ReactElement => (wineProperties.label && wineProperties.values ? (
		<div style={{ marginTop: '-5px' }}>
			<div className={'attribute-header'} >{wineProperties.label.join(' / ')}:</div>
			<div style={{ marginTop: '-5px' }}>{wineProperties.values.join(' / ')}</div>
		</div>
	) : (<></>))

	const getMaturitySection = (): ReactElement => {
		return <>
			{ wine.maturity?.label && (
				<div style={{ marginTop: '-5px' }}><div className={'attribute-header'} >{translate('Maturity')}:</div>
					<div style={{ marginTop: '-5px', textTransform:'uppercase' }}>
						{wine.maturity?.label}
					</div>
				</div>)}
		</>
	}

	const getCertified = (): ReactElement => {
		return <>
			{ wine.certified && wine.certified?.length !== 0 && (
				<div style={{ marginTop: '-5px' }}>
					<div className='attribute-header certified'>
						<img src='img/icons/elements-icons-certified.png' />
						<div>{translate('Certified')}:</div>
					</div>
					<div style={{ marginTop: '-5px', textTransform:'uppercase' }}>
						{wine?.certified.map((certified: any) => certified.label ).join(' / ')}

					</div>
				</div>)}
		</>
		
	}

	const wineRegion = (): ReactElement => (
		<div>
			<div className={'attribute-header'} >{translate('Wine Region')}:</div>
			<div className="tagcloud" style={{ marginTop: '-5px' }}>
				{generateLocationTagClouds()}
			</div>
		</div>
	)

	const generateLocationTagClouds = () => (
		<>
			{wine.country && wine.country.name ? (<Link style={{ color: '#333' }} to={`/search/wine?country=${wine.country.name}`}>{wine.country.name}</Link>) : null}
			{wine.region && wine.region.name ? (<Link style={{ color: '#333' }} to={`/search/wine?region=${wine.region.name}`}>{wine.region.name}</Link>) : null}
			{wine.location && wine.location.name ? (<Link style={{ color: '#333' }} to={`/search/wine?sub_region=${wine.location.name}`}>{wine.location.name}</Link>) : null}
			{wine.locale && wine.locale.name ? (<Link style={{ color: '#333' }} to={`/search/wine?appellation=${wine.locale.name}`}>{wine.locale.name}</Link>) : null}
			{wine.site && wine.site.name ? (<Link style={{ color: '#333' }} to={`/search/wine?sub_appellation=${wine.site.name}`}>{wine.site.name}</Link>) : null}
		</>
	)

	const handleRpCellar = (wine: any) => {
		window.open(`https://cellar.robertparker.com/wine/addWineToCellar?id=${wine.id}`)
	}

	const checkPriceOnline = () => {
		setFindPriceOnline(true)
		const lastTasted = new Date(tastingNotes[0]?.tasted_date).getTime()
		
		TagManager.dataLayer({
			dataLayer: {
				event: 'check_price',
				page_origin: 'wine',
				objectID: wine?.id,
				country: wine?.country?.name,
				region: wine?.region?.name,
				subregion: wine?.location?.name,
				appellation: wine?.appellation?.id,
				subappellation: wine?.sub_appellation,
				producer: wine?.producer?.name,
				name: wine?.name,
				date_last_tasted: Math.floor(lastTasted / 1000)?.toString(),
				last_reviewer: tastingNotes[0]?.author?.name,
				rating_computed: wine?.rating?.computed?.toString(),
				vintage: wine?.vintage?.toString()
			}
		})
	}

	const modalHandler = (value: boolean) => {
		setFindPriceOnline(value)
	}	

	const wineDescription = `A wine reviewed by ${wine?.producer.name}. <i>Robert Parker Wine Advocate</i> rating - Unbiased Wine Reviews`
	return (
		<div className="single-entry">
			{wineDetailsIsLoading && <LoadingOverlay />}
			{tastingNoteisLoading && <LoadingOverlay />}
			{getEcoDetailsIsLoading && <LoadingOverlay />}
			{data && wine ? (
				<>
					<Helmet>
						{/* Main meta tags */}
						<meta name="description" content='' />

						{/* FB meta tags */}
						<meta property="og:description" content={wineDescription} />
						<meta property='og:title' content={`${wine.vintage} ${wine.producer.display_name} ${wine.name}`} />
						<meta property='og:image' content={wine.image?.url ?? 'img/logo-center.png'} />

						{/* Twitter metatags */}
						<meta name="twitter:card" content='summary_large_image' />
						<meta name="twitter:description" content={wineDescription} />
						<meta name='twitter:title' content={`${wine.vintage} ${wine.producer.display_name} ${wine.name}`} />
						<meta name='twitter:image' content={wine.image?.url ?? 'img/logo-center.png'} />
					</Helmet>
					<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
						<div className="container" style={{padding: 24}}>
							<header className="single-header">
								<h1 style={isMobile ? { fontSize: '40px', paddingTop: '10px', maxWidth: 1200 } : { fontSize: '40px', paddingTop: '20px', maxWidth: 1200 }} className="entry-title entry-title--lg">
									{wine.producer.display_name === wine.name ? wine.vintage + ' ' + wine.name : wine.vintage + ' ' + wine.producer.display_name + ' ' + wine.name}
								</h1>
							</header>
						</div>
					</div>
					<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
						<div className="container">
							<div className="row">
								<div className="mnmd-sub-col mnmd-sub-col--left sidebar js-sticky-sidebar padding-top-10px" role="complementary">
									<div className="mnmd-widget-indexed-posts-c mnmd-widget widget" style={{ maxWidth: isMobile ? '100%' : '' }}>
										<div className="widget__title">
											<div className={hasImage ? isMobile ? 'widget__title-seperator-mobile' : 'widget__title-seperator' : ''}>
												<RatingsDisplay accessToken={authentication?.User?.accessToken} wine={wine} data={data} userData={userData} />
												{isMobile ? <div className={isMobile ? 'mobile-tasting-notes-image' : ''}>{generateMobileWineImage()}</div> : <div className={!isMobile ? 'desktop-tasting-notes-image' : ''}>{generateDesktopWineImage()}</div>}
											</div>

											<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
												{wine?.producer?.eco_distinction ? (
													<>
														{isMobile ?
															<GreenEmblemIndicatorMobile>
																<div className='logo'>
																	<img src='/img/icons/green-emblem.png' />
																</div>
																<div className='label'>
																	ROBERT PARKER GREEN EMBLEM SINCE {companyDetails && companyDetails?.year_awarded}
																</div>
															</GreenEmblemIndicatorMobile>
															:
															<GreenEmblemIndicator>
																<div className='logo'>
																	<img src='/img/icons/green-emblem.png' />
																</div>
																<div className='label'>
																	ROBERT PARKER GREEN EMBLEM SINCE {companyDetails && companyDetails?.year_awarded}
																</div>
															</GreenEmblemIndicator>
														}
													</>
												) : <></>}
											</div>
										</div>
										<ol className="posts-list list-space-md list-seperated list-unstyled">
											<li>
												<article className="post cat-5">
													<div className="media">
														<div className="media-body media-middle">
															<WineAttributes>
																{getWineProducerLinks()}
																{wineRegion()}
																{getVarietiesSection()}
																{getWinePropertiesSection()}
																{getMaturitySection()}
																{getCertified()}
																{(wine.importers && wine.importers?.length > 0) &&
																	<div style={{ marginTop: '1rem' }}>
																		<div className={'attribute-header'}>{translate('Importers Information')}:</div>
																		{wine.importers.map((importer: any, index: number) => <WineImporterInfo key={index} importerInfo={importer} />)}
																	</div>}
																{!isFreeUser(userData) && (authentication?.User?.accessToken || IsUserAuthenticated()) ?
																	<div style={{ marginTop: '1rem' }}>
																		<CustomButton onClick={checkPriceOnline} isMobile={isMobile ? true : false}>Find it online</CustomButton>&nbsp;
																		<CustomButton isMobile={isMobile ? true : false} onClick={() => handleRpCellar(wine)}>Add to RP Cellar</CustomButton>
																	</div>
																	:
																	<></>
																}
																{isMobile ? <SectionSeparator position={'horizontal'} topMargin='20px' bottomMargin='40px'></SectionSeparator> : <hr style={{ marginTop: '0px', marginBottom: '20px' }} />}
																<WineVintages wineId={wine.id} title={translate('Vintages')} wineName={wine.name} onVintageSelected={() => setActiveDisplay(1)} />

															</WineAttributes>
														</div>
													</div>
												</article>
											</li>
										</ol>
									</div>
								</div>

								<div className="mnmd-main-col has-left-sidebar" role="main">
									<article className="mnmd-block post post--single post-10 type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5" itemScope itemType="https://schema.org/Article">
										<div className="page-schema-meta">
											<link itemProp="mainEntityOfPage" />
											<meta itemProp="headline" content="Nintendo is reportedly bringing Zelda to your phone this year" />
											<meta itemProp="datePublished" content="2017-02-10" />
											<meta itemProp="dateModified" content="2017-02-10" />
											<meta itemProp="commentCount" content="24" />
											<div itemProp="image" itemScope itemType="https://schema.org/ImageObject">
												<meta itemProp="url" content="#image-url" />
												<meta itemProp="width" content="1000" />
												<meta itemProp="height" content="563" />
											</div>
											<div itemScope itemProp="author" itemType="https://schema.org/Person">
												<meta itemProp="name" content="Ryan Reynold" />
											</div>
											<div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
												<meta itemProp="name" content="The Next Mag" />
												<div className="hidden" itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
													<meta itemProp="url" content="#logo-url" />
													<meta itemProp="width" content="200" />
													<meta itemProp="height" content="70" />
												</div>
											</div>
										</div>
										{generateContentDisplay()}
										<div style={{ marginBottom: '3rem' }} />
										<PublishedArticles wineId={wine.id} />
									</article>
									{wine && findPriceOnline ? 
										<CommonModalContainer isOpen={findPriceOnline} onClose={()=> setFindPriceOnline(false)}>
											<PriceListingsModal wine={data} isModalOpen={modalHandler}/>					
										</CommonModalContainer> : <></>}
								</div>
							</div>
						</div>
					</div>
				</>
			) : null
			}
		</div >
	)
}

export default WinePage
