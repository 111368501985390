import React, { ReactElement } from 'react'
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form'
import { DropdownContainer, ErrorMessage } from '../styles'

interface DropdownProps {
	name: string;
	placeholder: string;
	options: (string | number)[];
	rules?: RegisterOptions;
	control: Control<FieldValues>;
	isErrorField?: boolean;
	disabled?: boolean;
}

const Dropdown = ({ options, name, placeholder, rules, control, isErrorField = true, disabled = false }:DropdownProps): ReactElement => {
	
	return (
		<Controller 
			name={name} 
			rules={rules} 
			control={control}
			render={({ field, fieldState: { error } }) => (
				<DropdownContainer isError={error ? true : false}>
					<select
						disabled={disabled}
						id={name} 
						placeholder={placeholder} 
						{...field}
						value={field.value || ''}
					>
						<option value="" disabled>{placeholder}</option>
						{options.map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))}
					</select>
					{error ? <ErrorMessage>{error?.message as string}</ErrorMessage> : isErrorField ? <p style={{ paddingTop: 7  }}></p> : null}
				</DropdownContainer>
			)}
		>

		</Controller>
		
	)
}

export default Dropdown
