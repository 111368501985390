/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, useHistory, useParams } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { PLANSv2 } from '../../../../configs'
import { useBasePath } from '../../Common'

import { CarouselWrapper, CarouselWrapperForThree, CarouselWrapperForTwo, Indicator, IndicatorWrapper, PlanTypesCard, PlanTypesCardContent, PlanTypesContainer, SubscribeButton } from './styles'

interface Plan {
    plan: string;
    price: string;
    due: string;
    billed: string;
    features: string[];
    title: string;
    button_text: string;
    numericPrice: number;
    isShow: boolean;
    isRecommended: boolean;
	planId: string;
	planType: string | number
}

interface Props {
	isloggedIn?: boolean;
}

function PlanTypes({ isloggedIn }: Props): ReactElement {

	const { type } = useParams<{ type: string }>()
	const [plans, setPlans] = useState<any[]>([])
	const history = useHistory()
	const pathname = useBasePath()

	useEffect(() => {
		if (type) {
			let plan =  PLANSv2.COMMERCIAL_PLANS

			if(type === 'personal-offers')
				plan = PLANSv2.PERSONAL_PLANS

			if(isMobile)
				plan = plan.sort((a, b) => {
					if (a.isRecommended && !b.isRecommended) return -1
					if (!a.isRecommended && b.isRecommended) return 1
					return 0
				})
			
			setPlans(plan)
		}
	}, [type])

	const handleSubscribeToPlan = (plan: Plan) => () => {
		triggerSubscribe(plan.plan)
		if (plan) {
			history.push({
				pathname: `/sign-up/${plan.planId}`,
			})
		}
	}

	const triggerSubscribe = (plan: string) => {
		let event = {
			event: 'view_item',
			ecommerce: {
				currency: 'USD',
				value: 37,
				items: [
					{
						item_name: 'Quaterly personal subscription',
						index: 0,            
						item_category: 'Personal',  
						item_category2: 'Quaterly', 
						price: 37 
					}
				]
			}
		}

		if(plan === 'personal-1-year') {
			event = {
				event: 'view_item',
				ecommerce: {
					currency: 'USD',
					value: 129,
					items: [
						{
							item_name: 'Annually personal subscription',
							index: 1,            
							item_category: 'Personal',  
							item_category2: 'Annually', 
							price: 129
						}
					]
				}
			}
		}

		if(plan === 'personal-monthly') {
			event = {
				event: 'view_item',
				ecommerce: {
					currency: 'USD',
					value: 15,
					items: [
						{
							item_name: 'Monthly personal subscription',
							index: 1,            
							item_category: 'Personal',  
							item_category2: 'Monthly', 
							price: 15
						}
					]
				}
			}
		}

		if(plan === 'commercial-1-year') {
			event = {
				event: 'view_item',
				ecommerce: {
					currency: 'USD',
					value: 249,
					items: [
						{
							item_name: 'Annually commercial subscription',
							index: 2,            
							item_category: 'Commercial',  
							item_category2: 'Annually', 
							price: 249
						}
					]
				}
			}
		}

		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null
			}
		})

		TagManager.dataLayer({
			dataLayer: event
		})
		
		TagManager.dataLayer({
			dataLayer: {
				'event': 'subscribe_btn',
				'page_origin': pathname
			}
		})
	}

	const planCard = () => {
		return plans.map((data: Plan) => (
			<PlanTypesCard key={data.plan} isCenterCard={data.isRecommended} isMobile={isMobile}>
				<PlanTypesCardContent>
					{data.isRecommended && (
						<div className='recommended-badge'>
							<p>Recommended</p>
						</div>
					)}
					<div className='plan-details'>
						<div className='plan-info'>
							<div className='plan-title'>
								<p>{data.title}</p>
							</div>
							{data.planId === 'personal-1-year' && !isloggedIn &&
								<div className='plan-sub-title'>
									<p>After 1 Free Week</p>
								</div>
							}
							<div className='plan-amount'>
								<p className='amount-price'>{data.price} <span className='amount-indicator'>/ {data.due}</span></p>
							</div>
							<div className='plan-billing'>
								<p>{data.billed}</p>
							</div>
						</div>

						<div className='features-info'>
							{data.features.map((feature, index) => (
								<div key={index}>
									<p>{feature}</p>
									{index < data.features.length - 1 && <hr />}
								</div>
							))}
						</div>

						<div className='subscribe-container'>
							<div className='subscribe-button'>
								<SubscribeButton onClick={handleSubscribeToPlan(data)}>
									{data.planId === 'personal-1-year' && !isloggedIn ? 'START FREE TRIAL' : data.button_text}
								</SubscribeButton>
							</div>
					
							{data.isRecommended && type === 'personal-offers' && (
								<div className='gift-subs'>
									<Link to='/gift-subscription'>
										<p>WANT TO GIFT THIS TO A FRIEND?</p>
									</Link>
								</div>
							)}
						</div>
					</div>
			
				</PlanTypesCardContent>
			</PlanTypesCard>
		))
	}

	const DesktopLayout = () => {
		return (
			<PlanTypesContainer>
				{plans.map((data: Plan) => (
					<PlanTypesCard key={data.plan} isCenterCard={data.isRecommended}>
						<PlanTypesCardContent>
							{data.isRecommended && (
								<div className='recommended-badge'>
									<p>Recommended</p>
								</div>
							)}
							<div className='plan-details'>
								<div className='plan-info'>
									<div className='plan-title'>
										<p>{data.title}</p>
									</div>
									{data.planId === 'personal-1-year' && !isloggedIn &&
										<div className='plan-sub-title'>
											<p>After 1 Free Week</p>
										</div>
									}
									<div className='plan-amount'>
										<p className='amount-price'>{data.price} <span className='amount-indicator'>/ {data.due}</span></p>
									</div>
									<div className='plan-billing'>
										<p>{data.billed}</p>
									</div>
								</div>
	
								<div className='features-info'>
									{data.features.length ? data.features.map((feature, index) => (
										<div key={index}>
											<p>{feature}</p>
											{index < data.features.length - 1 && <hr />}
										</div>
									)) : null}
								</div>
	
								<div className='subscribe-container'>
									<div className='subscribe-button'>
										<SubscribeButton onClick={handleSubscribeToPlan(data)}>
											{data.planId === 'personal-1-year' && !isloggedIn ? 'START FREE TRIAL' : data.button_text}
										</SubscribeButton>
									</div>
									
									{data.isRecommended && type === 'personal-offers' && (
										<div className='gift-subs'>
											<Link to='/gift-subscription'>
												<p>WANT TO GIFT THIS TO A FRIEND?</p>
											</Link>
										</div>
									)}
								</div>
								
	
							</div>
							
						</PlanTypesCardContent>
					</PlanTypesCard>
				))}
			</PlanTypesContainer>
		)
	}

	const MobileLayout = () => {

		const [currentSlide, setCurrentSlide] = useState(0)
		let touchStartX = 0

		const minSwipeDistance = 50

		const handleTouchStart = (e: React.TouchEvent) => {
			touchStartX = e.touches[0].clientX
		}

		const handleTouchEnd = (e: React.TouchEvent) => {
			const touchEndX = e.changedTouches[0].clientX
			const swipeDistance = touchStartX - touchEndX

			if (Math.abs(swipeDistance) > minSwipeDistance) {
				const direction = swipeDistance > 0 ? 'left' : 'right'
				handleSwipe(direction)
			}
		}

		const handleSwipe = (direction: 'left' | 'right') => {
			if (direction === 'left' && currentSlide < 2) {
				setCurrentSlide(currentSlide + 1)
			} else if (direction === 'right' && currentSlide > 0) {
				setCurrentSlide(currentSlide - 1)
			}
		}

		const clickIndicatorHandler = (slideNum: number) => {
			setCurrentSlide(slideNum)
		}
		return (
			<span>
				<PlanTypesContainer>
					{plans.length === 1 ? 
						<CarouselWrapper>{planCard()}</CarouselWrapper> : plans.length === 2 ? 
							<CarouselWrapperForTwo currentSlide={currentSlide} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
								{planCard()}
							</CarouselWrapperForTwo> : 
							<CarouselWrapperForThree currentSlide={currentSlide} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>{planCard()}</CarouselWrapperForThree>}
				</PlanTypesContainer>
				
				<IndicatorWrapper>
					{ plans.length > 1 ? plans.map((_plan,index) => (
						<Indicator key={index} isActive={currentSlide === index} onClick={() => clickIndicatorHandler(index)}/>
					)): null}
				</IndicatorWrapper>
			</span>
		)
	}

	return (
		isMobile ? <MobileLayout/> : <DesktopLayout/>
	)
}

export default PlanTypes