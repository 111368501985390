/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import CategorizedPreview from '../../../sections/the-wine-advocate/CategorizedPreview'
import { useGetPublishedWineArticlesQuery } from '../../../../../app/services'
import { ConvertJSONArticlesCollectionToClass } from '../../../../../utils'

type Props = {
	wineId: string
}

const PublishedArticles = ({ wineId }: Props): ReactElement => {

	const { data, isLoading, isError } = useGetPublishedWineArticlesQuery(wineId)
	const [articles, setArticles] = useState<any[]>([])

	useEffect(() => {
		if (data) {
			if(data.success){
				const formatData = data.data.filter((article: any) => article !== null).slice(0, 3).map((article: any) => ({
					...article,
					image: {
						url: article.banner?.url || ''
					}
				}))
	
				setArticles(ConvertJSONArticlesCollectionToClass(formatData))
			} else {
				console.log('Error: ', data.message)
			}
		}
		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [data, wineId, isError])

	return <div>
		{!isLoading && <>
			<h5 style={{ fontWeight: 600, marginBottom: '1.5rem' }}>PUBLISHED IN</h5>
			<div className='mnmd-block mnmd-carousel'>
				{articles.length > 0 ? <div className='row flex-row' style={{ paddingLeft: '10px' }}>
					{articles.map((article: any, index: number) => <div className='col-12 col-md-6 col-lg-4' style={{ paddingLeft: '5px', paddingRight: '5px'}} key={index} > <CategorizedPreview article={article} /></div>)}
				</div> : <p>No article found.</p>}
			</div>
		</>}
	</div>
}

export default PublishedArticles
