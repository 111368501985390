/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import moment from 'moment'
import { Link, useHistory, useParams } from 'react-router-dom'

import { AddOnsBackButton, AddOnsButton, AddOnsContainer, CreditCardContainer, PaymentMethodContainer, ValidatedField } from './styles'
import { RP_ENV } from '../../../../../configs'
import { useGetCustomerPaymentMethodQuery } from '../../../../../app/services'
import { scrollToErrorForm } from '../../redemption-codes/constants'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore} from '../../../../../app/slices/Users'
import { IsUserAuthenticated } from '../../../../../utils'
import SectionSeparator from '../../../sections/section-separator'
import ErrorModal from '../../../../../components/error-modal'
import { useBuyAddOnsMutation, useGetSubscriptionDetailsQuery } from '../../../../../app/services/Subscription'
import { isMobile } from 'react-device-detect'
import { ActionButtons, FieldErrorMessage, Separator } from '../styles'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { dataCySelector } from '../../../../../app/services/constant'
import { reverseObfuscateConstant } from '../../../../../utils/Constant'

const initAddOnsState = {
	managerId: '',
	qty: 1,
	credit_card: {
		cardName: '',
		ccNumber: '',
		expiryDate:{
			year: new Date().getFullYear(),
			month: '01',
		},
		cvv: '',
		postal_code: ''
	},
	formErrors: {},
	send_date: new Date(),
	error: {
		isShow: false,
		message: ''
	}
}

export const getErrors = (formErrors: any) => {
	let error = ''
	const {credit_card, ...oldErrors} = formErrors
	for (const i in oldErrors) {
		error += oldErrors[i]
	}

	for (const i in credit_card) {
		error += credit_card[i]
	}

	return error
}

type LoadingStateType = {
    isVerifyCode: boolean;
    isBuyAddOnsCreated: boolean;
}

const AddOnsFullPrice = 129.00

function BuyAddOns(): ReactElement {

	const { managerID } = useParams<{ managerID: string }>()

	// const dispatch = useDispatch()
	const history = useHistory()

	const { userData } = useAppSelector(UsersFromStore)
	const isAuthenticated = IsUserAuthenticated()
	const customerId = userData?._id
	
	const { data: subscriptionDetails, isLoading: subscriptionLoading} = useGetSubscriptionDetailsQuery(userData?.subscription?._id, {skip: !userData?.subscription?._id})
	const { data: paymentData, isLoading: paymentListLoading, isError: paymentListIsError } = useGetCustomerPaymentMethodQuery(customerId, { skip: customerId ? false : true })
	const [executeUseBuyAddOnsMutation, { data: buyAddOnsData, isLoading: buyAddOnsIsLoading, isError: buyAddOnsIsError }] = useBuyAddOnsMutation()

	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
	const recaptchaRef = useRef(null)

	const [addOnsState, setAddOnsState] = useState<any>(initAddOnsState)
	const [pricePerHead, setPricePerHead] = useState<number | any>(AddOnsFullPrice)
	const [totalPrice, setTotalPrice] = useState<number | any>(AddOnsFullPrice)
	const [subsEndDate, setSubsEndDate] = useState<any>()

	const [loadingState, setLoadingState] = useState({
		isVerifyCode: false,
		isBuyAddOnsCreated: false,
	})

	const [cardDetails, setCardDetails] = useState({
		isNew: true,
		selectedCard:{
			token:'',
			expirationDate: `01/${new Date().getFullYear()}`
		}
	})

	useEffect(() => {
		if(!isAuthenticated){
			history.push('/login')
		}
	},[])

	useEffect(() => {
		if(userData && subscriptionDetails){
			if(subscriptionDetails.success){
				if(!userData?.orbit_roles?.includes('project:customer-commercial-manager') || subscriptionDetails?.data?.manager?.invite_manager_id && subscriptionDetails?.data?.manager?.invite_manager_id !== managerID){
					return history.push('/my-company')
				}

				const subsEndDateDays = moment(subscriptionDetails?.data?.end, 'YYYY-MM-DD')
				const dateToday = moment()

				const remainingDays = subsEndDateDays.diff(dateToday, 'days') + 1
				const amountPerDay = 129/365

				setPricePerHead((amountPerDay * remainingDays).toFixed(2))
				setTotalPrice((amountPerDay * remainingDays).toFixed(2))
				setSubsEndDate(moment(subscriptionDetails?.data?.end).format('MMM DD, YYYY'))
			}
		}
	},[userData, subscriptionDetails])

	
	useEffect(() => {
		if(paymentData){
			if(paymentData.success) {
				const defaultCard = paymentData.data.find((card: any) => card.default)
				if(defaultCard) {
					setCardDetails({
						isNew: false,
						selectedCard: defaultCard
					})
				}
			}
		}
		
		if(paymentListIsError){
			setAddOnsState((state: any) => ({ ...state, error: { isShow: true, message: 'Something went wrong. Please try again.' } }))
		}
	}, [paymentData, paymentListIsError])
	



	const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		const targetId = event.target.id
		let errors = { ...addOnsState.formErrors }
		if (targetId === 'cardName') {
			errors = { ...errors, credit_card : {...errors.credit_card, [targetId]: !addOnsState.credit_card[targetId]?.trim() ? 'Cardholder name is required' : '' } }
		} else if (targetId === 'ccNumber') {
			errors = { ...errors, credit_card : {...errors.credit_card, [targetId]: !addOnsState.credit_card[targetId]?.trim() ? 'Card number is required' : '' } }
		} else if (targetId === 'cvv') {
			errors = { ...errors, credit_card : {...errors.credit_card, [targetId]: !addOnsState.credit_card[targetId]?.trim() ? 'Card cvv number is required' : '' } }
		} else if (targetId === 'postal_code') {
			errors = { ...errors, credit_card : {...errors.credit_card, [targetId]: !addOnsState.credit_card[targetId]?.trim() ? 'Postal code is required' : '' } }
		} else if (targetId === 'qty') {
			errors = { ...errors, [targetId]: addOnsState[targetId] > 0 ? '' : 'Quantity is required' }
		}
		setAddOnsState((state: any) => ({ ...state, formErrors: errors }))
	}


	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const creditCardList = ['cardName', 'ccNumber', 'cvv', 'postal_code']
		if(creditCardList.includes(event.target.id)){
			return setAddOnsState((state: any) => ({ ...state, credit_card: {
				...state.credit_card,
				[event.target.id]: event.target.value
			}}))
		}
	}

	const handleChangeQty = (event: React.ChangeEvent<HTMLSelectElement>) => {
		
		if (event.target.id === 'qty') {
			const qtyValue = parseInt(event.target.value, 10)
			
			if (qtyValue > 0) {
				const currentPrice = qtyValue * pricePerHead
				setTotalPrice(parseFloat(currentPrice.toFixed(2)))
				setAddOnsState((state: any) => ({ ...state, [event.target.id]: qtyValue }))
			}
		}
	}

	const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {id, value} = event.target
		setAddOnsState((state: any) => ({
			...state,
			credit_card: {
				...state.credit_card,
				expiryDate:{
					...state.credit_card.expiryDate,
					[id]: value
				}
			}
		}))
	}

	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAddOnsState((state: any) => ({ ...state, [event.target.id]: event.target.checked }))
	}

	const recaptchaHandler = (token: string | null) => {
		let errors = { ...addOnsState.formErrors }
		if(token){
			errors = { ...errors, ['termsAgreement']: '' }
			setAddOnsState((state: any) => ({ ...state, formErrors: errors }))
		}
		setRecaptchaToken(token)
	}

	const verifyErrors = () => {
		let errors = { ...addOnsState.formErrors }
		errors = { ...errors, ['termsAgreement']: addOnsState['termsAgreement'] ? '' : 'Make sure that you agree to the terms before you can proceed' }
		errors = { ...errors, ['qty']: addOnsState['qty'] ? '' : 'Quantity is required' }
		
		if(cardDetails.isNew){
			errors = { ...errors, credit_card : {...errors.credit_card, ['cardName']: !addOnsState.credit_card['cardName']?.trim() ? 'Cardholder name is required' : '' } }
			errors = { ...errors, credit_card : {...errors.credit_card, ['ccNumber']: !addOnsState.credit_card['ccNumber']?.trim() ? 'Card number is required' : '' } }
			errors = { ...errors, credit_card : {...errors.credit_card, ['cvv']: !addOnsState.credit_card['cvv']?.trim() ? 'Card cvv number is required' : '' } }
			errors = { ...errors, credit_card : {...errors.credit_card, ['postal_code']: !addOnsState.credit_card['postal_code']?.trim() ? 'Postal code is required' : '' } }
		}
		setAddOnsState((state: any) => ({ ...state, formErrors: errors }))

		return getErrors(errors)
	}

	const errorDisplay = (errorMessage: string) => {
		return <>
			{
				errorMessage && <div className='error-message'>
					<img src={'img/icons/icon_error_warning.svg'} />
					<span>{errorMessage}</span>
				</div>
			}
		</>
	}

	const dateConfirmation = (specifiedDate: string) => {
		const todate = moment(new Date())
		return todate.isBefore(moment(specifiedDate, 'MM/YYYY')) || specifiedDate ===  todate.format('MM/YYYY')
	}

	const cardSelectionHandler = (type: string)	=> {
		setAddOnsState((state: any) => ({ ...state, formErrors: {} }))
		setCardDetails((prevState:any) => ({
			...prevState,
			isNew: type === 'isNewCard' ? true : false
		}))

		
	}

	const openModal = (message: string) => {
		setAddOnsState((state: any) => ({ ...state, error: { isShow: true, message: message }, isBuyAddOnsCreated: false }))
	}
	
	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
			setRecaptchaToken(null)
		}
	}

	const closeModal = () => {
		setAddOnsState((state: any) => ({ ...state, error: { isShow: false, message: '' }}))
	}

	const handleBuyAddOns = async () => {
		try {
			if(!verifyErrors()){
				if(!recaptchaToken) {
					let errors = { ...addOnsState.formErrors }
					errors = { ...errors, ['termsAgreement']: 'Please verify the recaptcha' }
					setAddOnsState((state: any) => ({ ...state, formErrors: errors }))
					return
				}

				setLoadingState((prevState:LoadingStateType) => ({
					...prevState,
					isBuyAddOnsCreated: true
				}))

				if(cardDetails.isNew){
					if(!dateConfirmation(`${addOnsState.credit_card.expiryDate.month}/${addOnsState.credit_card.expiryDate.year}`)){
						setAddOnsState((prevState: any) => ({...prevState, credit_card: {...prevState.credit_card, cvv: '', expiryDate: {...prevState.credit_card.expiryDate, month: '01', year: new Date().getFullYear()}}}))
						openModal('You cannot proceed with the payment because the credit card has expired.')
						return
					}
				} else {
					if(!dateConfirmation(cardDetails.selectedCard?.expirationDate)){
						openModal('You cannot proceed with the payment because the credit card has expired.')
						return
					}
				}

				await executeBuyAddOns()
			} else {
				scrollToErrorForm()
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoadingState((prevState:LoadingStateType) => ({
				...prevState,
				isBuyAddOnsCreated: false
			}))
		}
	}

	const executeBuyAddOns = async () => {
		try {
			let payload:any
			if(cardDetails.isNew){
				payload = {
					managerID: managerID,
					qty: addOnsState.qty,
					card: {
						cvv:addOnsState.credit_card.cvv,
						name: addOnsState.credit_card.cardName,
						number: addOnsState.credit_card.ccNumber,
						expirationDate: `${addOnsState.credit_card.expiryDate.month}/${addOnsState.credit_card.expiryDate.year}`,
						postalCode: addOnsState.credit_card.postal_code
					},
					recaptcha_token: recaptchaToken,
					
				}	
			} else {
				payload = {
					managerID: managerID,
					qty: addOnsState.qty,
					card: {
						expirationDate: cardDetails.selectedCard.expirationDate,
						paymentMethodToken: cardDetails.selectedCard.token
					},
					recaptcha_token: recaptchaToken
				}

			}

			//ExecuteBuyAddOns
			const csrf_token = await getCSURFToken()
			await executeUseBuyAddOnsMutation({params: payload, token: csrf_token})

		} catch (error) {
			console.log(error)
		}
	}


	useEffect(() => {
		if(buyAddOnsData){
			if(buyAddOnsData.success){
				window.location.href = '/my-company'
			} else {
				handleRecaptchaReset()
				setAddOnsState((prevState: any) => ({
					...prevState,
					credit_card: {
						...prevState.credit_card,
						cvv: '',
						expiryDate: {
							...prevState.credit_card.expiryDate,
							month: '01',
							year: new Date().getFullYear(),
						},
					},
					error: {
						isShow: true,
						message: JSON.stringify(buyAddOnsData.message).replace(/</g, '\\u003c'),
					},
				}))
			}
		}

		if(buyAddOnsIsError){
			handleRecaptchaReset()
			console.log('Something went wrong. Please try again')
		}
	},[buyAddOnsData, buyAddOnsIsError])
	

	const savedCreditCard = () => {
		return (
			<PaymentMethodContainer>
				<div className='payment-method'>
					<div className='credit-card-title detail-right'>
						<div>
							<input type='radio'
								onChange={() => cardSelectionHandler('isSaveCard')}
								checked={!cardDetails.isNew}
							/> &nbsp;
							<span>Saved credit card</span>
						</div>
					</div>
					<SectionSeparator position={'horizontal'} ></SectionSeparator>
					<div className='credit-card-title'>

						{paymentListLoading ? 'Loading please wait...'
							:
							paymentData?.success && paymentData?.data?.map((data: any, key: number) => {
								return (
									<div key={key} style={{ borderBottom: '0.5px solid #ece6e6', marginBottom: '10px' }}>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<div style={{ paddingLeft: '10px' }}>
												<div className='col-sm-12 col-md-12 col-lg-12'>
													<div>
														<div>
															<input type='radio' onChange={() => {
																setCardDetails((prevState:any) => ({
																	...prevState,
																	selectedCard: data
																}))
															}}
															checked={!cardDetails.isNew && cardDetails?.selectedCard?.token === data.token}
															style={{ marginRight: '5px' }}
															/>
													
															{data.cardholderName}</div>
														<div className='card-details' style={{ paddingLeft: '15px' }}><img src={`${data.imageUrl}`} style={{ height: '22px' }} />&nbsp; {data.cardType} ending {data.last4}</div>
														<div className='card-details' style={{ paddingLeft: '15px', marginBottom: '8px' }}><b>Expires </b>{data.expirationDate}</div>
													</div>
												</div>

											</div>
											<div >
												{data.default ? <div className='default' style={{ textAlign: 'right' }}>Default</div> : <></>}
											</div>
										</div>
									</div>
								)
							})}
					</div>
				</div>

				<div className='payment-method'>
					<div className='credit-card-title padding-bottom-20px'>
						<div>
							<input type='radio' id='new' onChange={() => cardSelectionHandler('isNewCard')} checked={cardDetails.isNew}
							/> &nbsp;
							<label htmlFor='new'>New credit card</label>
						</div>
					</div>
				</div>
			</PaymentMethodContainer>
		)
	}


	const newCreditCard = () => {
		function generateMonthOptions(year: number) {
			const options = []
			for (let i = 1; i <= 12; i++) {
				const formattedMonth = i.toString().padStart(2, '0')
				options.push(<option key={formattedMonth} value={formattedMonth}>{formattedMonth}</option>)
			}
			return options
		}
		
		function generateYearOptions() {
			const currentYear = new Date().getFullYear()
			const endYear = currentYear + 10
		
			const options = []
			for (let year = currentYear; year <= endYear; year++) {
				options.push(<option key={year} value={year}>{year}</option>)
			}
			return options
		}
		
		const monthOptions = generateMonthOptions(addOnsState.credit_card.expiryDate.year)
		const yearOptions = generateYearOptions()

		return (<CreditCardContainer>
			{!isAuthenticated ? <p className='form-title'>Payment Method</p> : <div className='payment-method'>
				<div className='credit-card-title padding-bottom-20px'>
					<div>
						<input type='radio' id='save' onChange={() => cardSelectionHandler('isSaveCard')} checked={!cardDetails.isNew} /> &nbsp;
						<label htmlFor='save'>Saved credit card</label>
					</div>
				</div>

				
			</div>}
		
			<div className='gift-credit-card'>
				{!isAuthenticated ? <div className='credit-card-check'>
					<input checked id='credit-card' type='radio' />
					<label htmlFor="credit-card">Credit card</label>
				</div>: 
					<div className='credit-card-title'>
						<div>
							<input type='radio' id='save' onChange={() => cardSelectionHandler('isNewCard')} checked={cardDetails.isNew} /> &nbsp;
							<label htmlFor='save'>Pay with Card</label>
						</div>
					</div>}
				<div className='credit-card-content'>
					<div id='cardholder-name-block'>
						<label htmlFor='cardName'>Cardholder name</label>
						<ValidatedField type='text' id='cardName' value={addOnsState.credit_card.cardName} onChange={handleChange} onBlur={handleBlur} className={addOnsState.formErrors?.credit_card?.cardName ? 'error' : 'component-design'} required {...dataCySelector('cardholder-name-input')}/>
						<FieldErrorMessage>{errorDisplay(addOnsState.formErrors?.credit_card?.cardName)}</FieldErrorMessage>
					</div>
					<div id='card-number-block'>
						<label htmlFor='ccNumber'>Card number</label>
						<ValidatedField type='text' id='ccNumber' value={addOnsState.credit_card.ccNumber} onChange={handleChange} onBlur={handleBlur} className={addOnsState.formErrors?.credit_card?.ccNumber ? 'error' : 'component-design'} required {...dataCySelector('card-number-input')}/>
						<FieldErrorMessage>{errorDisplay(addOnsState.formErrors?.credit_card?.ccNumber)}</FieldErrorMessage>
					</div>
					<div className='expiry-date-container'>
						<label>Expiry date</label><br />
						<div className='expiry-date-inputs'>
							<select value={addOnsState.credit_card.expiryDate.month} id='month' className='expiry-date component-design' onChange={handleDateChange} {...dataCySelector('month-select-field')}>
								{monthOptions}
							</select> &ensp;
							<select value={addOnsState.credit_card.expiryDate.year} id='year' className='expiry-date component-design' onChange={handleDateChange} {...dataCySelector('year-select-field')}>
								{yearOptions}
							</select>
						</div>
					</div>
					<div className='cvv' id='first-name-recipient-block'>
						<div className='cvv-label'>
							<label>CVV</label>
							<img src='/img/icons/question-mark-icon.png' width={15} height={15}/>
						</div>
						<ValidatedField type='text' id='cvv' value={addOnsState.credit_card.cvv} onChange={handleChange} onBlur={handleBlur} className={addOnsState.formErrors?.credit_card?.cvv ? 'error' : 'component-design'} required {...dataCySelector('cvv-input')}/>
						<FieldErrorMessage>{errorDisplay(addOnsState.formErrors?.credit_card?.cvv)}</FieldErrorMessage>
					
					</div>
					<div id='zip-code-block'>
						<label htmlFor='postal_code'>ZIP / Postal Code</label>
						<ValidatedField type='text' id='postal_code' value={addOnsState.credit_card.postal_code} onChange={handleChange} onBlur={handleBlur} className={addOnsState.formErrors?.credit_card?.postal_code ? 'error' : 'component-design'} required {...dataCySelector('zip-code-input')}/>
						<FieldErrorMessage>{errorDisplay(addOnsState.formErrors?.credit_card?.postal_code)}</FieldErrorMessage>
					</div>
				</div>
			</div>
		</CreditCardContainer>)
	}

	return <>
		{buyAddOnsIsLoading && <LoadingOverlay />}
		{subscriptionLoading && <LoadingOverlay />}
		{paymentListLoading && <LoadingOverlay />}
		{loadingState.isBuyAddOnsCreated && <LoadingOverlay />}
		{loadingState.isVerifyCode && <LoadingOverlay />}
		<div className='single-entry'>
			<div className='container' >
				<div className='row'>
					<div style={{ width: '100%' }} className='mnmd-block post post--single type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5 margin-bottom-0px' >
						<AddOnsContainer>
							<div className='addons'>
								<span className='addons-title'>Buy addons</span>
								<span>Price</span>
							</div>
							<Separator style={{ marginTop: '0.5rem', marginBottom:'2rem' }} />
							<div className='addons-content'>
								<div>
									<p>Commercial Addons (Pro-rated)</p>
									<div className='qtyContainer'>
										<label htmlFor='qty'>Qty:</label>
										<select className='component-design' id='qty' value={addOnsState.qty} onChange={handleChangeQty}>
											{[...Array(15).keys()].map((number) => (
												<option key={number + 1} value={number + 1}>{number + 1}</option>
											))}
										</select>
									</div>

									<FieldErrorMessage>{errorDisplay(addOnsState.formErrors?.qty)}</FieldErrorMessage>
								</div>
								<span className='price'><strong>USD {pricePerHead}{!pricePerHead.toString().includes('.') && <>.00</>}</strong></span>
							</div>
							<Separator />
							<p className='addons-total'>Total: USD  {totalPrice}{!totalPrice.toString().includes('.') && <>.00</>}</p>
							<Separator />
							<p className='footerTitle'>Full price for each commercial addon is USD 129.00. Add-ons bought will be valid until <strong>{subsEndDate}.</strong></p>

							{isAuthenticated && paymentData?.success && !cardDetails.isNew ? savedCreditCard() : newCreditCard()}
							<div className='captcha'>
								<i>Please check the box below to proceed.</i>
								<ReCAPTCHA
									ref={recaptchaRef}
									sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
									onChange={recaptchaHandler}
								/>
							</div>
							<div className='checkbox'>
								<input type='checkbox' id='termsAgreement' value={addOnsState.termsAgreement} onChange={handleCheckChange} onBlur={handleBlur} {...dataCySelector('terms-agreement-checkbox')}/>&ensp;
								<label htmlFor='termsAgreement'>I have read and agreed to the terms of the <Link to={'/subscription-agreement'}>Commercial Subscription Agreement</Link></label>
							</div>
							<FieldErrorMessage>{errorDisplay(addOnsState.formErrors.termsAgreement)}</FieldErrorMessage>
						</AddOnsContainer>
						<Separator />
						<ActionButtons className={isMobile ? 'mobile' : 'desktop'}>
							<Link to={'/my-company'}>
								<AddOnsBackButton className={isMobile ? 'mobile back-button' : 'back-button'} {...dataCySelector('back-btn')}><img src='img/icons/arrow-back-ios.png' className='img-arrow'/><span>&ensp;BACK</span></AddOnsBackButton>
							</Link>
							<AddOnsButton onClick={handleBuyAddOns} {...dataCySelector('buy-addons-btn')}>BUY ADDONS</AddOnsButton>
						</ActionButtons>
					</div>

					{!addOnsState.error.isShow ? '' : 
						<ErrorModal messageBody={addOnsState.error.message} isOpen={addOnsState.error.isShow} onClose={closeModal} />
					}
				</div>
			</div>
		</div></>
}

export default BuyAddOns
