/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react'
import moment from 'moment'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { useAppSelector } from '../../app/hooks'
import { Users as UsersFromStore } from '../../app/slices/Users'
import { IsUserAuthenticated } from '../../utils'
import { Seperator } from '../../views/content/pages/subscriptions/styles'
import RegionCard, { LinkButton } from './RegionCard'

import { SuccessContainer } from './styles'

interface SuccessActivationType {
	header: string;
	bodyOne: string;
	bodyTwo?: string;
}

const regionList = [{
	key: 'bordeaux',
	name: 'Bordeaux',
	url: '/search/wine?country=France&region=Bordeaux',
	imgUrl: '/img/icons/icon_bordeaux.svg'
}, {
	key: 'burgundy',
	name: 'Burgundy',
	url: '/search/wine?country=France&region=Burgundy',
	imgUrl: '/img/icons/icon_burgundy.svg'
}, {
	key: 'california',
	name: 'California',
	url: '/search/wine?country=USA&region=California',
	imgUrl: '/img/icons/icon_california.svg'
}, {
	key: 'champagne',
	name: 'Champagne',
	url: '/search/wine?country=France&region=Champagne',
	imgUrl: '/img/icons/icon_champagne.svg'
}, {
	key: 'italy',
	name: 'Italy',
	url: '/search/wine?country=Italy',
	imgUrl: '/img/icons/icon_italy.svg'
}]

const planData = (planId: string) => {
	if(planId === 'commercial-1-year'){
		return {
			item_name: 'Annually commercial subscription',
			index: 2,             
			item_category: 'Commercial', 
			item_category2: 'Annually',
			price: 249
		}
	}

	if(planId === 'personal-1-year') {
		return {
			item_name: 'Annually personal subscription',
			index: 1,             
			item_category: 'Personal',  
			item_category2: 'Annually',
			price: 129 
		}
	}

	if(planId === 'personal-monthly') {
		return {
			item_name: 'Monthly personal subscription',
			index: 3,             
			item_category: 'Personal',  
			item_category2: 'Monthly',
			price: 15 
		}
	}

	return {
		item_name: 'Quaterly personal subscription',
		index: 0,             
		item_category: 'Personal',  
		item_category2: 'Quaterly',
		price: 37
	}
}

function SuccessActivation({header, bodyOne, bodyTwo}: SuccessActivationType): ReactElement {
	
	const { userData } = useAppSelector(UsersFromStore)
	const { search } = useLocation()
	const history = useHistory()
	const {type} = useParams<{ type: string }>()
	const queryParams = new URLSearchParams(search)
	const planType = queryParams.get('type')

	useEffect(() => {
		if(!IsUserAuthenticated()){
			history.push('/login')
		}
	}, [])

	useEffect(() => {
		if(userData && type === 'success' && (planType === 'commercial' || planType === 'personal'))
			triggerTags()
	},[userData, type, planType])

	const triggerTags = () => {
		const {_id, planId} = userData.subscription

		const event = {
			event: 'purchase',
			ecommerce: {
				transaction_id: _id,
				currency: 'USD',
				value: planData(planId).price,
				items : [
					{
						...planData(planId)
					}
				]
			}
		}

		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null
			}
		})

		TagManager.dataLayer({
			dataLayer: event
		})
	}

	return <div>
		<SuccessContainer className='container'>
			<div className='head-content'>
				<img alt='icon-success' src='/img/icons/icon_success.svg'  />
				<div className='head-content-body'>
					<h3>{header}</h3>
					<p>{bodyOne} {moment(userData?.subscription?.end).format('MMMM DD, YYYY')}.</p>
					{bodyTwo && <p>{bodyTwo}</p>}
					<LinkButton href='/my-subscription'>
							MANAGE YOUR ACCOUNT
					</LinkButton>
				</div>
			</div>
			<Seperator width='100%' />
			<div className='regions-selection'>
				<h3>Browse Wines from Notable Regions</h3>
				<div className='regions'>
					{regionList.map(region => <RegionCard key={region.key} name={region.name} url={region.url} imgUrl={region.imgUrl} />)}
				</div>
			</div>
		</SuccessContainer>
	</div>
}

export default SuccessActivation
