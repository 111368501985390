/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import { IsUserAuthenticated } from '../../../../../utils'
import ErrorModal from '../../../../../components/error-modal'
import { useChangeSubscriptionPlanMutation, useGetSubscriptionDetailsQuery } from '../../../../../app/services/Subscription'
import { isMobile } from 'react-device-detect'
import { PLANS } from '../../../../../configs'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import NoticeModal from '../../../../../components/notice-modal'
import { RPColors } from '../../../../../styles/CSSConstant'


const initChangePlanState = {
	plan: {
		planLabel: PLANS[0].label,
		planId: PLANS[0].planId,
		planPrice: PLANS[0].numericPrice,
	},
	userId: '',
	formErrors: {},
	openModal: {
		isOpen: false,
		message: ''
	},
	error: {
		isShow: false,
		message: ''
	}
}

function ChangePlan(): ReactElement {
	const history = useHistory()
	const { userData } = useAppSelector(UsersFromStore)
	const isAuthenticated = IsUserAuthenticated()
	const { data: subscriptionDetails, isLoading: subscriptionLoading } = useGetSubscriptionDetailsQuery(userData?.subscription?._id, { skip: !userData?.subscription?._id })
	const [executeChangeSubscriptionPlanMutation, { data: changePlanData, isLoading: changePlanIsLoading, isError: changePlanIsError }] = useChangeSubscriptionPlanMutation()
	const [changePlanState, setChangePlanState] = useState<any>(initChangePlanState)
	const [totalPrice, setTotalPrice] = useState<number | string>(0.00)
	const [subsEndDate, setSubsEndDate] = useState<any>()
	const [allPlanId, setAllPlanId] = useState<any[]>([])
	const [showNoticeModal, setShowNoticeModal] = useState<boolean>(false)
	const [planLabel, setPlanLabel] = useState<string>('')
	const [planId, setPlanId] = useState<string>('')
	const [planPrice, setPlanPrice] = useState<number>(0)

	useEffect(() => {
		if (userData && userData?.orbit_roles) {
			if (userData.orbit_roles.includes('project:customer-commercial')) {
				window.location.href = '/my-subscription'
			}
		}

		if (!isAuthenticated) {
			history.push('/login')
		}
	}, [userData])

	const errorDisplay = (errorMessage: string) => {
		return <>
			{
				errorMessage && <div className='error-message'>
					<img src={'img/icons/icon_error_warning.svg'} />
					<span>{errorMessage}</span>
				</div>
			}
		</>
	}

	const handleOpenNoticeModal = () => {
		setChangePlanState((state: any) => ({ ...state, openModal: { isOpen: true, message: `You will be charged for a ${changePlanState.plan.planLabel} subscription on ${moment(subsEndDate).format('MMMM DD, YYYY')}. Are you sure you want to proceed?` } }))
	}

	const openModal = (message: string) => {
		setChangePlanState((state: any) => ({ ...state, error: { isShow: true, message: message } }))
	}

	const closeModal = () => {
		setChangePlanState((state: any) => ({ ...state, error: { isShow: false, message: '' } }))
	}

	const handleChangePlan = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (event.target.id === 'changePlan') {

			let planPrice = 0.00
			let planLabel = ''
			if (event.target.value === PLANS[0].planId) {
				planLabel = PLANS[0].label
				planPrice = PLANS[0].numericPrice
			} else if (event.target.value === PLANS[1].planId) {
				planLabel = PLANS[1].label
				planPrice = PLANS[1].numericPrice
			} else if (event.target.value === PLANS[2].planId) {
				planLabel = PLANS[2].label
				planPrice = PLANS[2].numericPrice
			} else if (event.target.value === PLANS[3].planId) {
				planLabel = PLANS[3].label
				planPrice = PLANS[3].numericPrice
			}

			setPlanId(event.target.value)
			setPlanLabel(planLabel)
			setPlanPrice(planPrice)
		}
	}

	useEffect(() => {
		if (subscriptionDetails) {
			if (subscriptionDetails.success) {
				setSubsEndDate(subscriptionDetails?.data?.end)

				const planIdsAndLabels = PLANS.filter(plan => plan.planId !== subscriptionDetails?.data?.planId)
					.map(plan => ({
						planId: plan.planId,
						label: plan.label,
						price: plan.numericPrice
					}))

				setPlanId(planIdsAndLabels[0]?.planId)
				setPlanLabel(planIdsAndLabels[0]?.label)
				setPlanPrice(planIdsAndLabels[0]?.price)
			}
		}
	}, [subscriptionDetails])


	const handleSubmitChangePlan = async (isConfirm: boolean) => {
		if (isConfirm) {
			const payload = {
				planId: planId,
				customerId: userData?._id
			}

			const csrf_token = await getCSURFToken()
			await executeChangeSubscriptionPlanMutation({ params: payload, token: csrf_token })
		}
		setShowNoticeModal(false)
	}
	console.log('changePlanData', changePlanData)

	useEffect(() => {
		if (changePlanData) {
			if (changePlanData.success) {
				history.push('/my-subscription')
				window.location.reload()
			} else {
				openModal(JSON.stringify(changePlanData.message).replace(/</g, '\\u003c'))
			}
		}

		if (changePlanIsError) {
			openModal('Something went wrong. Please try again.')
		}
	}, [changePlanData])

	return (
		<div>
			{changePlanIsLoading && <LoadingOverlay />}
			{subscriptionLoading && <LoadingOverlay />}
			<div>
				<div style={{ padding: 32 }}><p style={{ fontSize: 22, fontWeight: 600, textAlign: 'center' }}>Change Plan</p></div>
				<div style={{ border: `1px solid ${RPColors.borderColor}`, padding: 24, width: isMobile ? 'auto' : 564, marginLeft: isMobile ? 0 : 'auto', marginRight: isMobile ? 0 : 'auto', borderRadius: isMobile ? 0 : 12 }}>
					<p style={{ fontSize: 24, fontWeight: 'bold' }}>Change subscription plan</p>
					<p style={{ fontSize: 16 }}>The plan type selected will be activated and start on {moment(subsEndDate).format('DD MMM YYYY')}. <br />If auto-renew is on, your default payment method will be automatically charged on that day.</p>
					<p style={{ fontSize: 16, fontWeight: 'bold' }}>Select a plan to change</p>
					<select
						id="changePlan"
						value={planId}
						onChange={handleChangePlan}
						style={{ border: '1px solid #ccc', borderRadius: 4, padding: 20, backgroundColor: '#fff' }}
					>
						{PLANS.filter(plan => plan.planId !== subscriptionDetails?.data?.planId).map((plan, index) => (
							<option key={index} value={plan.planId}>{plan.label}</option>
						))}
					</select>
					<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', padding: 30 }}>
						<p style={{ fontSize: 14, fontWeight: 500, textDecoration: 'underline', color: RPColors.darkPrimary, margin: 0, cursor: 'pointer' }}>BACK</p>
						<div
							onClick={() => setShowNoticeModal(true)}
							style={{ backgroundColor: '#73333f', borderRadius: 8, justifyContent: 'center', alignContent: 'center', height: 41, width: 140, marginLeft: 40, cursor: 'pointer' }}>
							<p style={{ margin: 0, fontSize: 14, color: '#fff', textAlign: 'center' }}>CHANGE PLAN</p>
						</div>
					</div>
				</div>
			</div>
			{showNoticeModal &&
				<NoticeModal
					messageBody={`You will be charged for a ${planLabel} subscription on ${moment(subsEndDate).format('MMMM DD, YYYY')}. Are you sure you want to proceed?`}
					title={'CHANGE PLAN'}
					isOpen={showNoticeModal} onClose={handleSubmitChangePlan} isBlocked={false} />}
		</div>
	)
}

export default ChangePlan
