/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

// Utilities
import { TranslationsUtil } from '../../../../utils'

// i18n
import englishTranslation from './translations/en.json'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

// Components
import IssueArchives from '../../sections/issue-archives'

// Styles
import LoadingOverlay from '../../../../components/Loading-overlay'
import { ArticleContainer, Truncate } from './styles'
import { useGetArticleIssueQuery, useGetCurrentNextAndPreviousIssueQuery, useGetIssuesDetailsQuery, useGetLatestIssueQuery } from '../../../../app/services'
import { SetActiveFilters } from '../../../../app/slices/SearchFilters'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'

type IssueDetails = {_id: string;
		title: string;
		published_at: string;
		status: string;
		publication: {
			_id: string
		};
		created_at: string;
		created_by: string;
		count: {
			assignments: number;
			tasting_records: number;
			tasting_notes: number;
			producer_notes: number;
			tasting_notes_approved: number;
			wines: number;
			wines_approved: number;
			producer_notes_approved: number;
		};
		updated_at: string;
		updated_by: string;
		jobId: string;
		live: string
	
}

function IssuePage(): ReactElement {
	const translate = translationUtil.getTranslator()
	const { issueID } = useParams<{ issueID: string }>()
	const [articlesFromIssue, setArticlesFromIssue] = useState([])
	const [issueDetails, setIssueDetails] = useState<IssueDetails>()

	// const { triggerTrackingHandler } = useArticleTracking()
	
	const [issueKey, setIssueKey] = useState(issueID)
	
	const { data: latestIssueDetails } = useGetLatestIssueQuery({skip: issueKey !== 'latest'})

	const {data:issueData} = useGetCurrentNextAndPreviousIssueQuery(issueKey, {skip: issueKey === 'latest'})

	const dispatch = useDispatch()
	const { data: issueDetailsData, isSuccess: isSuccessIssueDetailsQuery, isLoading: isLoadingQuery, isError: isErrorQuery } = useGetIssuesDetailsQuery(issueKey, {skip: !issueData})
	const { data: articleIssueData, isSuccess: isSuccessArticleIssueQuery, isLoading: isLoadingArticleIssueQuery, isError: isErrorArticleIssueQuery } = useGetArticleIssueQuery(issueKey, {skip: !issueData})

	useEffect(() => {
		if(issueDetailsData && isSuccessIssueDetailsQuery){
			setIssueDetails(issueDetailsData)
		}

		if(isErrorQuery){
			console.log('Something went wrong. Please try again.')
		}
	},[issueDetailsData, isErrorQuery])

	useEffect(() => {
		if(articleIssueData && isSuccessArticleIssueQuery){
			if(articleIssueData.success){
				setArticlesFromIssue(articleIssueData.data)
			}
		}
		
		if(isErrorArticleIssueQuery){
			console.log('Something went wrong. Please try again.')
		}
	},[articleIssueData, isErrorArticleIssueQuery])

	useEffect(() => {
		if(issueID !== 'latest') {
			setIssueKey(issueID)
		}
	}, [issueID])

	useEffect(() => {
		if(issueKey === 'latest' && latestIssueDetails && latestIssueDetails.length) {
			setIssueKey(latestIssueDetails[0]._id)
		}
		
	}, [issueKey, latestIssueDetails])

	
	

	return (
		<>
			{isLoadingArticleIssueQuery && <LoadingOverlay />}
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous pag<any, void>e-heading page-heading--has-background">
				<div className="container">
					{isLoadingQuery ?
						<><div className="page-heading__subtitle">{'Loading...'}</div></>: 
						<>
							{issueDetails?.count
								?
								<>
									{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>{issueDetails.title}</h2> : <h2 className="page-heading__title">{issueDetails.title}</h2>}
									<div className="page-heading__subtitle">{issueDetails.count.assignments} {translate('articles')} - {issueDetails.count.wines} {translate('Wines Tasted')}</div>
								</>
								:
								<>
									<div className="page-heading__subtitle">{'No articles found.'}</div>
							
								</>
							}
						</>
					}
					
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth">
				<div className="container">
					<div className="row">
						<div className="mnmd-main-col" role="main">
							<div className="mnmd-block">
								<div className="posts-listing list-unstyled list-space-lg">
									{/* { isLoading? (<div>Loading ...</div>):null } */}

									{articlesFromIssue?.map((article: any, article_from_issue_key: number) => (
										<ArticleContainer className="list-item" key={article_from_issue_key}>
											<article className="post post-responsive post--horizontal post--horizontal-sm cat-1">
												<div className="post__thumb">
													<Link to={`/articles/${article.articleId}`}>
														{article.banner?.url ? <CloudImageUtils imgSrc={article.banner?.url} alt="image" ratio={2} params=''/>
															: <img src='img/icons/default_article_banner_sm.jpg' alt='image'/> }
													</Link>
												</div>
												<div className="post__text">
													<h3 className="post__title typescale-2">
														<Truncate>
															<Link to={`/articles/${article.articleId}`} className="truncate-lines">{article.title} </Link>
														</Truncate>

													</h3>
													<div className="post__excerpt">
														{article.excerpt}
													</div>
													<div className="post__meta">
														<span className="entry-author">{translate('By')}&nbsp;
															<Link to={`/author/${article.author.id}`} href="#" className="entry-author__name">{article.author.name}</Link>
														</span>
														<time className="time published" dateTime={article.created_at} title={moment(article.created_at).format('MMM DD, YYYY at hh:mm a')}><i className="mdicon mdicon-schedule"></i>{moment(article.created_at).format('MMM DD, YYYY')}</time>
														<Link onClick={()=> dispatch(SetActiveFilters({}))} to={`/search/wine?article_id=${article.articleId}`} title={`${article.tasting_note_count} tasting notes`}><img src='/img/icons/black.png' style={{height: 'auto'}} />&nbsp;{article.tasting_note_count}</Link>
													</div>
												</div>
											</article>
										</ArticleContainer>
									))}

								</div>
								<nav className="mnmd-pagination mnmd-pagination--next-n-prev">
									<div className="mnmd-pagination__inner">
										{issueData?.previous ? (
											<div className="mnmd-pagination__links">
												<Link to={`/issues/${issueData?.previous?._id}`} className="mnmd-pagination__item mnmd-pagination__item-prev">{issueData?.previous?.title}</Link>
											</div>
										) : (<div className="mnmd-pagination__links"></div>)}
										{issueData?.next ? (
											<div className="mnmd-pagination__links">
												<Link to={`/issues/${issueData?.next?._id}`} className="mnmd-pagination__item mnmd-pagination__item-next">{issueData?.next?.title}</Link>
											</div>
										) : null}
									</div>
								</nav>
							</div>
						</div>
						<div className="mnmd-sub-col mnmd-sub-col--right sidebar js-sticky-sidebar" role="complementary">
							<div className="widget widget_archive">
								<IssueArchives />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default IssuePage
