import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import NonExistPage from '../../404-page/NonExistPage'
import AddGroup from './AddGroup'

function CompanyManagement():ReactElement {
	const { type } = useParams<{ type: string }>()
	
	if(type === 'add-group') {
		return <AddGroup />
	} else {
		return <NonExistPage />
	}
}

export default CompanyManagement
