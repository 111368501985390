/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'

import { useAppSelector } from '../../../../app/hooks'
import { IsUserAuthenticated } from '../../../../utils'
import { LinearResultContainer, RatingDisplay, ResultBoxContainer, ResultOptions, ResultSortContainer } from './common/styles'
import Wine from '../../../../structure/classes/Wine'

// Sections 
import { SetSort } from '../../../../app/slices/SearchFilters'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { slugify } from '../../../../utils/ArticlesUtil'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'
import PriceListingsModal from '../../../modals/price-listings'
import SectionSeparator from '../../sections/section-separator'
import { WineSelectedType } from './common/types'
import {Authentication as AuthFromStore} from '../../../../app/slices/Authentication'
import { useBasePath } from '../../Common'
import CommonModalContainer from '../../../modals/ModalContainer'
import { RootState } from '../../../../app/store'


const GetWineLayout = (props: { wine: any, userData: any, onCheckPrice: (wine: any) => void, selectedWines:WineSelectedType[], checkedWine: (e: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => void, isAuthenticated: boolean, isModalOpen: (value: boolean) => void }) => {
	const { wine, userData, onCheckPrice, selectedWines, checkedWine, isAuthenticated, isModalOpen } = props

	const checkPriceHandler = () => {
		onCheckPrice(wine)
		isModalOpen(true)
	}
	const authentication = useAppSelector(AuthFromStore)

	const pathname = useBasePath()

	let wineData: WineSelectedType = {
		id: '',
		checked: false,
		wine_details:{}
	}
	const findWine = selectedWines.find(wineArr => wineArr.id === wine.objectID)

	if(!findWine){
		wineData = {
			id: wine.objectID,
			checked: false,
			wine_details:{}
		}
	}else{
		wineData = findWine
	}

	const triggerSubscribe = () => {
		TagManager.dataLayer({
			dataLayer: {
				'event': 'subscribe_btn',
				'page_origin': pathname
			}
		})
	}

	return <div>
		<ResultBoxContainer rounded={true} bgColor={'#fff'} flexed={true} justify={'space-between'}>
			<LinearResultContainer>
				<div className="wine-name-selector">
					<div className='wine-check'>
						{isAuthenticated && <input checked={wineData.checked} name={wine.name} type='checkbox' id={`${wine.name} - ${wine.objectID}`} onChange={(e) => checkedWine(e, false)} value={wine.objectID} />}
						<label title={wine && wine.latest_note && wine.latest_note?.content && isAuthenticated ? wine.latest_note?.content : ''}>
							{
								wine.slug && wine.slug !== null ?
									<Link className='no-style font-weight-bold' to={`/wines/${wine.objectID}/${slugify(wine.slug)}`}>
										{wine.producer === wine.name ? wine.vintage + ' ' + wine.name : wine.display_name}
									</Link>
									:
									<Link className='no-style font-weight-bold' to={`/wines/${wine.objectID}`}>
										{wine.producer === wine.name ? wine.vintage + ' ' + wine.name : wine.display_name}
									</Link>
							}
						</label>
					</div>
					<div className='wine-ratings'>
						{(!isFreeUser(userData) && IsUserAuthenticated() || wine.rating_display === '100') ?
							(<RatingDisplay style={{ color: '#333333' }} className='ratings'><span>{getWineRatingDisplay(wine)}</span></RatingDisplay>)
							:
							(<button onClick={triggerSubscribe} className='subscribe-button'><Link to={'/subscriptions'}><span className="subscribe-title">SUBSCRIBE</span><img src='img/icons/lock-2.png' style={{ paddingBottom: '2px' }} /></Link></button>)
						}
					</div>
				</div>
				<div>
					<ResultOptions>
						<div style={{ paddingLeft: '15px' }}>
							{wine.eco_distinction ? <span className="icon-container" title='RP Green Emblem'>
								<img style={{ height: '19px' }} src='img/icons/icon-rpwa-eco.svg' />
							</span>
								: null}
							{wine.certified ? <span className="icon-container" title={wine.certified ? wine.certified : ''}>
								<img src='img/icons/elements-icons-certified.png' />
							</span>
								: null}
							{wine.color_class ? <span className="icon-container" title={wine.color_class}>
								<img src={`img/icons/${wine.color_class?.toLowerCase()}.svg`} />
							</span> : null}
							{(!isFreeUser(userData)) && <span onClick={checkPriceHandler}><span className="check-price">Check price</span ></span>}
						</div>
					</ResultOptions>
				</div>
			</LinearResultContainer>
		</ResultBoxContainer>
		<SectionSeparator position={'horizontal'} ></SectionSeparator>
	</div>
}

const GetWineMobileLayout = (props: { wine: any, userData: any, onCheckPrice: (wine: any) => void, selectedWines:WineSelectedType[], checkedWine: (e: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => void, isAuthenticated: boolean, isModalOpen: (value: boolean) => void }) => {
	const { wine, userData, onCheckPrice, isAuthenticated, selectedWines, checkedWine, isModalOpen } = props
	const checkPriceHandler = () => {
		onCheckPrice(wine)
		isModalOpen(true)
	}

	let wineData: WineSelectedType = {
		id: '',
		checked: false,
		wine_details:{}
	}
	const findWine = selectedWines.find(wineArr => wineArr.id === wine.objectID)

	if(!findWine){
		wineData = {
			id: wine.objectID,
			checked: false,
			wine_details:{}
		}
	}else{
		wineData = findWine
	}
	return <div>
		<ResultBoxContainer rounded={true} bgColor={'#fff'} flexed={true} justify={'space-between'}>
			<LinearResultContainer>
				<div className="wine-name-selector">
					<div className='wine-check'>
						{isAuthenticated && <input checked={wineData.checked} name={wine.name} type='checkbox' id={`${wine.name} - ${wine.objectID}`} onChange={(e) => checkedWine(e, false)} value={wine.objectID} />}
						<label title={wine && wine.latest_note && wine.latest_note?.content && isAuthenticated ? wine.latest_note?.content : ''}>
							{
								wine.slug && wine.slug !== null ?
									<Link className='no-style font-weight-bold' to={`/wines/${wine.objectID}/${slugify(wine.slug)}`}>
										{wine.producer === wine.name ? wine.vintage + ' ' + wine.name : wine.display_name}
									</Link>
									:
									<Link className='no-style font-weight-bold' to={`/wines/${wine.objectID}`}>
										{wine.producer === wine.name ? wine.vintage + ' ' + wine.name : wine.display_name}
									</Link>
							}
						</label>
					</div>
					<div className='wine-ratings'>
						{(!isFreeUser(userData) && IsUserAuthenticated() || wine.rating_display === '100') ?
							(<RatingDisplay style={{ color: '#333333' }} className='ratings'><span>{getWineRatingDisplay(wine)}</span></RatingDisplay>)
							:
							(<button className='subscribe-button'><Link to={'/subscriptions'}><span className="subscribe-title">SUBSCRIBE</span><img src='img/icons/lock-2.png' style={{ paddingBottom: '2px' }} /></Link></button>)
						}
					</div>
				</div>
				<div>
					<ResultOptions>
						<div style={{ paddingLeft: '15px' }}>
							{wine.eco_distinction ? <span className="icon-container" title='RP Green Emblem'>
								<img style={{ height: '19px' }} src='img/icons/icon-rpwa-eco.svg' />
							</span>
								: null}

							{wine.certified ? <span className="icon-container" title={wine.certified ? wine.certified : ''}>
								<img src='img/icons/elements-icons-certified.png' />
							</span>
								: null}

							{wine.color_class ? <span className="icon-container" title={wine.color_class}>
								<img src={`img/icons/${wine.color_class?.toLowerCase()}.svg`} />
							</span> : null}
							{(!isFreeUser(userData)) && <span onClick={checkPriceHandler}><span className="check-price">Check price</span ></span>}
							
							{/* {(isFreeUser(userData) || IsUserAuthenticated()) && <span data-target={`#price-listing-modal_${wine.objectID}`} data-toggle="modal" onClick={checkPriceHandler}><span className="check-price">Check price</span></span>} */}
						</div>
					</ResultOptions>
				</div>
			</LinearResultContainer>
		</ResultBoxContainer>
		<SectionSeparator position={'horizontal'} ></SectionSeparator>
	</div>
}

const getWineRatingDisplay = (wine: any) => (wine.rating_display ? `RP ${wine.rating_display}` : 'N/A')

function WineResults(props: { results: any[], wineIds: WineSelectedType[], toggleChecked: (e: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => void  }): ReactElement {
	const { results: wineResults, wineIds, toggleChecked } = props

	const [wineState, setWineState] = useState<any>({})
	const [vintage, setVintage] = useState<string>('vintage_high')
	const [rating, setRating] = useState<string>('rating_high')
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const { userData } = useAppSelector(UsersFromStore)
	const [isFindOnlineModalOpen, setIsFindOnlineModalOpen] = useState(false)
	const selectedWinesData = useSelector((state: RootState) => state.SearchResults.Wines.selectedWines)
	const dispatch = useDispatch()

	const checkPriceHandler = (wine: any) => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'check_price',
				page_origin: 'search_wine',
				objectID: wine?.objectID,
				country: wine?.country,
				region: wine?.region,
				subregion: wine?.sub_region,
				appellation: wine?.appellation,
				subappellation: wine?.sub_appellation,
				producer: wine?.producer,
				name: wine?.name,
				date_last_tasted: Math.floor(wine?.date_last_tasted / 1000).toString(),
				last_reviewer: wine?.last_reviewer,
				rating_computed: wine?.rating_computed.toString(),
				vintage: wine?.vintage.toString()
			}
		})
		setWineState(wine)
	}

	useEffect(() => {
		if(IsUserAuthenticated() && !isFreeUser(userData)) return setIsAuthenticated(true)
		else return setIsAuthenticated(false)
	}, [IsUserAuthenticated(), isFreeUser(userData)])

	

	const handleVintage = (type: any) => {
		if (type === 'vintage_low') {
			setVintage('vintage_high')
		} else {
			setVintage('vintage_low')
		}
		dispatch(SetSort(vintage))
	}

	const handleRating = (type: any) => {
		if (type === 'rating_low') {
			setRating('rating_high')
		} else {
			setRating('rating_low')
		}
		dispatch(SetSort(rating))
	}

	const modalHandler = (value: boolean) => {
		setIsFindOnlineModalOpen(value)
	}	
	return (
		<>
			<ResultSortContainer>
				<span className={isAuthenticated ? 'vintage-title-auth' : 'vintage-title-free'} onClick={() => handleVintage(vintage)}>Vintage<img src='img/icons/elements-icons-dark-sort.png' style={{ height: '17px', paddingTop: '3px', paddingLeft: '5px' }} /></span>
				{isAuthenticated &&
					<span className='rating-title' onClick={() => handleRating(rating)}>Rating<img src='img/icons/elements-icons-dark-sort.png' style={{ height: '17px', paddingTop: '3px', paddingLeft: '5px' }} /></span>}
			</ResultSortContainer>
			<SectionSeparator position={'horizontal'} ></SectionSeparator>

			{wineResults?.map((wine: Wine, key: number) => (isMobile
				?
				<>
					<GetWineMobileLayout key={key} wine={wine} userData={userData} onCheckPrice={checkPriceHandler} selectedWines={selectedWinesData} checkedWine={toggleChecked} isAuthenticated={isAuthenticated} isModalOpen={modalHandler}/>
				</>
				:
				<GetWineLayout key={key} wine={wine} userData={userData} onCheckPrice={checkPriceHandler} selectedWines={selectedWinesData} checkedWine={toggleChecked} isAuthenticated={isAuthenticated} isModalOpen={modalHandler}/> ))
			}
			{wineState ? 
				<CommonModalContainer isOpen={isFindOnlineModalOpen} onClose={()=> setIsFindOnlineModalOpen(false)}>
					<PriceListingsModal wine={wineState} isModalOpen={modalHandler}/>					
				</CommonModalContainer> : <></>}
		
		</>
	)
}

export default WineResults