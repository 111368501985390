/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement, useEffect, useState } from 'react'
import { Link, 
	// useHistory 
} from 'react-router-dom'

// Utils
// import { GetGroupedArticles, AttatchCustomSortOrder, ConvertJSONArticleToClass
// } from '../../../../../utils'

// Styles
import { slugify } from '../../../../../utils/ArticlesUtil'
import {
	ArticleContainer, ArticleImageContainer, 
	// FiltersContainer, ListAndFilters, PageHeader, ResultContainer
} from './styles'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { CloudImageUtils } from '../../../../../utils/CloudImageUtils'
import useScreen from '../../../../../helper/hooks/useScreen'
import Pagination from '../../../../../components/pagination'
import { useGetWineJournalArticlesQuery } from '../../../../../app/services/Article'

function FreePubsWineJournal(): ReactElement {

	// const [group, setGroup] = React.useState<any>()
	// const [filterCategory, setFilterCategory] = useState('')
	// const [sort, setSort] = useState('recent')
	// const { ArticleCategories } = useAppSelector(SummaryFromStore)
	// const [state, setState] = useState({
	// 	isLoading: false,
	// })
	// const { triggerTrackingHandler } = useArticleTracking()

	const [data, setData] = useState<any>()
	const [offset, setOffset] = useState(0)
	const limit = 10
	const screen = useScreen()
	const {data: articleData, isFetching, isError} = useGetWineJournalArticlesQuery({offset, limit})
	const [totalPagination, setTotalPagination] = useState(10)
	const imageRatio = () => {
		switch (screen) {
		case 'tablet portrait':
			return 1.1
		case 'desktop':
			return 1.3
		default:
			return 3.5
		}
	}

	const handleChangePage = (page: number) => {
		setOffset(page)

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		if(articleData){
			if(articleData.success){
				const totalRecords = articleData?.data?.meta?.total
				setTotalPagination(totalRecords / limit)
				setData(articleData.data)
			}
		}
		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [articleData, isError])

	// const history = useHistory()
	// const [articleTags, setArticleTags] = useState({
	// 	'tags': ['Hello', 'World', 'Test']
	// })
	// useEffect(() => {
	// 	if (data && data.articles) {
	// 		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// 		const groupedContents: any[] = []
	// 		const container = GetGroupedArticles(data.articles, 'category.id')

	// 		const article = ConvertJSONArticleToClass(input)

	// 		setGroup(AttatchCustomSortOrder(groupedContents))
	// 	}
	// }, [data])

	// const getListItem = (input: ArticleJSON, index: number) => {
	// 	if (input) {

	// 		const article = ConvertJSONArticleToClass(input)

	// 		return (
	// 			<ArticleContainer key={index} onClick={() => { goToArticle(article.id, article.title) }} >
	// 				<ArticleImageContainer>
	// 					<img src={article.image?.url} />
	// 				</ArticleImageContainer>
	// 				<div className='details-container'>
	// 					<div className='title'>
	// 						{article.title}
	// 					</div>
	// 					<div className='excerpt'>{article.excerpt}</div>
	// 					<div className='filter-category'>{article.category.name}</div>
	// 					<div className="post__meta author">
	// 						<span className="entry-author">By &nbsp;
	// 							<Link to={`/author/${article.author.id}`} className="entry-author__name">{article.author.name}</Link>
	// 						</span>
	// 						<time className="time published" dateTime={article.published_date} title={article.getPublishedDate('title')}><i className="mdicon mdicon-schedule"></i>{article.getPublishedDate('display')}</time>
	// 						<Link to={`/search/wines?&article_id=${article.id}`} title={`${article.tasting_note_count} tasting notes`}><i className="mdicon mdicon-chat_bubble_outline"></i>{article.tasting_note_count}</Link>
	// 					</div>
	// 				</div>
	// 			</ArticleContainer>
	// 		)
	// 	} else {
	// 		return null
	// 	}
	// }

	// const goToArticle = (id: string, title: string) => {
	// 	history.push(`/articles/${id}/${slugify(title)}`)
	// }

	// const setCategoryFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
	// 	setFilterCategory(event.target.value)
	// }

	// const setSortingFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
	// 	setSort(event.target.value)
	// }

	return (
		<div>
			{isFetching && <LoadingOverlay />}
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Wine Journal</h2> : <h2 className="page-heading__title">Wine Journal</h2>}
					{data?.articles ? (
						<div className='page-heading__subtitle'>{articleData?.data?.meta?.total} articles</div>
					) : null}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth">
				<div className='container'>
					<div className="mnmd-block mnmd-block--fullwidth">
						<div className="container">
							{data?.articles && data.articles.map((article: any, key: number) => (
								<ArticleContainer key={key} >
									<ArticleImageContainer>
										<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}><CloudImageUtils imgSrc={article.image?.url} alt="image" ratio={imageRatio()} params='func=cropfit'/></Link>
									</ArticleImageContainer>
									<div className='details-container'>
										<div className='title'>
											<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}>{article.title}</Link>
										</div>
										<div className='excerpt'>{article.excerpt}</div>
										<div className='filter-category'>{article.category.name}</div>
										<div className="post__meta details" style={{ width: '100%' }}>
											<span className="entry-author">By:&nbsp;
												<span className="entry-author__name">{article.author.name}</span>
											</span>
											<time className="time published detail-container" dateTime={article.published_date} title={moment(article.published_date).format('MMM DD, YYYY')}>
												<i className="mdicon mdicon-schedule"></i>
												<span className='date'>{moment(article.published_date).format('MMM DD, YYYY')}</span>
											</time>
											<Link to={`/search/wines?&article_id=${article.id}`} title={`${article.tasting_note_count} tasting notes`}><i className="mdicon mdicon-chat_bubble_outline"></i>{article.tasting_note_count || 0}</Link>
											{/* <div className='tags-style'>
												{articleTags.tags.length > 0 ? (
													<div className="tagcloud">
														<div className='tags-label'>Tags:</div>
														{articleTags.tags.map((tag, tagCloudKey) => (
															<Link
																key={tagCloudKey}
																to={`/search/article?keyword=${tag}`}
																className="tag-link-77 tag-link-position-1"
																title="9 topics"
															>
																{tag}
															</Link>
														))}
													</div>
												) : (
													<div>No tags on this article.</div>
												)}
											</div> */}
										</div>
									</div>
								</ArticleContainer>
							))}
							<Pagination totalRecords={totalPagination} limit={1} render={handleChangePage} />
						</div>
					</div>
					{/* <ResultContainer>
						<ListAndFilters>
							<FiltersContainer>
								<div className={'filter-options'} >
									<label htmlFor='contributors-filter'>Filter</label>
									<select id='contributors-filter' onChange={setCategoryFilter} value={filterCategory} >
										<option value={''}>All Categories</option>
										<option className={'list-entry'} value={'Culture & Education'}>Culture & Education</option>
										<option className={'list-entry'} value={'News & Views'}>News & Views</option>
										<option className={'list-entry'} value={'People & Places'}>People & Places</option>
										<option className={'list-entry'} value={'Restaurants'}>Restaurants</option>
										<option className={'list-entry'} value={'Travel'}>Travel</option>
									</select>
								</div>

								<div className={'filter-options'} >
									<label htmlFor='sort-options'>SORT BY</label>
									<select id='sort-options' onChange={setSortingFilter} value={sort} >
										<option value='recent'>Most Recent</option>
										<option value='earliest' >Earliest</option>
									</select>
								</div>

							</FiltersContainer>

						</ListAndFilters>
					</ResultContainer> */}
				</div>
			</div>
		</div>
	)
}

export default FreePubsWineJournal