/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'

import { sprintf } from 'sprintf-js'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'
import { GetBaseQuery } from '../../utils/APIUtil'

export const BridgeAPI = createApi({
	reducerPath: 'BridgeAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2), 'application/x-www-form-urlencoded;charset=UTF-8'),
	endpoints: (builder) => ({
		createPaymentMethod: builder.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.create_payment_method, parameters),
		}),
		createUserSubscripiton: builder.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.create_user_subscription, parameters),
		}),
		deletePaymentMethod: builder.mutation({
			query: (token: string) =>
				sprintf(API_ENDPOINTS.delete_payment_method, token),
		}),
		getAppellationsFromKeyword: builder.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.search_appellations, parameters),
		}),
		getArticleTags: builder.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.get_article_tags, parameters),
		}),
		getAuthorCounts: builder.query<any, string>({
			query: (authorId: string) =>
				sprintf(API_ENDPOINTS.get_author_counts, authorId),
		}),
		getAuthorArticleCount: builder.query<any, string>({
			query: (authorId: string) =>
				sprintf(API_ENDPOINTS.get_author_article_count, authorId),
		}),
		getAuthorTastingNoteCount: builder.query<any, string>({
			query: (authorId: string) =>
				sprintf(API_ENDPOINTS.get_author_tasting_note_count, authorId),
		}),
		getBraintreeDiscountCode: builder.mutation<any, string>({
			query: (code: string) => sprintf(API_ENDPOINTS.get_discount_code, code),
		}),
		getCommentsOfAnArticle: builder.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.get_article_comments, parameters),
		}),
		getCustomerPaymentMethod: builder.query<any, string>({
			query: (customerId: string) =>
				sprintf(API_ENDPOINTS.get_payment_method, customerId),
		}),
		getEditorialCalendar: builder.query<any, string>({
			query: () => API_ENDPOINTS.get_editorial_calendar,
		}),
		getListOfAuthors: builder.query<any, string>({
			query: () => sprintf(API_ENDPOINTS.get_author_list),
		}),
		getPaymentHistory: builder.query<any, string>({
			query: (userId: string) =>
				sprintf(API_ENDPOINTS.get_payment_history, userId),
		}),
		getProducersFromKeyword: builder.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.search_producers, parameters),
		}),
		getPromoCode: builder.mutation<any, string>({
			query: (code: string) => sprintf(API_ENDPOINTS.get_promo_code, code),
		}),
		getProducersFromId: builder.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.get_producers, parameters),
		}),
		getProducerNotes: builder.query<any, string>({
			query: (article_id: string) =>
				sprintf(API_ENDPOINTS.get_producer_notes, article_id),
		}),
		getSubscriptionFromUserId: builder.query<any, string>({
			query: (userId: string) =>
				sprintf(API_ENDPOINTS.get_subscripton_from_user_id, userId),
		}),
		getSubscriptionHistory: builder.query<any, string>({
			query: (userId: string) =>
				sprintf(API_ENDPOINTS.get_subscription_history, userId),
		}),
		getTradeDirectory: builder.mutation({
			query: (params: string[]) => {
				return {
					url: API_ENDPOINTS.get_trade_directory,
					method: 'POST',
					body: params.join('&'),
				}
			}
		}),
		getTradeDirectoryV2: builder.mutation({
			query: (params: string[]) => {
				return {
					url: API_ENDPOINTS.get_trade_directoryV2 + params.join('&'),
					method: 'GET',
				}
			}
		}),
		getTradeDirectoryDetails: builder.query<any, string>({
			query: (companyId: string) => sprintf(API_ENDPOINTS.get_user_company_details, companyId)
		}),
		getTradeDirectoryLookupCountries: builder.query<any, string>({
			query: () => sprintf(API_ENDPOINTS.get_trade_directory_lookup_countries),
		}),
		getTradeDirectoryLookupCountriesV2: builder.query<any, string>({
			query: () => sprintf(API_ENDPOINTS.get_trade_directory_lookup_countriesV2),
		}),
		getTradeDirectoryLookupTypes: builder.query<any, string>({
			query: () => sprintf(API_ENDPOINTS.get_trade_directory_lookup_types),
		}),
		getTradeDirectoryLookupTypesV2: builder.query<any, string>({
			query: () => sprintf(API_ENDPOINTS.get_trade_directory_lookup_types),
		}),
		getUserCompany: builder.query<any, string>({
			query: (userId: string) =>
				sprintf(API_ENDPOINTS.get_trade_directory, userId),
		}),
		getUserSubscription: builder.query<any, string>({
			query: (userId: string) =>
				sprintf(API_ENDPOINTS.get_user_subscription, userId),
		}),
		getVintagesForAWine: builder.query<any, string>({
			query: (wineId: string) =>
				sprintf(API_ENDPOINTS.get_wine_vintages, wineId),
		}),
		getWineVarieties: builder.query<any, string>({
			query: () => sprintf(API_ENDPOINTS.get_wine_varieties),
		}),
		getEcoDetailsByCompanyId: builder.query<any, string>({
			query: (companyId: string) => `${API_ENDPOINTS.get_eco_details}?companyId=${companyId}`,
		}),
		getListEco: builder.query<any, void>({
			query: () => API_ENDPOINTS.get_list_eco,
		}),
		getCompanyDetails: builder.query<any, string>({
			query: (userId: string) => `${API_ENDPOINTS.get_company_details}?userId=${userId}`,
		}),
		getMoreFreeStuff: builder.mutation({
			query: (searchQuery: string[]) =>
				sprintf(API_ENDPOINTS.get_more_free_stuff, searchQuery.join('&'))
		}),
		getMySubscription: builder.mutation({
			query: (searchQuery: string[]) =>
				sprintf(API_ENDPOINTS.get_more_free_stuff, searchQuery.join('&'))
		}),
		getIssuesDetails: builder.query<any, string>({
			query: (issueID: string) => `${API_ENDPOINTS.get_issue_detailsByID}?issueId=${issueID}`,
		}),
		getArticleIssue: builder.query<any, string>({
			query: (issueID: string) => `${API_ENDPOINTS.get_article_issues}?issueId=${issueID}&offset=0&limit=10`,
		}),
		addUserCompany: builder.mutation({
			query: (params: string[]) => ({
				url: API_ENDPOINTS.add_user_company,
				method: 'POST',
				body: params.join('&'),
			}),
		}),
		addUserOrbitRole: builder.mutation({
			query: (params: string[]) => ({
				url: API_ENDPOINTS.add_user_orbit_role,
				method: 'POST',
				body: params.join('&'),
			}),
		}),
		updateCreditCard: builder.mutation({
			query: (params: string[]) => ({
				url: API_ENDPOINTS.update_credit_card,
				method: 'POST',
				body: params.join('&'),
			}),
		}),
		updateSubscription: builder.mutation({
			query: (params: string[]) => ({
				url: API_ENDPOINTS.update_subscription,
				method: 'POST',
				body: params.join('&'),
			}),
		}),
		updateUserPreference: builder.mutation({
			query: (params: string[]) => ({
				url: API_ENDPOINTS.update_user_preference,
				method: 'POST',
				body: params.join('&'),
			}),
		}),
		updatePersonalInformation: builder.mutation({
			query: (params: string[]) => ({
				url: API_ENDPOINTS.update_personal_information,
				method: 'POST',
				body: params.join('&'),
			}),
		}),
	}),
})

export const {
	useGetMoreFreeStuffMutation,
	useGetAppellationsFromKeywordQuery,
	useGetArticleTagsQuery,
	useGetCustomerPaymentMethodQuery,
	useGetEditorialCalendarQuery,
	useGetListOfAuthorsQuery,
	useGetPaymentHistoryQuery,
	useGetProducersFromKeywordQuery,
	useGetCommentsOfAnArticleQuery,
	useGetProducersFromIdQuery,
	useGetProducerNotesQuery,
	useGetSubscriptionFromUserIdQuery,
	useGetSubscriptionHistoryQuery,
	useGetTradeDirectoryMutation,
	useGetTradeDirectoryV2Mutation,
	useGetTradeDirectoryLookupCountriesQuery,
	useGetTradeDirectoryLookupCountriesV2Query,
	useGetTradeDirectoryLookupTypesQuery,
	useGetTradeDirectoryLookupTypesV2Query,
	useGetUserSubscriptionQuery,
	useGetWineVarietiesQuery,
	useGetVintagesForAWineQuery,
	useUpdateCreditCardMutation,
	useUpdateUserPreferenceMutation,
	useUpdatePersonalInformationMutation,
	useGetTradeDirectoryDetailsQuery,
	useGetEcoDetailsByCompanyIdQuery,
	useGetListEcoQuery,
	useGetCompanyDetailsQuery,
	useGetIssuesDetailsQuery,
	useGetArticleIssueQuery,
} = BridgeAPI
