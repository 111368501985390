import { IsUserAuthenticated } from './AuthenticationUtil'

export const Subscription_Type = { // Enums
	Commercial: 'commercial',
	Personal: 'personal',
}

// export function obfuscateConstant(originalString: string) {
// 	let obfuscatedString = ''
// 	for (let i = 0; i < originalString.length; i++) {
// 		obfuscatedString += String.fromCharCode(originalString.charCodeAt(i) + 1)
// 	}
// 	return obfuscatedString
// }

export const reverseObfuscateConstant = (obfuscatedString: string): string => {
	let originalString = ''
	for (let i = 0; i < obfuscatedString.length; i++) {
		originalString += String.fromCharCode(obfuscatedString.charCodeAt(i) - 1)
	}
	return originalString
}

export const isUserAuthAndExpired = (userData: any): boolean => {
	if(IsUserAuthenticated()){
		const dateToday = new Date()
		const subscriptionEnd = new Date(userData?.subscription?.end)

		if(subscriptionEnd > dateToday){
			return true
		}
	}

	return false
}