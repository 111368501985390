/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react'
import * as _ from 'lodash'

// Slices
import {
	RemoveAdvancedSearchParameters,
	SetActiveAdvanceFilters,
	SetActiveFilters,
	SetAdvancedSearchParameters,
	SetGeneratedQuery,
	SetKeywords,
} from '../app/slices/SearchFilters'

import {
	ClearWineSearch,
	ClearArticleSearch,
} from '../app/slices/SearchResults'

import { APP_CONFIG, SEARCH_FILTER_TRANSLATIONS } from '../configs'
import { RP_ENV } from '../configs/index'

export function CleanPriorSortingFilters(query: URLSearchParams): string[] {
	const collectedQueries = GetFiltersFromQuery(query)

	const filtersToReturn = ['']

	collectedQueries.forEach((filterPair) => {
		const prefix = filterPair.split('=')[0]
		if (prefix !== 'sort' && prefix !== 'order') {
			filtersToReturn.push(filterPair)
		}
	})

	filtersToReturn.shift()

	return filtersToReturn
}

export function ExtractFilterFromURL(
	keyToRemove: string,
	valueToRemove: string,
	currentQuery: URLSearchParams
): string {
	const collectedFilters = GetFiltersFromQuery(currentQuery)

	const updatedQueries = ['']

	collectedFilters.forEach((filterPair) => {
		const [key, values] = filterPair.split('=')

		if (key === keyToRemove) {
			const updatedValues = values
				.split(/,(?!\s)/)
				.filter((valueToWatch) => valueToWatch != valueToRemove)

			// Add it back to the collection if there more than 1 filter left
			if (updatedValues.length > 0) {
				// This means that there are still values left after the filter
				updatedQueries.push(`${keyToRemove}=${updatedValues.join(',')}`)
			}
		} else {
			// this will ignore the filters that will not match
			updatedQueries.push(filterPair)
		}
	})

	updatedQueries.shift()
	return updatedQueries.join('&')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GetFiltersFromQuery(query: URLSearchParams): string[] {
	const collectedFilters = ['']
	query.forEach((value: string, key: string) => {
		collectedFilters.push(`${key}=${value}`)
	})

	collectedFilters.shift()

	return collectedFilters
}

/**
 * This generates the query string that will be added to the keywords portion of th query
 * @param filters Object taken from the store
 * @returns
 */
export function GenerateFilterQueriesFromSelectedFilters(
	filters: QueryFilters
): string {
	const mappingContainer: string[] = []

	Object.keys(filters).forEach((filterProperty) => {
		const filterValues = filters[filterProperty]
		const queryString = `${filterProperty}=${filterValues.join(',')}`
		mappingContainer.push(queryString)
	})

	return `${mappingContainer.join('&')}`
}

/**
 * Generates the query for search
 * @param keywords initial keyword
 * @param filters Generated filter query string
 * @returns
 */
export function GenerateFullURLQueryForSearch(
	keywords: string,
	filterQueryString: string
): string {
	// Deafults to 'expand' when there is no keyword
	const container = [keywords ? `keyword=${keywords}` : '', filterQueryString]
	return container.join('&')
}

export function GetArticleIdFromQuery(query: string): string {
	const container = query.split('&')

	const result = container.filter(
		(query) => query.split('=')[0] === 'article_id'
	)

	return result[0].split('=')[1]
}

export function getStructuredFiltersForState(collectedFilters: string[]): any {
	let filtersForState = {}

	collectedFilters.forEach((filter: string) => {
		const [key, values] = filter.split('=')

		if (key === 'keyword') {
			return
		}

		const arrayValues = values.split(/,(?!\s)/)
		filtersForState = {
			...filtersForState,
			[key]: arrayValues,
		}
	})

	return filtersForState
}

export function FilterCollection(
	keywords: string,
	collection: any[],
	fieldToSearch: string,
	isProfile = false
): any[] {
	return _.filter(collection, (record) => {
		const expression = new RegExp(`^${keywords}`, 'i')

		let match

		if (isProfile) {
			match = expression.test(record['profile']['name'][fieldToSearch])
		} else {
			match =
				fieldToSearch != ''
					? expression.test(record[fieldToSearch])
					: expression.test(record)
		}

		if (match) {
			return record
		}
	})
}

/**
 * Generates a new URL query by injecting values into it
 * @param keyToAdd
 * @param valueToAdd
 * @param currentQuery
 * @returns
 */
export function InjectFilterIntoURL(
	keyToAdd: string,
	valueToAdd: string,
	currentQuery: URLSearchParams
): string {
	const collectedFilters = GetFiltersFromQuery(currentQuery)
	const updatedQueries = []
	let filterExists = false
	const filterValues = valueToAdd.split(/,(?!\s)/)
	collectedFilters.forEach((filterPair) => {
		const [key, values] = filterPair.split('=')
		let joinedValues = values

		if (key === keyToAdd) {
			// This means that there is an existing query with the key; add the values
			filterExists = true
			const valueContainer = values.split(/,(?!\s)/)
			joinedValues = [...valueContainer, ...filterValues].join(',')
		}
		updatedQueries.push(`${key}=${joinedValues}`)
	})

	// This means that the current does not exists yet, therefore there is a need to add it to the string
	if (!filterExists) {
		updatedQueries.push(`${keyToAdd}=${filterValues.join(',')}`)
	}

	return updatedQueries.join('&')
}

export function IsFilteredFromArticle(query: string): boolean {
	const container = query.split('&')

	const result = container.filter(
		(query) => query === 'filter_from_article=true'
	)

	return result.length > 0 ? true : false
}

/**
 * Checks to see if a value is part o the selected filters
 * @param property Rfilter property
 * @param value fitler value
 * @param filters filters from store
 * @returns
 */
export function IsOptionChecked(
	property: string,
	value: string,
	filters: QueryFilters
): boolean {
	if (filters[property]) {
		return Object.values(filters[property]).includes(value)
	}

	return false
}

export function OverrideSearchFromArticle(collectedFilters: string[]): boolean {
	const result = collectedFilters.filter(
		(query) => query.split('=')[0] === 'article_id'
	)

	return result.length > 0 ? true : false
}

/** REACT Dependent  ************************************************************************************/

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function UpdateSearchFilters(
	dispatch: Dispatch<any>,
	query: URLSearchParams
): void {
	query.forEach((value: string, key: string) => {
		if (key === 'keyword') {
			dispatch(SetKeywords({ keywords: value }))
		}
	})
}

export function TriggerSearch(
	dispatch: Dispatch<any>,
	query: URLSearchParams
): void {
	// clears the search results
	dispatch(ClearWineSearch())
	dispatch(ClearArticleSearch())

	// Extract the filters from the given query
	const collectedFilters = GetFiltersFromQuery(query)

	// Build a collection for the active filters from the collected filters
	const filtersForState = getStructuredFiltersForState(collectedFilters)
	dispatch(SetActiveFilters(filtersForState))

	// Extract the keywords from the collected filters
	const keywords = collectedFilters.filter((collectedFilter: string) =>
		collectedFilter.split('=')[0] === 'keyword'
			? collectedFilter.split('=')[1]
			: ''
	)[0]

	dispatch(SetKeywords({ keywords }))

	if (OverrideSearchFromArticle(collectedFilters)) {
		collectedFilters.push('filter_from_article=true')
	}

	// Updates the generated query by combining the collected filters all together
	const generated_query = collectedFilters.join('&')
	dispatch(SetGeneratedQuery({ generated_query }))
}

export function ToggleAdvancedSearchChoice(
	isChecked: boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: Dispatch<any>,
	filter: string,
	value: string
): void {
	// This checks to see if the filter needs to be overriden
	const foundTranslation = SEARCH_FILTER_TRANSLATIONS.filter(
		(searchParam) => searchParam.key === filter
	)

	if (foundTranslation.length > 0) {
		filter = foundTranslation[0].filter
	}

	const actionValue = { filter, value }
	if (isChecked) {
		dispatch(SetAdvancedSearchParameters(actionValue))
	} else {
		dispatch(RemoveAdvancedSearchParameters(actionValue))
	}
}

export const checkQueryString = (queryStrings: any, activeFilters: any): boolean => {
	let res = false
	if ((!activeFilters.vintage && queryStrings.vintage) ||
		(!activeFilters.reviewers && queryStrings.reviewers) ||
		(!activeFilters.country && queryStrings.country) ||
		(!activeFilters.region && queryStrings.region) ||
		(!activeFilters.sub_region && queryStrings.sub_region) ||
		(!activeFilters.appellation && queryStrings.appellation) ||
		(!activeFilters.sub_appellation && queryStrings.sub_appellation) ||
		(!activeFilters.producer && queryStrings.producer) ||
		(!activeFilters.varieties && queryStrings.varieties) ||
		(!activeFilters.maturity && queryStrings.maturity) ||
		(!activeFilters.type && queryStrings.type) ||
		(!activeFilters.color_class && queryStrings.color_class) ||
		(!activeFilters.dryness && queryStrings.dryness) ||
		(!activeFilters.certified && queryStrings.certified) ||
		(!activeFilters.tags && queryStrings.tags) ||
		(!activeFilters.regions && queryStrings.regions) ||
		(!activeFilters.countries && queryStrings.countries) ||
		(!activeFilters['author.name'] && queryStrings['author.name']) ||
		(!activeFilters.issue_title && queryStrings.issue_title)) res = true||
		(!activeFilters.date_last_tasted && queryStrings.date_last_tasted) 

	else res = false
	return res
}

export const getSameFacetsByType = (queryStrings: any, existingQueryString: string, type: string): string => {
	let newPath = ''
	if (queryStrings['author.name'] && type === APP_CONFIG.SEARCH.TYPES.WINE) {
		newPath = existingQueryString.replace('author.name', 'reviewers')
	} else if (queryStrings['reviewers'] && type === APP_CONFIG.SEARCH.TYPES.ARTICLE) {
		newPath = existingQueryString.replace('reviewers', 'author.name')
	} else if (queryStrings['countries'] && type === APP_CONFIG.SEARCH.TYPES.WINE) {
		newPath = existingQueryString.replace('countries', 'country')
	} else if (queryStrings['country'] && type === APP_CONFIG.SEARCH.TYPES.ARTICLE) {
		newPath = existingQueryString.replace('country', 'countries')
	} else if (queryStrings['regions'] && type === APP_CONFIG.SEARCH.TYPES.WINE) {
		newPath = existingQueryString.replace('regions', 'region')
	} else if (queryStrings['region'] && type === APP_CONFIG.SEARCH.TYPES.ARTICLE) {
		newPath = existingQueryString.replace('region', 'regions')
	}
	return newPath
}

export const generateBody = (dispatch: Dispatch<any>, state: SearchFiltersStoreData, type: string, queryStrings: any, varStore: any): AlgoliaQueryBody => {
	const { ActiveFilters, Offset_Limit, Sort, AdvanceFilter: advanceFilter } = state

	dispatch(ClearWineSearch())
	dispatch(ClearArticleSearch())

	let sort = Sort, query = ''
	const filteredData: string[] = []
	if (type === APP_CONFIG.SEARCH.TYPES.WINE && sort === '') {
		sort = 'latest_review'
	} else if (type === APP_CONFIG.SEARCH.TYPES.ARTICLE && sort === 'latest_review'){
		sort = 'publish_date_latest'
	}

	if(varStore.Keywords?.key){
		query = varStore.Keywords?.key
	} else if (queryStrings.keyword) {
		query = queryStrings.keyword
	}
	if (queryStrings.article_id) {
		filteredData.push(`referring_articles:${queryStrings.article_id}`)
	}

	if(advanceFilter.price_low){
		let price = ''
		if(typeof advanceFilter.price_low[0] === 'string'){
			if(advanceFilter.price_low[0].includes('price_low')){
				price = `${advanceFilter.price_low[0]} TO ${advanceFilter.price_low[1]}`.replace(/ TO undefined$/, '')
			} 
		}else {
			price = `price_low:${advanceFilter.price_low[0]} TO ${advanceFilter.price_low[1]}`.replace(/ TO undefined$/, '')
		}
		filteredData.push(price)
	}

	let rating = ''
	if(type === APP_CONFIG.SEARCH.TYPES.WINE){
		if(advanceFilter.rating_computed){
			if(!isNaN(advanceFilter.rating_computed[0]) && !isNaN(advanceFilter.rating_computed[1])){
				if(advanceFilter.rating_computed[0] <= advanceFilter.rating_computed[1]){
					if(typeof advanceFilter.rating_computed[0] === 'string'){
						if(advanceFilter.rating_computed[0].includes('rating_computed')){
							rating = `${advanceFilter.rating_computed[0]} TO ${advanceFilter.rating_computed[1]}`.replace(/ TO undefined$/, '')
						}		
					} else {
						rating = `rating_computed:${advanceFilter.rating_computed[0]} TO ${advanceFilter.rating_computed[1]}`.replace(/ TO undefined$/, '')		
					}

					if(rating !== 'rating_computed:0 TO 0'){
						filteredData.push(rating)
					}
				}
				
			}
		} else {
			rating = 'rating_computed:50 TO 100'
			filteredData.push(rating)
		}
	}


	if(advanceFilter.date_last_tasted){
		let publishDate = ''
		if(typeof advanceFilter.date_last_tasted[0] === 'number'){
			const toDate = advanceFilter.date_last_tasted[0]
			let fromDate = advanceFilter.date_last_tasted[1]
			if(advanceFilter.date_last_tasted[0] === advanceFilter.date_last_tasted[1]){
				fromDate = advanceFilter.date_last_tasted[1] + 86400000
			}
			if(type === 'wine'){
				publishDate = `date_last_tasted:${toDate} TO ${fromDate}`
			} else if(type === 'article') {
				publishDate = `published_date:${toDate} TO ${fromDate}`
			}
		}
		filteredData.push(publishDate)
	}
	
	
	let convertedFilters = filteredData.join(' AND ')
	if(!filteredData.length && type !== APP_CONFIG.SEARCH.TYPES.ARTICLE){
		if(varStore.FacetFilters !== undefined) {
			if('price_low' in varStore.FacetFilters){
				convertedFilters = `rating_computed:${varStore.FacetFilters?.rating_computed[0]} TO ${varStore.FacetFilters?.rating_computed[1]} AND price_low:${varStore.FacetFilters?.price_low[0]} TO ${varStore.FacetFilters?.price_low[1]}`
			}
		} else {
			convertedFilters = ''
		}
	}

	// const newFacetsFilters = Object.keys(ActiveFilters).map((key: any) => ActiveFilters[key])
	// const newFacetsFilters = Object.keys(ActiveFilters).filter((key: any) => key !== 'price_low' || key !== 'rating_computed').map((key: any) => ActiveFilters[key])
	const newFacetsFilters = Object.keys(ActiveFilters).filter((key: any) => key !== 'keyword').map((key: any) => ActiveFilters[key])
	return {
		sort: sort,
		body: {
			query: query,
			facetFilters: newFacetsFilters,
			filters: convertedFilters,
			hitsPerPage: Offset_Limit.limit,
			page: Offset_Limit.offset,
		},
		type: type,
	}
}

export function IsChecked(
	property: string,
	value: string,
	filters: any
): boolean {
	if (filters[property]) {
		return Object.values(filters[property]).includes(value)
	}

	return false
}

export const checkIfExist = (currentFilters: any, newFilters: any): boolean => {
	let i = 0, j = 0

	for (i = 0; i < currentFilters.length; ++i) {
		if (newFilters.length === currentFilters[i].length) {
			const current = currentFilters[i]
			for (j = 0; j < newFilters.length && newFilters[j] === current[j]; ++j);
			if (j === newFilters.length)
				return true
		}
	}

	return false
}

export const generateFacetsFilters = (ActiveFacetsFilters: any, newFacetsFilters: string[]): string[] => {

	const exists = checkIfExist(ActiveFacetsFilters, newFacetsFilters)
	if (exists) {
		ActiveFacetsFilters = ActiveFacetsFilters.map((y: any) => y.filter((x: any, i: number) => !x.includes(newFacetsFilters[i])))
	} else {
		ActiveFacetsFilters = [...ActiveFacetsFilters, newFacetsFilters]
	}

	return ActiveFacetsFilters.filter((y: any) => { return y.length !== 0 })
}

export const generateActiveFilters = (ActiveFilters: any, reference: string, value: string): any => {

	if (ActiveFilters[reference]) {
		let newFilters = []

		if (!ActiveFilters[reference].includes(value)) {
			newFilters = [...ActiveFilters[reference], value]
			ActiveFilters = { ...ActiveFilters, [reference]: newFilters }
		} else {
			newFilters = ActiveFilters[reference].filter((ele: any) => { return ele !== value })
			if (newFilters.length > 0) {
				ActiveFilters = { ...ActiveFilters, [reference]: newFilters }
			} else {
				const { [reference]: x, ...activeFilters } = ActiveFilters
				ActiveFilters = activeFilters
			}
		}

	} else {
		ActiveFilters = { ...ActiveFilters, [reference]: [value] }
	}
	return ActiveFilters
}

export const getTypes = (pathName: string): string => {
	let types = []
	types = pathName.split('/')
	types = types.filter(ele => { return ele === 'wine' || ele === 'article' })
	if (types[0] !== undefined) {
		return types[0]
	} else {
		return 'wine'
	}
}

export const removeFilterFromQueryString = (queryString: any, id: string): string => {
	const value = id.replace(':', '=')
	const x = queryString.split('&').map((ele: any) => ele.replace('?', '').toLowerCase())
	const filteredQuery = x.filter((ele: any) => {
		const [key, _] = ele.split('=')
		return key !== value.split('=')[0]
	}).join('&')
	return filteredQuery
}